import { Box, Chip, ListItem, styled } from "@mui/joy";
import { memo, type PropsWithChildren } from "react";

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.variants.outlined.neutral.borderColor,
  borderRadius: theme.radius.sm,
  display: "flex",
  flex: 1,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  overflow: "hidden",
  padding: theme.spacing(1),
}));

interface AccessTokenListItemProps extends PropsWithChildren {
  revoked?: boolean;
}

const AccessTokenListItem = memo<AccessTokenListItemProps>(
  ({ children, revoked = false }) => {
    return (
      <StyledListItem>
        <Box sx={{ p: 1 }}>
          {revoked ? (
            <Chip color="danger" sx={{ marginRight: 1 }}>
              Revoked
            </Chip>
          ) : (
            <Chip color="success" sx={{ marginRight: 1 }}>
              Active
            </Chip>
          )}
        </Box>
        {children}
      </StyledListItem>
    );
  }
);
AccessTokenListItem.displayName = "AccessTokenListItem";

export default AccessTokenListItem;
