import { useOrganizationDetailsQuery } from "@decentriq/graphql/dist/hooks";
import { FormLabel, Stack, Typography } from "@mui/joy";
import { format } from "date-fns";
import { memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";

const OrganizationDetails = memo(() => {
  const { organizationId, showOrganizationDetails } =
    useOrganizationAdminConfiguration();
  const { data: organizationDetailsData } = useOrganizationDetailsQuery({
    skip: !showOrganizationDetails,
    variables: {
      organizationId,
    },
  });
  const { createdAt, createdBy, updatedAt } =
    organizationDetailsData?.organization || {};
  if (!showOrganizationDetails) {
    return null;
  }
  return (
    <Stack
      direction="row"
      sx={{ "& > *": { width: "calc(100% / 3 - 8px * 2 / 3)" } }}
    >
      <Typography
        alignItems="flex-start"
        flexDirection="column"
        level="title-md"
        startDecorator={<FormLabel>Created at</FormLabel>}
      >
        {createdAt ? format(new Date(createdAt), "dd.MM.yyy, HH:mm") : "–"}
      </Typography>
      <Typography
        alignItems="flex-start"
        flexDirection="column"
        level="title-md"
        startDecorator={<FormLabel>Updated at</FormLabel>}
      >
        {updatedAt ? format(new Date(updatedAt), "dd.MM.yyy, HH:mm") : "–"}
      </Typography>
      <Typography
        alignItems="flex-start"
        flexDirection="column"
        level="title-md"
        startDecorator={<FormLabel>Created by</FormLabel>}
      >
        {createdBy?.email || "–"}
      </Typography>
    </Stack>
  );
});
OrganizationDetails.displayName = "OrganizationDetails";

export default OrganizationDetails;
