import { useCallback } from "react";
import { useDraftDataRoomIsFavorite } from "features/draftDataRoom/components/DraftDataRoomActions/hooks";
import { usePublishedDataRoomIsFavorite } from "features/publishedDataRoom/components/PublishedDataRoomActions/hooks";
import { DataRoomTypeNames } from "models";

interface DataRoomToggleFavoriteHookPayload {
  dataRoomId: string;
  __typename: DataRoomTypeNames;
}

export const useDataRoomToggleFavorite = ({
  dataRoomId,
  __typename,
}: DataRoomToggleFavoriteHookPayload) => {
  const { setDraftDataRoomIsFavorite } = useDraftDataRoomIsFavorite({
    id: dataRoomId,
    skip: __typename !== DataRoomTypeNames.DraftDataRoom,
  });
  const { setPublishedDataRoomIsFavorite } = usePublishedDataRoomIsFavorite({
    __typename: DataRoomTypeNames.PublishedDataRoom,
    id: dataRoomId,
    skip: __typename === DataRoomTypeNames.DraftDataRoom,
  });
  const toggleFavorite = useCallback(
    async (isFavorite: boolean) => {
      switch (__typename) {
        case DataRoomTypeNames.DraftDataRoom:
          await setDraftDataRoomIsFavorite(isFavorite);
          break;
        case DataRoomTypeNames.PublishedDataRoom:
        case DataRoomTypeNames.PublishedMediaInsightsDcr:
          await setPublishedDataRoomIsFavorite(isFavorite);
      }
    },
    [__typename, setDraftDataRoomIsFavorite, setPublishedDataRoomIsFavorite]
  );

  return { toggleFavorite };
};
