import { useUpdatePostComputeNodeDependencyMutation } from "@decentriq/graphql/dist/hooks";
import {
  type DraftNode,
  ScriptingLanguage,
} from "@decentriq/graphql/dist/types";
import {
  Box,
  FormControl,
  FormHelperText,
  Option,
  Select,
  styled,
  Typography,
} from "@mui/joy";
import { memo, useCallback } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import { ComputeNodeTypeNames, DataNodeTypeNames } from "models";
import usePostComputeNode from "./usePostComputeNode";

interface PostComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const StyledFormHelperText = styled(FormHelperText)(
  ({ theme: { spacing } }) => ({
    margin: spacing(1, 0, 0, 0),
  })
);

const PostComputeNodeEditor = memo(
  ({ computeNodeId, readOnly }: PostComputeNodeEditorProps) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { dataRoomId } = useDataRoom();
    const { executionContext } = useComputeNodesVars();
    const isInteractivityContext =
      executionContext === "development" || executionContext === "requests";
    const { dependency, isLoading } = usePostComputeNode(computeNodeId);
    const postInput =
      (dependency as DraftNode | undefined)?.id || dependency?.computeNodeId;
    const { nodes } = useNodes();
    // As a Post dependency, only SQL, Python compute nodes and Table nodes are allowed
    const computeNodes = nodes.filter(
      ({ __typename, id, ...restComputeNode }) =>
        [
          ComputeNodeTypeNames.DraftSqlNode,
          ComputeNodeTypeNames.PublishedSqlNode,
          DataNodeTypeNames.DraftTableLeafNode,
          DataNodeTypeNames.PublishedTableLeafNode,
        ].includes(__typename) ||
        ([
          ComputeNodeTypeNames.DraftScriptingNode,
          ComputeNodeTypeNames.PublishedScriptingNode,
        ].includes(__typename) &&
          restComputeNode?.scriptingLanguage === ScriptingLanguage.Python)
    );
    const inputList =
      computeNodes.map((computeNode: any) => ({
        id: computeNode?.id,
        label: computeNode?.name,
        value: computeNode?.id,
      })) || [];
    const [updatePostComputeNodeDependencyMutation] =
      useUpdatePostComputeNodeDependencyMutation();
    const updatePostComputeNodeDependency = useCallback(
      async (dependencyId: string) => {
        try {
          return await updatePostComputeNodeDependencyMutation({
            variables: {
              computeNodeId,
              dependencyId: isInteractivityContext
                ? {
                    published: {
                      computeNodeId: dependencyId,
                      publishedDataRoomId: dataRoomId,
                    },
                  }
                : { draft: dependencyId },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Post settings could not be updated."
            )
          );
          throw error;
        }
      },
      [
        updatePostComputeNodeDependencyMutation,
        computeNodeId,
        isInteractivityContext,
        dataRoomId,
        enqueueSnackbar,
      ]
    );
    return !isLoading ? (
      <Box
        sx={{ height: "100%", overflow: "auto", p: 1, position: "relative" }}
      >
        <FormControl>
          <Select
            defaultValue={postInput}
            disabled={readOnly}
            onChange={(event, value) => {
              updatePostComputeNodeDependency(value || "");
            }}
            renderValue={({ value }) =>
              inputList.find(
                (i: { id: string; label: string }) => i.id === value
              )?.label || "Data source"
            }
            sx={{ maxWidth: "250px", minWidth: 144, textAlign: "center" }}
            value={postInput}
          >
            {inputList.map(({ id, label }) => (
              <Option key={id} value={id}>
                {label}
              </Option>
            ))}
          </Select>
          <StyledFormHelperText id="component-helper-text">
            Expected schema, in this order: first_name, last_name, street,
            house_number, postal_code, town, id NULL, owner NULL.
          </StyledFormHelperText>
        </FormControl>
        <Typography color="textSecondary" level="body-sm" maxWidth={600} mt={3}>
          The post worker sends an API request to the Swiss Post address
          maintenance service that includes the personalised addresses to
          maintain. The information identifying the records' owner are dropped
          before making the request and – once the results are returned –
          records are mapped back to the original owner using a cryptographic
          pseudo random id.
        </Typography>
      </Box>
    ) : null;
  }
);

PostComputeNodeEditor.displayName = "PostComputeNodeEditor";

export default PostComputeNodeEditor;
