// TODO: Fix joy migration
import {
  faEnvelopeOpenText as fatEnvelopeOpenText,
  faIdBadge as fatIdBadge,
} from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { EmailNotVerifiedError } from "models";

interface UnauthorizedDialogProps {
  open: boolean;
  onClose: () => void;
  error?: Error;
}

const UnauthorizedDialog = memo<UnauthorizedDialogProps>(
  ({ open, onClose, error }) => {
    const isEmailVerificationError = error instanceof EmailNotVerifiedError;
    return (
      <Modal open={open}>
        <ModalDialog role="alertdialog">
          <DialogTitle>
            <Box sx={{ mb: 1, textAlign: "center" }}>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={
                  isEmailVerificationError ? fatEnvelopeOpenText : fatIdBadge
                }
                size="4x"
              />
            </Box>
            <Typography level="title-md" sx={{ textWrap: "balance" }}>
              {isEmailVerificationError ? (
                <strong>Please verify your email first</strong>
              ) : (
                <strong>Sign in failed</strong>
              )}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography component="div" level="body-sm">
              {isEmailVerificationError ? (
                <>
                  We must be sure that the email address you specified during
                  the sign up process really belongs to you, therefore we've
                  sent you a verification link. Please follow that link first
                  and then come back and try to sign in again.
                </>
              ) : (
                <>
                  During sign in, the following error occurred:{" "}
                  <i>{error?.message}</i>
                  <br />
                  Please try again. If the issue persists, please contact us at{" "}
                  <a
                    href="mailto:support@decentriq.com"
                    style={{ color: "inherit" }}
                  >
                    support@decentriq.com
                  </a>
                </>
              )}
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={onClose}>Sign in</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);
UnauthorizedDialog.displayName = "UnauthorizedDialog";

export default UnauthorizedDialog;
