import { styled } from "@mui/joy";
import { type SnackbarKey, useSnackbar } from "notistack";
import { memo, type ReactNode, useCallback } from "react";

interface CustomSnackbarActionsProps {
  id: SnackbarKey;
  render: (closeSnackbar: () => void) => ReactNode[];
}

const CustomSnackbarActionsWrapper = styled("div")({
  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
  justifyContent: "center",
});

const CustomSnackbarAction = styled("div")({
  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
});

const CustomSnackbarActions: React.FC<CustomSnackbarActionsProps> = ({
  id,
  render,
}) => {
  const { closeSnackbar } = useSnackbar();
  const handleClose = useCallback(() => closeSnackbar(id), [closeSnackbar, id]);
  const actions = render(handleClose);
  return (
    <CustomSnackbarActionsWrapper>
      {actions.map((action, index) => {
        return (
          <CustomSnackbarAction key={index}>{action}</CustomSnackbarAction>
        );
      })}
    </CustomSnackbarActionsWrapper>
  );
};

CustomSnackbarActions.displayName = "CustomSnackbarActions";

export default memo(CustomSnackbarActions);
