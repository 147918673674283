import { Link, Stack, Typography, useTheme } from "@mui/joy";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CustomBrandingLogo } from "components";
import { SIDEBAR_WIDTH, useSidebar } from "features/sidebar";
import { useCurrentUserOrganizationBranding } from "hooks";

const OrganizationLogoSidebarItem = memo(() => {
  const { isSidebarExpanded } = useSidebar();
  const { radius } = useTheme();
  const { domain, logoBase64, organizationName } =
    useCurrentUserOrganizationBranding();
  return (
    <Stack alignItems="center" direction="row" width="100%">
      <Link
        component={RouterLink}
        maxWidth={`${SIDEBAR_WIDTH}px`}
        minWidth={isSidebarExpanded ? `${SIDEBAR_WIDTH}px` : "100%"}
        to="/"
      >
        <CustomBrandingLogo
          domain={domain}
          fallbackToDecentriq={false}
          logoBase64={logoBase64!}
          maxHeight={`${SIDEBAR_WIDTH}px`} // Applied in order to prevent logo from being height "auto" while toggling a sidebar
          style={{ borderRadius: radius.sm }}
          withPoweredBy={false}
          wrapperSx={{
            marginBottom: 1,
            maxWidth: `${SIDEBAR_WIDTH}px`,
            width: "100%",
          }}
        />
      </Link>
      {domain && logoBase64 && isSidebarExpanded && (
        <Typography level="body-lg" lineHeight="18px">
          {organizationName}
        </Typography>
      )}
    </Stack>
  );
});

OrganizationLogoSidebarItem.displayName = "OrganizationLogoSidebarItem";

export default OrganizationLogoSidebarItem;
