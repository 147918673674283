import { RawMatchingID } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
} from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";

const MatchingIdSelect: React.FC = memo(() => {
  const { control } = useFormContext();
  const { defaultValues } = useFormState();
  return (
    <Controller
      control={control}
      name="matchingId"
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return (
          <FormControl
            disabled={field.disabled || defaultValues?.matchingId !== ""} // NOTE: Similarly to `lockedEmails`, the assumption is that the matching ID should not be changed if it was pre-populated
            error={Boolean(error)}
            required={true}
          >
            <FormLabel>Matching ID</FormLabel>
            <Select
              data-testid={
                testIds.mediaInsightsDcr.matchingIdSelector.matchingId
              }
              placeholder="No matching ID selected"
              {...field}
              onChange={(event, newValue) => field.onChange(newValue)}
              value={field.value || null}
            >
              <Option
                data-testid={`${testIds.mediaInsightsDcr.matchingIdSelector.helper}${RawMatchingID.STRING}`}
                value={RawMatchingID.STRING}
              >
                String
              </Option>
              <Option
                data-testid={`${testIds.mediaInsightsDcr.matchingIdSelector.helper}${RawMatchingID.EMAIL}`}
                value={RawMatchingID.EMAIL}
              >
                Email
              </Option>
              <Option
                data-testid={`${testIds.mediaInsightsDcr.matchingIdSelector.helper}${RawMatchingID.HASHED_EMAIL}`}
                value={RawMatchingID.HASHED_EMAIL}
              >
                Hashed email
              </Option>
              <Option
                data-testid={`${testIds.mediaInsightsDcr.matchingIdSelector.helper}${RawMatchingID.PHONE_NUMBER}`}
                value={RawMatchingID.PHONE_NUMBER}
              >
                Phone number
              </Option>
              <Option
                data-testid={`${testIds.mediaInsightsDcr.matchingIdSelector.helper}${RawMatchingID.HASHED_PHONE_NUMBER}`}
                value={RawMatchingID.HASHED_PHONE_NUMBER}
              >
                Hashed phone number
              </Option>
            </Select>
            <FormHelperText>
              {defaultValues?.matchingId !== ""
                ? "Matching ID has been set according to the publisher’s settings"
                : error?.message}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
});
MatchingIdSelect.displayName = "MatchingIdSelect";

export default MatchingIdSelect;
