import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, type ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAdvertiserAudiences,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import {
  type LookalikeAudienceStatistics,
  useLookalikeAudienceStatistics,
} from "features/mediaDataRoom/hooks";
import {
  type AdvertiserAudience,
  MediaDataRoomTab,
  type RuleBasedAudience,
} from "features/mediaDataRoom/models";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useOrganizationPreferences,
} from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import {
  AdvertiserAudienceGeneratorEmptyState,
  AdvertiserAudienceGeneratorErrorState,
  AdvertiserAudienceGeneratorLoadingState,
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "../../AdvertiserAudienceGenerator";
import {
  LookalikeAdvertiserAudienceGeneratorConfigurationStep,
  LookalikeAdvertiserAudienceGeneratorSummaryStep,
} from "../components";
import {
  type LookalikeAdvertiserAudienceGeneratorContextValue,
  LookalikeAdvertiserAudienceGeneratorProvider,
} from "../contexts/LookalikeAdvertiserAudienceGeneratorContext";

const DEFAULT_REACH_VALUE: number = 5;

const LookalikeAdvertiserAudienceGenerator = memo(() => {
  const { session } = useMediaDataRoomInsightsData();
  const { dataRoomId, isDeactivated } = useMediaDataRoom();
  const { allowExcludingSeedAudience: showExcludeSeedAudienceCheckbox } =
    useOrganizationPreferences();
  const { currentStep } = useAdvertiserAudienceGenerator();
  const [reach, setReach] = useState<number>(DEFAULT_REACH_VALUE);
  const [excludeSeedAudience, setExcludeSeedAudience] =
    useState<boolean>(false);
  const [selectedSeedAudience, setSelectedSeedAudience] = useState<
    RuleBasedAudience | AdvertiserAudience | null
  >(null);
  const [audienceName, setAudienceName] = useState<string>("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  // Advertiser audience Quality drawer state
  const [
    isAdvertiserAudienceQualityDrawerOpen,
    {
      setTrue: openAdvertiserAudienceQualityDrawer,
      setFalse: closeAdvertiserAudienceQualityDrawer,
    },
  ] = useBoolean(false);
  const {
    saveAudience,
    audiences: {
      computeResults: allAdvertiserAudiences = [],
      ...audiencesState
    },
  } = useAdvertiserAudiences();
  const [generationStep, setGenerationStep] =
    useState<
      LookalikeAdvertiserAudienceGeneratorContextValue["generationStep"]
    >(null);
  // Lookalike audiences need to be filtered out in order to exclude depending lookalike audiences
  const availableSeedAudiences = allAdvertiserAudiences.filter(
    ({ id }, index, allAudiences) =>
      !session?.compiler.abMedia.doesAudienceDependOnLookalikeAudience(
        id,
        allAudiences
      )
  ) as (RuleBasedAudience | AdvertiserAudience)[];
  const selectedAudienceStatistics: LookalikeAudienceStatistics =
    useLookalikeAudienceStatistics({
      audienceId: selectedSeedAudience?.id,
      audienceReach: reach,
      audiences: allAdvertiserAudiences,
      excludeSeedAudience,
    });
  // Set default value for the new audience
  useEffect(() => {
    setAudienceName(`${selectedSeedAudience?.mutable.name} - ${reach}%`);
  }, [reach, currentStep, selectedSeedAudience?.mutable.name]);

  // Set default value for audienceType selector
  useEffect(() => {
    if (availableSeedAudiences.length && !selectedSeedAudience) {
      setSelectedSeedAudience(availableSeedAudiences[0]);
    }
  }, [availableSeedAudiences, selectedSeedAudience]);
  const generateAudience = useCallback(async () => {
    try {
      if (!selectedSeedAudience?.id) {
        enqueueSnackbar("Please select seed audience", {
          variant: "info",
        });
        return;
      }
      if (!audienceName.trim()) {
        enqueueSnackbar("Please provide a name for the audience", {
          variant: "info",
        });
        return;
      }
      setGenerationStep("pending");
      await saveAudience({
        exclude_seed_audience: excludeSeedAudience,
        id: crypto.randomUUID(),
        kind: "lookalike",
        mutable: {
          created_at: new Date().toISOString(),
          name: audienceName,
          status: "ready",
        },
        reach,
        source_ref: selectedSeedAudience.id,
      });
      setGenerationStep("completed");
      enqueueSnackbar("Audiences successfully updated", { variant: "success" });
      setTimeout(() => {
        const dataRoomBasePath = dataRoomPathBuilder(
          dataRoomId,
          DataRoomTypeNames.PublishedMediaInsightsDcr
        );
        navigate(`${dataRoomBasePath}/${MediaDataRoomTab.activation}`);
      }, 1200);
    } catch (error) {
      setGenerationStep(null);
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, "Failed to generate audience")
      );
    }
  }, [
    setGenerationStep,
    audienceName,
    selectedSeedAudience?.id,
    saveAudience,
    excludeSeedAudience,
    reach,
    dataRoomId,
    navigate,
    enqueueSnackbar,
  ]);
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryGeneration = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      await audiencesState.retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [audiencesState, isDeactivated]);
  if (audiencesState.error) {
    return (
      <AdvertiserAudienceGeneratorErrorState
        RetryButton={
          !isDeactivated && (
            <Button
              color="neutral"
              loading={retryInProgress}
              onClick={retryGeneration}
              variant="soft"
            >
              Retry
            </Button>
          )
        }
      />
    );
  }
  if (audiencesState.loading) {
    return <AdvertiserAudienceGeneratorLoadingState />;
  }
  if (!allAdvertiserAudiences?.length) {
    return <AdvertiserAudienceGeneratorEmptyState />;
  }
  let step: ReactNode = null;
  switch (currentStep) {
    case AdvertiserAudienceGeneratorStep.CONFIGURATION:
      step = <LookalikeAdvertiserAudienceGeneratorConfigurationStep />;
      break;
    case AdvertiserAudienceGeneratorStep.SUMMARY:
      step = <LookalikeAdvertiserAudienceGeneratorSummaryStep />;
      break;
  }
  return (
    <LookalikeAdvertiserAudienceGeneratorProvider
      value={{
        audienceName,
        availableSeedAudiences: {
          computeResults: availableSeedAudiences,
          ...audiencesState,
        },
        closeAdvertiserAudienceQualityDrawer,
        excludeSeedAudience,
        generateAudience,
        generationStep,
        isAdvertiserAudienceQualityDrawerOpen,
        openAdvertiserAudienceQualityDrawer,
        reach,
        selectedAudienceStatistics,
        selectedSeedAudience,
        setAudienceName,
        setExcludeSeedAudience,
        setReach,
        setSelectedSeedAudience,
        showExcludeSeedAudienceCheckbox,
      }}
    >
      {step}
    </LookalikeAdvertiserAudienceGeneratorProvider>
  );
});

LookalikeAdvertiserAudienceGenerator.displayName =
  "LookalikeAdvertiserAudienceGenerator";

export default LookalikeAdvertiserAudienceGenerator;
