import { Link } from "@mui/joy";
import { type ShowMoreToggleLinesFn } from "@re-dev/react-truncate";
import { memo, type PropsWithChildren } from "react";

const MoreOrLessButton: React.FC<
  PropsWithChildren & { toggleLines: ShowMoreToggleLinesFn }
> = memo(({ children, toggleLines }) => (
  <Link
    color="neutral"
    component="button"
    ml={0.5}
    onClick={toggleLines}
    underline="none"
    variant="soft"
  >
    {children}
  </Link>
));
MoreOrLessButton.displayName = "MoreOrLessButton";

export default MoreOrLessButton;
