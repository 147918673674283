import { Box, Stack } from "@mui/joy";
import { memo } from "react";
import { HomeScreen, NotificationBanner } from "features/homeScreen";

const HomePage = memo(() => (
  <Stack
    direction="column"
    height="100%"
    overflow="auto"
    spacing={0}
    width="100%"
  >
    <Box style={{ left: 0, position: "sticky", right: 0, top: 0, zIndex: 2 }}>
      <NotificationBanner />
    </Box>
    <Stack alignItems="center" direction="column" flex="1" p={4}>
      <HomeScreen />
    </Stack>
  </Stack>
));
HomePage.displayName = "HomePage";

export default HomePage;
