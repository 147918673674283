export interface Configuration {
  apiUrl: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0RelayClientId: string;
  azureAppConfigurationConnectionString: string;
  clientLogRequests?: boolean;
  diswaHost: string;
  diswaPort: number;
  diswaUseTls: boolean;
  environment: string;
  insecureEnclavesEnabled: boolean;
  unsafeDisableKnownRootCaCheck: boolean;
  enableDebugLogs: boolean;
  graphqlUrl: string;
  nodeEnv: string;
  optimizelyDatafile: null;
  publicUrl: string;
  websocketUrl: string;
}

const getConfiguration = (): Configuration => {
  const {
    NODE_ENV,
    PUBLIC_URL,
    VITE_ENVIRONMENT,
    VITE_API_URL,
    VITE_GRAPHQL_URL,
    VITE_WEBSOCKET_URL,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_RELAY_CLIENT_ID,
    VITE_AZURE_APP_CONFIGURATION_CONNECTION_STRING,
    VITE_DISWA_HOST,
    VITE_DISWA_PORT,
    VITE_DISWA_USE_TLS,
    VITE_DECENTRIQ_CLIENT_LOG_REQUESTS,
    VITE_UNSAFE_DISABLE_KNOWN_ROOT_CA_CHECK,
    VITE_ENABLE_INSECURE_ENCLAVE,
    VITE_ENABLE_DEBUG_LOGS,
  } = import.meta.env;
  const {
    nodeEnv = NODE_ENV,
    ENVIRONMENT: environment = VITE_ENVIRONMENT,
    publicUrl = PUBLIC_URL,
    API_URL: apiUrl = VITE_API_URL,
    GRAPHQL_URL: graphqlUrl = VITE_GRAPHQL_URL,
    WEBSOCKET_URL: websocketUrl = VITE_WEBSOCKET_URL,
    AUTH0_DOMAIN: auth0Domain = VITE_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: auth0ClientId = VITE_AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: auth0Audience = VITE_AUTH0_AUDIENCE,
    AUTH0_RELAY_CLIENT_ID: auth0RelayClientId = VITE_AUTH0_RELAY_CLIENT_ID,
    AZURE_APP_CONFIGURATION_CONNECTION_STRING:
      azureAppConfigurationConnectionString = VITE_AZURE_APP_CONFIGURATION_CONNECTION_STRING,
    DISWA_HOST: diswaHost = VITE_DISWA_HOST,
    DISWA_PORT: diswaPort = VITE_DISWA_PORT,
    DISWA_USE_TLS: diswaUseTls = VITE_DISWA_USE_TLS,
    DECENTRIQ_CLIENT_LOG_REQUESTS:
      clientLogRequestsOverride = VITE_DECENTRIQ_CLIENT_LOG_REQUESTS,
    ENABLE_INSECURE_ENCLAVE:
      enableInsecureEnclave = VITE_ENABLE_INSECURE_ENCLAVE,

    UNSAFE_DISABLE_KNOWN_ROOT_CA_CHECK:
      unsafeDisableKnownRootCaCheck = VITE_UNSAFE_DISABLE_KNOWN_ROOT_CA_CHECK,
    ENABLE_DEBUG_LOGS: enableDebugLogs = VITE_ENABLE_DEBUG_LOGS,
  } = window as any;

  const clientLogRequests =
    clientLogRequestsOverride !== undefined
      ? clientLogRequestsOverride
      : environment && !environment.startsWith("prod");

  const { protocol, hostname, port } = window.location;
  const httpProtocol = protocol;
  const wsProtocol = protocol.replace("http", "ws");
  const configuration = {
    apiUrl: (
      apiUrl || `${httpProtocol}//${[hostname, port].filter(Boolean).join(":")}`
    )?.replace(/\/$/, ""),
    auth0Audience: auth0Audience || "",
    auth0ClientId: auth0ClientId || "",
    auth0Domain: auth0Domain || "",
    auth0RelayClientId: auth0RelayClientId || "",
    azureAppConfigurationConnectionString,
    clientLogRequests,
    diswaHost: diswaHost || "localhost",
    diswaPort: parseInt(diswaPort) || 443,
    diswaUseTls: diswaUseTls === "true" || false,
    enableDebugLogs: enableDebugLogs === "true" || false,
    environment: environment || "",
    graphqlUrl: (
      graphqlUrl ||
      `${httpProtocol}//${[hostname, port].filter(Boolean).join(":")}/graphql`
    )?.replace(/\/$/, ""),
    insecureEnclavesEnabled: enableInsecureEnclave === "true" || false,
    nodeEnv: nodeEnv || "",
    optimizelyDatafile: null,
    publicUrl: publicUrl?.replace(/\/$/, ""),
    unsafeDisableKnownRootCaCheck:
      unsafeDisableKnownRootCaCheck === "true" || false,
    websocketUrl: (
      websocketUrl ||
      `${wsProtocol}//${[hostname, port].filter(Boolean).join(":")}/graphql`
    )?.replace(/\/$/, ""),
  };

  return configuration;
};

export const configuration = getConfiguration();
