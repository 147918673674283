import { CollaborationTypes } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, FormControl, FormLabel, Stack, Tooltip } from "@mui/joy";
import { Fragment, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { type SchemaType } from "components/DqCreateMediaDcr/components/DqCreateMediaDcrFormProvider/model";
import { useCreationWizardConfiguration } from "../../../../contexts";

const checkboxSlotProps = {
  label: {
    sx: { cursor: "pointer", userSelect: "none", zIndex: 1 },
  },
};

const mediaDataRoomFeaturesTooltipMap: Record<
  CollaborationTypes,
  string | null
> = {
  [CollaborationTypes.Insights]:
    "If enabled, advertiser, publisher, agency and observer users will have access to the insights dashboards.",
  [CollaborationTypes.Remarketing]:
    "If enabled, advertiser and agency users will have access to the audiences tab including automatically created remarketing audiences.",
  [CollaborationTypes.Lookalike]:
    "If enabled, advertiser and agency users will have access to the audiences tab where they can create lookalike audiences.",
  [CollaborationTypes.Measurements]:
    "If enabled, advertiser users will be able to request measurement DCRs. This is a separate DCR that does rules-based attribution on brand conversions.",
  [CollaborationTypes.ExclusionTargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created exclusion targeting audiences and make them available to the publisher.",
  [CollaborationTypes.RuleBasedAudiences]:
    "If enabled, advertiser and agency users will be able to create rule-based audiences.",
};

const CollaborationTypesCheckboxes: React.FC = memo(() => {
  const { control, getValues, setValue } = useFormContext<SchemaType>();
  const { allowedCollaborationTypes: allowedFeatures } =
    useCreationWizardConfiguration();
  return (
    <Controller
      control={control}
      name="enabledFeatures"
      render={({ field, fieldState }) => {
        const { error } = fieldState; // NOTE: error.message for this field is rendered below the "Options"
        return (
          <FormControl disabled={field.disabled} error={Boolean(error)}>
            <FormLabel>Collaboration types</FormLabel>
            <Stack direction="row" flexWrap="wrap" spacing={2}>
              {allowedFeatures.map((feature) => {
                let disabled = field.disabled;
                let title = mediaDataRoomFeaturesTooltipMap[feature];
                if (feature === CollaborationTypes.Remarketing) {
                  const enabledFeatures = getValues("enabledFeatures");
                  if (
                    enabledFeatures.includes(
                      CollaborationTypes.RuleBasedAudiences
                    )
                  ) {
                    disabled = true;
                    title =
                      "Remarketing is automatically enabled due to Rule-based collaboration settings and cannot be changed manually.";
                  }
                }
                return (
                  <FormControl key={feature}>
                    <Checkbox
                      checked={field.value.includes(feature)}
                      data-testid={`${testIds.mediaInsightsDcr.collaborationConfigurationStepRole.allowedFeaturesHelper}${feature}`}
                      disabled={disabled}
                      key={feature}
                      label={
                        <Fragment>
                          {feature}
                          <span
                            style={{
                              lineHeight: 1,
                              verticalAlign: "middle",
                              whiteSpace: "nowrap",
                            }}
                          >
                            &nbsp;
                            <Tooltip title={title}>
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </Tooltip>
                          </span>
                        </Fragment>
                      }
                      onChange={(event) => {
                        field.onChange(
                          (field.value as CollaborationTypes[]).includes(
                            feature
                          )
                            ? (field.value as CollaborationTypes[]).filter(
                                (i) => i !== feature
                              )
                            : [
                                ...new Set(
                                  [...field.value, feature].concat(
                                    feature ===
                                      CollaborationTypes.RuleBasedAudiences
                                      ? [CollaborationTypes.Remarketing]
                                      : []
                                  )
                                ),
                              ]
                        );
                        /**
                         * NOTE: A lot of Insights features rely on `showAbsoluteAudienceSizes` to be turned on,
                         * hence switching it on automatically when Insights is enabled.
                         */
                        if (
                          feature === CollaborationTypes.Insights &&
                          event.target.checked
                        ) {
                          setValue("showAbsoluteAudienceSizes", true);
                        }
                      }}
                      slotProps={checkboxSlotProps}
                    />
                  </FormControl>
                );
              })}
            </Stack>
          </FormControl>
        );
      }}
    />
  );
});
CollaborationTypesCheckboxes.displayName = "CollaborationTypesCheckboxes";

export default CollaborationTypesCheckboxes;
