import { useAuth0 } from "@auth0/auth0-react";
import { useCurrentUserDomainQuery } from "@decentriq/graphql/dist/hooks";
import { type CurrentUserDomainQuery } from "@decentriq/graphql/dist/types";

interface CurrentUserOrganizationBrandingHookResult {
  domain: string;
  logoBase64: CurrentUserDomainQuery["myself"]["organizationLogo"];
  organizationName: string;
}

const useCurrentUserOrganizationBranding =
  (): CurrentUserOrganizationBrandingHookResult => {
    const { user } = useAuth0();
    const { email: currentUserEmail = "" } = user || {};
    const domain =
      currentUserEmail.slice(currentUserEmail.indexOf("@") + 1).toLowerCase() ||
      "";
    const { data: currentUserDomainData } = useCurrentUserDomainQuery();
    const logoBase64 = currentUserDomainData?.myself?.organizationLogo;
    const organizationName =
      currentUserDomainData?.myself?.organization?.name ??
      "Undefined organisation";

    return {
      domain,
      logoBase64,
      organizationName,
    };
  };

export default useCurrentUserOrganizationBranding;
