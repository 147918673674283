import { DqEmptyData } from "@decentriq/components";
import { useTheme } from "@mui/joy";
import { ResponsiveRadar } from "@nivo/radar";
import { memo, useMemo } from "react";
import {
  SuppressedSegmentsInformation,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";
import {
  SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
  SHARE_IN_OVERLAP_CHART_KEY,
} from "../../constants";

const RadarChart: React.FC = () => {
  const {
    aggregationData,
    hasSuppressedValues,
    hasHiddenValues,
    possibleValues,
    suppressedValues,
    hiddenValues,
  } = useMediaDataRoomOverlapInsights();

  const { palette } = useTheme();

  const chartData = useMemo(
    () =>
      aggregationData.map((data) => ({
        [SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY]:
          data.shareInAddressableAudience,
        // Keys defined as strings here as there is no way for now neither
        // to customize labels inside of a tooltip, nor to pass a custom tooltip
        // Keys defined here are being set as properties names inside of a tooltip
        [SHARE_IN_OVERLAP_CHART_KEY]: data.shareInOverlap,
        aggregationName: data.aggregationName,
      })),
    [aggregationData]
  );

  return (
    <>
      {(hasSuppressedValues || hasHiddenValues) && (
        <SuppressedSegmentsInformation
          hiddenValues={hiddenValues}
          possibleValues={possibleValues}
          suppressedValues={suppressedValues}
        />
      )}
      {chartData.length ? (
        <ResponsiveRadar
          colors={[palette.chart["900"], palette.chart["500"]]}
          data={chartData}
          indexBy="aggregationName"
          {...(aggregationData.length > 30 ? { gridLabel: () => null } : {})}
          animate={false}
          keys={[
            SHARE_IN_OVERLAP_CHART_KEY,
            SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
          ]}
          margin={{
            bottom: 24,
            top: 32,
          }}
          valueFormat={(value) => `${((value || 0) * 100).toFixed(1)}%`}
        />
      ) : (
        <DqEmptyData title="No chart data available" />
      )}
    </>
  );
};

RadarChart.displayName = "RadarChart";

export default memo(RadarChart);
