import { testIds } from "@decentriq/utils";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  ModalDialog,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { forwardRef, memo, useCallback, useMemo } from "react";
import { OrganizationSheet } from "../../components";
import {
  useCreationWizardConfiguration,
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "../../contexts";
import { MediaDataRoomCreationStep } from "../../types";

interface CollaborationRequestStepProps {
  characterLimit?: number;
}

const CollaborationRequestStep = memo(
  forwardRef<HTMLDivElement, CollaborationRequestStepProps>(
    ({ characterLimit = 800 }, ref) => {
      const {
        requestForCollaborationMessage,
        setRequestForCollaborationMessage,
        handleSendCollaborationRequest,
      } = useCreationWizardConfiguration();
      const { selectedPublisher } = useCreationWizardPublisher();
      const { handleBackStep, activeStep } = useCreationWizardStepper();
      const { selectedDataPartner } = useCreationWizardDataPartner();
      const isDataPartnerStep =
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER;
      const handleChange: React.ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      > = useCallback(
        (event) => {
          const message = event.target.value;
          setRequestForCollaborationMessage(
            message.length >= characterLimit
              ? message.slice(0, characterLimit)
              : message
          );
        },
        [characterLimit, setRequestForCollaborationMessage]
      );
      const characterCount = useMemo(
        () => requestForCollaborationMessage.length,
        [requestForCollaborationMessage.length]
      );
      const isRequestForCollaborationMessageEmpty = useMemo(
        () => requestForCollaborationMessage.trim().length === 0,
        [requestForCollaborationMessage]
      );
      const orgDetails: {
        name: string;
        logo: string | undefined | null;
        marketIds: string[];
      } | null = isDataPartnerStep
        ? selectedDataPartner
          ? {
              logo: selectedDataPartner.dataPartnerLogo,
              marketIds: selectedDataPartner.marketIds,
              name: selectedDataPartner.dataPartnerName,
            }
          : null
        : selectedPublisher
          ? {
              logo: selectedPublisher.publisherLogo,
              marketIds: selectedPublisher.marketIds,
              name: selectedPublisher.publisherName,
            }
          : null;
      return (
        <ModalDialog ref={ref}>
          <DialogTitle>
            {isDataPartnerStep ? "Contact data partner" : "Contact publisher"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="body-md" mb={2}>
              {isDataPartnerStep
                ? "This data partner needs to create a usage configuration for you. Please reach out to them about your data needs."
                : "This publisher needs to create an activation configuration for you. Please reach out to them about your data needs."}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              {orgDetails ? (
                <OrganizationSheet
                  ids={orgDetails.marketIds}
                  logo={orgDetails.logo}
                  name={orgDetails.name}
                />
              ) : null}
            </Box>
            <FormControl>
              <FormLabel sx={{ fontSize: "12px", pl: "2px" }}>
                {`Message (${characterCount}/${characterLimit})`}
              </FormLabel>
              <Textarea
                data-testid={
                  testIds.mediaInsightsDcr
                    .mediaInsightsDcrCreateCollaborationRequestStep
                    .requestTextarea
                }
                minRows={4}
                onChange={handleChange}
                placeholder={`Enter message to the ${
                  isDataPartnerStep ? "data partner" : "publisher"
                }`}
                sx={{
                  pl: 1,
                }}
                value={requestForCollaborationMessage}
              />
            </FormControl>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleBackStep}>Back</Button>
            <Tooltip
              title={
                isRequestForCollaborationMessageEmpty
                  ? "Please enter message"
                  : null
              }
            >
              <span>
                <Button
                  color="primary"
                  data-testid={
                    testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                      .submitButton
                  }
                  disabled={isRequestForCollaborationMessageEmpty}
                  onClick={handleSendCollaborationRequest}
                  variant="solid"
                >
                  Send
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </ModalDialog>
      );
    }
  )
);
CollaborationRequestStep.displayName = "CollaborationRequestStep";

export default CollaborationRequestStep;
