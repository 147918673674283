import { alpha, createTheme, darken } from "@mui/material";

const BORDER_RADIUS = 6;
const FONT_WEIGHT_SEMI_BOLD = 500;

export const createDefaultTheme = () => {
  const theme = createTheme();
  return createTheme({
    components: {
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: "0.9375rem",
            fontWeight: FONT_WEIGHT_SEMI_BOLD,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => {
            if (
              ownerState.variant === "contained" &&
              ownerState.color &&
              ownerState.color !== "inherit"
            ) {
              return {
                "&:active": {
                  backgroundColor: theme.palette[ownerState.color].light,
                },
                "&:hover": {
                  backgroundColor: theme.palette[ownerState.color].light,
                },
                fontSize: theme.typography.pxToRem(14),
                lineHeight: "1.25rem",
                padding: "8px 12px",
              };
            }
            if (ownerState.variant === "tonal") {
              return {
                "&:hover": {
                  backgroundColor: alpha(
                    ownerState.color && ownerState.color !== "inherit"
                      ? theme.palette[ownerState.color].main
                      : theme.palette.text.primary,
                    theme.palette.action.focusOpacity,
                  ),
                },
                backgroundColor: ownerState.disabled
                  ? alpha(
                      theme.palette.action.disabledBackground,
                      theme.palette.action.hoverOpacity,
                    )
                  : alpha(
                      ownerState.color && ownerState.color !== "inherit"
                        ? theme.palette[ownerState.color].main
                        : theme.palette.text.primary,
                      theme.palette.action.hoverOpacity,
                    ),
                color:
                  ownerState.color && ownerState.color !== "inherit"
                    ? theme.palette[ownerState.color].dark
                    : theme.palette.text.primary,
              };
            }
            return {
              ...(ownerState.variant !== "contained" &&
                ownerState.color &&
                ownerState.color !== "inherit" && {
                  "&:hover": {
                    color: darken(theme.palette[ownerState.color].main, 0.2),
                  },
                  color: theme.palette[ownerState.color].dark,
                }),
              ...(ownerState.variant === "outlined" &&
                ownerState.color &&
                ownerState.color !== "inherit" && {
                  "&:hover": {
                    borderColor: theme.palette[ownerState.color].dark,
                  },
                  borderColor: alpha(theme.palette[ownerState.color].dark, 0.5),
                }),
              fontSize: theme.typography.pxToRem(14),
              lineHeight: "1.25rem",
              padding: "8px 12px",
            };
          },
          startIcon: ({ ownerState }) => ({
            "& > *:nth-of-type(1)": {
              fontSize: "inherit",
            },
            fontSize:
              ownerState.size === "large" || ownerState.size === "xlarge"
                ? "1.125rem"
                : ownerState.size === "xsmall" || ownerState.size === "xxsmall"
                  ? "0.875rem"
                  : "1rem",
            marginLeft: 0,
            marginRight: "calc(1em - 10px)",
          }),
        },
        variants: [
          {
            props: {
              size: "xlarge",
            },
            style: {
              fontSize: theme.typography.pxToRem(16),
              lineHeight: "1.5rem",
              padding: "10px 16px",
            },
          },
          {
            props: {
              size: "large",
            },
            style: {
              fontSize: theme.typography.pxToRem(16),
              lineHeight: "1.5rem",
              padding: "8px 14px",
            },
          },
          {
            props: {
              size: "small",
            },
            style: {
              fontSize: theme.typography.pxToRem(14),
              lineHeight: "1.25rem",
              padding: "6px 10px",
            },
          },
          {
            props: {
              size: "xsmall",
            },
            style: {
              fontSize: theme.typography.pxToRem(12),
              lineHeight: "1rem",
              padding: "6px 8px",
            },
          },
          {
            props: {
              size: "xxsmall",
            },
            style: {
              fontSize: theme.typography.pxToRem(12),
              lineHeight: "1rem",
              padding: "4px 6px",
            },
          },
          {
            props: {
              variant: "outlined",
            },
            style: {
              fontSize: theme.typography.pxToRem(14),
              lineHeight: "1.25rem",
              padding: "7px 11px",
            },
          },
          {
            props: {
              size: "xlarge",
              variant: "outlined",
            },
            style: {
              fontSize: theme.typography.pxToRem(16),
              lineHeight: "1.5rem",
              padding: "9px 15px",
            },
          },
          {
            props: {
              size: "large",
              variant: "outlined",
            },
            style: {
              fontSize: theme.typography.pxToRem(16),
              lineHeight: "1.5rem",
              padding: "7px 13px",
            },
          },
          {
            props: {
              size: "small",
              variant: "outlined",
            },
            style: {
              fontSize: theme.typography.pxToRem(14),
              lineHeight: "1.25rem",
              padding: "5px 9px",
            },
          },
          {
            props: {
              size: "xsmall",
              variant: "outlined",
            },
            style: {
              fontSize: theme.typography.pxToRem(12),
              lineHeight: "1rem",
              padding: "5px 7px",
            },
          },
          {
            props: {
              size: "xxsmall",
              variant: "outlined",
            },
            style: {
              fontSize: theme.typography.pxToRem(12),
              lineHeight: "1rem",
              padding: "3px 5px",
            },
          },
        ],
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false,
        },
        styleOverrides: {
          root: {
            fontWeight: FONT_WEIGHT_SEMI_BOLD,
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          strong {
            font-weight: ${FONT_WEIGHT_SEMI_BOLD};
          }
        `,
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "space-between",
            padding: theme.spacing(2, 2.5),
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: theme.spacing(2, 2.5),
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: theme.spacing(2, 2.5),
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            "&:empty": {
              display: "none",
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          TouchRippleProps: { center: false },
        },
        styleOverrides: {
          root: {
            borderRadius: BORDER_RADIUS,
            fontSize: "1rem",
            minWidth: "36px",
            padding: "10px",
          },
        },
        variants: [
          {
            props: {
              size: "xlarge",
            },
            style: {
              fontSize: theme.typography.pxToRem(18),
              minWidth: "44px",
              padding: "13px",
            },
          },
          {
            props: {
              size: "large",
            },
            style: {
              fontSize: theme.typography.pxToRem(18),
              minWidth: "40px",
              padding: "11px",
            },
          },
          {
            props: {
              size: "small",
            },
            style: {
              fontSize: theme.typography.pxToRem(16),
              minWidth: "32px",
              padding: "8px !important",
            },
          },
          {
            props: {
              size: "xsmall",
            },
            style: {
              fontSize: theme.typography.pxToRem(14),
              minWidth: "28px",
              padding: "7px",
            },
          },
          {
            props: {
              size: "xxsmall",
            },
            style: {
              fontSize: theme.typography.pxToRem(14),
              minWidth: "24px",
              padding: "5px",
            },
          },
        ],
      },
      MuiInputLabel: {
        defaultProps: {
          size: "small",
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            fontWeight: FONT_WEIGHT_SEMI_BOLD,
          },
        },
      },
      // @ts-expect-error - MuiLoadingButton wasn't properly typed in the MUI's `Theme`
      MuiLoadingButton: {
        styleOverrides: {
          loadingIndicatorStart: {
            left: theme.spacing(1),
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0.75, 1),
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          size: "small",
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            border: "1px solid #bbb",
            boxShadow: "none !important",
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              elevation: 0,
              sx: {
                boxShadow: "none",
              },
            },
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom",
            },
            transformOrigin: {
              horizontal: "left",
              vertical: "top",
            },
          },
          size: "small",
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: { borderBottomColor: "transparent" },
          head: { fontWeight: 500 },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            fontSize: "1rem",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            lineHeight: "1.25rem",
            padding: theme.spacing(0.875),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            boxShadow: "none",
            color: theme.palette.common.white,
            fontSize: "0.75rem",
            lineHeight: "1rem",
            maxWidth: theme.spacing(40),
          },
          tooltipPlacementBottom: {
            margin: `${theme.spacing(0.5, 0)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
          },
          tooltipPlacementLeft: {
            margin: `${theme.spacing(0, 0.5)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
          },
          tooltipPlacementRight: {
            margin: `${theme.spacing(0, 0.5)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
          },
          tooltipPlacementTop: {
            margin: `${theme.spacing(0.5, 0)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          content: {
            padding: theme.spacing(0, 1),
          },
        },
      },
    },
    palette: {
      action: {
        activatedOpacity: 0.24,
        active: "rgba(0, 0, 0, 0.56)",
        disabled: "rgba(0, 0, 0, 0.32)",
        disabledBackground: "rgba(0, 0, 0, 0.16)",
        disabledOpacity: 0.32,
        focus: "rgba(0, 0, 0, 0.24)",
        focusOpacity: 0.24,
        hover: "rgba(0, 0, 0, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(0, 0, 0, 0.16)",
        selectedOpacity: 0.16,
      },
      contrastThreshold: 3,
      divider: "rgba(0, 0, 0, 0.16)",
      error: {
        main: "#DA2324",
      },
      info: {
        main: "#407AF6",
      },
      primary: {
        light: "#03D8C3",
        main: "#14B8A6",
      },
      secondary: {
        main: "#926DE4",
      },
      success: {
        main: "#14B8A6",
      },
      tonalOffset: 0.2,
      warning: {
        light: "rgba(239, 108, 0, 0.08)",
        main: "#F19B3F",
      },
    },
    shape: {
      borderRadius: BORDER_RADIUS,
    },
    typography: {
      body2: {
        lineHeight: "1.25rem",
      },
      button: {
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
        lineHeight: "1.5rem",
        textTransform: "none",
      },
      fontFamily: `"Inter", -apple-system, BlinkMacSystemFont, "Avenir Next", Avenir, "Segoe UI", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      fontWeightSemiBold: FONT_WEIGHT_SEMI_BOLD,
    },
  });
};

export const materialTheme = createDefaultTheme();
