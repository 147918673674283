import {
  faBan,
  faPen,
  faPowerOff,
  faQuestionCircle,
  faShieldKeyhole,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, styled, Tooltip } from "@mui/joy";
import { memo } from "react";
import { StatusTooltipTitle } from "components";

const StateIconRoot = styled("span")(({ theme }) => ({
  "& > svg": {
    fontSize: "1rem",
  },
  alignItems: "center",
  display: "flex",
  height: theme.spacing(2),
  justifyContent: "center",
  width: theme.spacing(2),
}));

interface StateIconProps {
  status: string;
}

export const StateIcon = memo<StateIconProps>(({ status }) => {
  switch ((status || "").toUpperCase()) {
    case "BUSY":
      return (
        <StateIconRoot>
          <CircularProgress />
        </StateIconRoot>
      );
    case "PUBLISHED":
      return (
        <StateIconRoot>
          <FontAwesomeIcon fixedWidth={true} icon={faShieldKeyhole} />
        </StateIconRoot>
      );
    case "DRAFT":
      return (
        <StateIconRoot>
          <FontAwesomeIcon fixedWidth={true} icon={faPen} />
        </StateIconRoot>
      );
    case "STOPPED":
      return (
        <StateIconRoot>
          <FontAwesomeIcon fixedWidth={true} icon={faBan} />
        </StateIconRoot>
      );
    case "DEACTIVATED":
      return (
        <StateIconRoot>
          <FontAwesomeIcon fixedWidth={true} icon={faPowerOff} />
        </StateIconRoot>
      );
    default:
      return (
        <StateIconRoot>
          <FontAwesomeIcon fixedWidth={true} icon={faQuestionCircle} />
        </StateIconRoot>
      );
  }
});

interface StateIconWithTooltipProps {
  status: string;
  statusUpdatedAt?: string;
  statusTimePrefix?: string;
  statusTimeSuffix?: string;
  className?: string;
}

const StateIconWithTooltip = memo<StateIconWithTooltipProps>(
  ({
    status = "N/A",
    statusUpdatedAt,
    statusTimePrefix = "",
    statusTimeSuffix = "",
    className,
  }) => {
    return (
      <Tooltip
        placement="top-start"
        title={
          <StatusTooltipTitle
            status={status}
            statusTimePrefix={statusTimePrefix}
            statusTimeSuffix={statusTimeSuffix}
            statusUpdatedAt={statusUpdatedAt}
          />
        }
      >
        <span className={className}>
          <StateIcon status={status} />
        </span>
      </Tooltip>
    );
  }
);

export default StateIconWithTooltip;
