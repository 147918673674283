import { Chip, Skeleton, Stack } from "@mui/joy";
import { Fragment, useCallback } from "react";
import { useCreationWizardPublisher } from "../../contexts";

const SelectPublisherStepMarketFilters: React.FC = () => {
  const {
    advertiserMarketFilters,
    isAdvertiserMarketFiltersLoading,
    updateAdvertiserMarketFilters,
  } = useCreationWizardPublisher();
  const handleClick = useCallback(
    (marketId: string) =>
      updateAdvertiserMarketFilters(
        advertiserMarketFilters
          .map((filter) =>
            filter.id === marketId
              ? { ...filter, selected: !filter.selected }
              : filter
          )
          .filter(({ selected }) => selected)
          .map(({ id }) => id)
      ),
    [advertiserMarketFilters, updateAdvertiserMarketFilters]
  );
  const handleAllClick = useCallback(
    () => updateAdvertiserMarketFilters([]),
    [updateAdvertiserMarketFilters]
  );
  const areAllMarketsAllowed = advertiserMarketFilters.every(
    ({ selected }) => !selected
  );
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      maxHeight="calc(28px * 3 + 8px * 2)" // NOTE: Relies on gap being 8px and chip height being 28px
      overflow="auto"
    >
      {isAdvertiserMarketFiltersLoading ? (
        Array.from(
          { length: Math.floor(Math.random() * 10) + 5 },
          (value, index) => (
            <Skeleton
              height={28}
              key={index}
              sx={{ borderRadius: "1.5rem" }}
              variant="rectangular"
              width={Math.floor(Math.random() * 50) + 50}
            />
          )
        )
      ) : advertiserMarketFilters.length > 0 ? (
        <Fragment>
          <Chip
            key={0}
            onClick={handleAllClick}
            variant={areAllMarketsAllowed ? "solid" : "outlined"}
          >
            All
          </Chip>
          {advertiserMarketFilters.map(({ id, name, selected }) => (
            <Chip
              key={id}
              onClick={(event) => handleClick(id)}
              variant={selected ? "solid" : "outlined"}
            >{`${name} (${id})`}</Chip>
          ))}
        </Fragment>
      ) : null}
    </Stack>
  );
};
SelectPublisherStepMarketFilters.displayName =
  "SelectPublisherStepMarketFilters";

export default SelectPublisherStepMarketFilters;
