import { matchingIdTypeAndHashingAlgorithmPresentation } from "@decentriq/models";
import { Alert, Chip } from "@mui/joy";
import { memo } from "react";
import {
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "../../contexts";
import { MediaDataRoomCreationStep } from "../../types";

const MatchingIdChip: React.FC = memo(() => {
  const { activeStep } = useCreationWizardStepper();
  const {
    selectedPublisher,
    hasSkippedSelection: hasSkippedPublisherSelection,
  } = useCreationWizardPublisher();
  const { selectedDataPartner } = useCreationWizardDataPartner();
  const matchingIdFormat =
    activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER
      ? selectedPublisher?.matchingIdFormat ===
          selectedDataPartner?.matchingIdFormat || hasSkippedPublisherSelection
        ? selectedDataPartner?.matchingIdFormat
        : null
      : selectedPublisher?.matchingIdFormat;
  const matchingIdHashingAlgorithm =
    activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER
      ? selectedPublisher?.matchingIdHashingAlgorithm ===
          selectedDataPartner?.matchingIdHashingAlgorithm ||
        hasSkippedPublisherSelection
        ? selectedDataPartner?.matchingIdHashingAlgorithm
        : null
      : selectedPublisher?.matchingIdHashingAlgorithm;
  return !matchingIdFormat && !matchingIdHashingAlgorithm ? (
    <Alert>The Matching ID is incompatible with the publisher's</Alert>
  ) : (
    <Chip size="lg">
      {matchingIdTypeAndHashingAlgorithmPresentation({
        matchingIdFormat: matchingIdFormat!,
        matchingIdHashingAlgorithm,
      })}
    </Chip>
  );
});
MatchingIdChip.displayName = "MatchingIdChip";

export default MatchingIdChip;
