import { Chip, FormControl, FormLabel } from "@mui/joy";
import { memo } from "react";

const MatchingIdChip: React.FC = memo(() => {
  return (
    <FormControl>
      <FormLabel>Matching ID</FormLabel>
      <Chip size="lg">Hashed email</Chip>
    </FormControl>
  );
});
MatchingIdChip.displayName = "MatchingIdChip";

export default MatchingIdChip;
