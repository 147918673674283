import { DqLoader } from "@decentriq/components";
import { Box, Stack } from "@mui/joy";
import { memo } from "react";
import { useApiTokenActions, useApiTokens } from "features/accessTokens/hooks";
import AccessTokensConstructorForm from "../components/AccessTokensConstructorForm";
import AccessTokensTile from "../components/AccessTokensTile";

const AccessTokensConstructor = memo(() => {
  const { data: tokensData, isLoading } = useApiTokens();
  const { revokeApiToken, createApiToken } = useApiTokenActions();
  const tokens = tokensData || [];
  return (
    <Box sx={{ height: "100%", mt: 2, overflow: "auto" }}>
      <AccessTokensConstructorForm
        onCreateApiToken={createApiToken}
        tokens={tokens}
      />
      {isLoading ? (
        <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
          <DqLoader />
        </Stack>
      ) : tokens.length > 0 ? (
        <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
          {tokens.map((token, index) => (
            <AccessTokensTile
              key={token.token}
              onRevokeApiToken={revokeApiToken}
              token={token}
            />
          ))}
        </Stack>
      ) : null}
    </Box>
  );
});
AccessTokensConstructor.displayName = "AccessTokensConstructor";

export default AccessTokensConstructor;
