import { CollaborationTypes, RawMatchingID } from "@decentriq/types";
import { z } from "zod";

const checkUniqueEmails = (items: string[], ctx: z.RefinementCtx) => {
  const uniqueValues = new Map<string, number>();
  items.forEach((item, idx) => {
    const firstAppearanceIndex = uniqueValues.get(item);
    if (firstAppearanceIndex !== undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Email is not unique`,
        path: [idx],
      });
    }
    uniqueValues.set(item, idx);
  });
};

export const schema = z
  .object({
    advertiserEmails: z
      .array(z.string().email("Email is invalid"))
      .min(
        1,
        "At least one email is required. Type in user email and press Enter"
      )
      .superRefine(checkUniqueEmails),
    agencyEmails: z
      .array(z.string().email("Email is invalid"))
      .superRefine(checkUniqueEmails),
    dataPartnerEmails: z
      .array(z.string().email("Email is invalid"))
      .superRefine(checkUniqueEmails),
    enableAdvertiserAudienceDownload: z.boolean(),
    enabledFeatures: z.array(z.nativeEnum(CollaborationTypes)),
    matchingId: z.nativeEnum(RawMatchingID, {
      message: "Matching ID is required",
    }),
    name: z
      .string({ required_error: "Name is required" })
      .min(1, "Name is required")
      .max(800, "Data room name must be no longer than 800 characters"),
    observerEmails: z
      .array(z.string().email("Email is invalid"))
      .superRefine(checkUniqueEmails),
    publisherEmails: z
      .array(z.string().email("Email is invalid"))
      .min(
        1,
        "At least one email is required. Type in user email and press Enter"
      )
      .superRefine(checkUniqueEmails),
    showAbsoluteAudienceSizes: z.boolean(),
  })
  .superRefine((items, ctx) => {
    if (
      items.enabledFeatures.length === 0 &&
      !items.showAbsoluteAudienceSizes
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `At least one collaboration type or "Show absolute audience sizes" must be selected`,
        path: ["enabledFeatures"],
      });
    }
    /**
     * NOTE: Order is now not important, i.e. all occurances are reported as dupes.
     * `zod` does not know user's selected role, so if requirements change for the
     * "first" occurance (contextually) to get a free pass, then current `superRefine`
     * routine would have to be moved to the `FormProvider`, keys below sorted accordingly,
     * and reporting of the second issue below removed.
     */
    const uniqueEmailsArrayKeys = [
      "publisherEmails" as const,
      "advertiserEmails" as const,
      "dataPartnerEmails" as const,
      "observerEmails" as const,
      "agencyEmails" as const,
    ];
    const uniqueValues = new Map<string, [string, number]>();
    uniqueEmailsArrayKeys.forEach((key) => {
      items[key].forEach((item, idx) => {
        const [firstAppearanceKey, firstAppearanceIndex] =
          uniqueValues.get(item) || [];
        if (
          firstAppearanceKey !== undefined &&
          firstAppearanceIndex !== undefined
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Email is not unique`,
            path: [key, idx],
          });
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Email is not unique`,
            path: [firstAppearanceKey, firstAppearanceIndex],
          });
        }
        uniqueValues.set(item, [key, idx]);
      });
    });
  });

export type SchemaType = z.infer<typeof schema>;
