import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useApproveSubmittedDataRoomRequestMutation,
  useDeleteRequestMutation,
  useEnclaveConfigurationPinQuery,
  useMergeSubmittedDataRoomRequestMutation,
  useRequestComputeNodePermissionsQuery,
  useSubmitDataRoomRequestMutation,
  useSubmittedRequestSigneesQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  SubmittedDataRoomRequestProcessingStatus,
  SubmittedDataRoomRequestsDocument,
} from "@decentriq/graphql/dist/types";
import { faCheck, faMerge, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { Actions } from "components";
import { ActionsRawButton } from "components/base/Actions/Actions";
import { usePublishedDataRoom, useRequest, useRequestsVars } from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import DataRoomRequestSubmissionDialog from "../DataRoomRequestSubmissionDialog/DataRoomRequestSubmissionDialog";
import SubmitForApprovalButton from "../SubmitForApprovalButton/SubmitForApprovalButton";
import SubmittedRequestApprovalDialog from "../SubmittedRequestApprovalDialog/SubmittedRequestApprovalDialog";
import SubmittedRequestMergeDialog from "../SubmittedRequestMergeDialog/SubmittedRequestMergeDialog";

interface RequestActionsProps {
  requestId: string;
}

interface DraftRequestActionsProps {
  requestId: string;
}

const DraftRequestActions: React.FC<DraftRequestActionsProps> = memo(
  ({ requestId }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { computeNodeId } = useRequest();
    const [submisionWithValidationState, setSubmissionWithValidationState] =
      useState<boolean | undefined>(undefined);
    const isSubmissionDialogOpen = submisionWithValidationState !== undefined;
    const openSubmissionDialog = useCallback(setSubmissionWithValidationState, [
      setSubmissionWithValidationState,
    ]);
    const closeSubmissionDialog = useCallback(
      () => setSubmissionWithValidationState(undefined),
      [setSubmissionWithValidationState]
    );
    const { data: requestPermissionsData } =
      useRequestComputeNodePermissionsQuery({
        fetchPolicy: "cache-only",
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Data clean room participants could not be retrieved."
            )
          );
        },
        variables: { computeNodeId },
      });
    const { dataRoomId, driverAttestationHash, switchToOverview } =
      usePublishedDataRoom();
    const [deleteRequestMutation, { loading: deletingRequest }] =
      useDeleteRequestMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Request could not be deleted.")
          );
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              __typename: "DataRoomRequest",
              id: requestId,
            }),
          });
          cache.gc();
        },
        variables: { id: requestId },
      });
    const [submitDataRoomRequest, { loading: submittingRequest }] =
      useSubmitDataRoomRequestMutation({
        onCompleted: (data) => {
          if (
            data.submitDataRoomRequest.status ===
            SubmittedDataRoomRequestProcessingStatus.Merged
          ) {
            switchToOverview();
          }
          closeSubmissionDialog();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Request could not be submitted.`
            )
          );
        },
        refetchQueries: [
          {
            query: SubmittedDataRoomRequestsDocument,
            variables: { id: dataRoomId },
          },
        ],
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              __typename: "DataRoomRequest",
              id: requestId,
            }),
          });
          cache.gc();
        },
        variables: {
          input: {
            dcrHash: dataRoomId,
            driverAttestationHash,
            id: requestId,
            validate: !!submisionWithValidationState,
          },
        },
      });
    return (
      <Box onClick={(event) => event.stopPropagation()}>
        <Actions
          actions={{
            buttons: [
              {
                childComponent: SubmitForApprovalButton,
                component: ActionsRawButton,
                disabled: submittingRequest,
                loading: submittingRequest,
                name: "Submit for approval",
                onClick: openSubmissionDialog,
                type: "Submit for approval",
              },
              {
                disabled: deletingRequest,
                icon: faTrashCan,
                iconColor: "var(--joy-palette-neutral-500)",
                loading: deletingRequest,
                name: "Delete",
                onClick: deleteRequestMutation,
                tooltipTitle: "Delete",
                type: "Delete",
              },
            ],
          }}
        />
        {isSubmissionDialogOpen && (
          <DataRoomRequestSubmissionDialog
            loading={submittingRequest}
            onCancel={closeSubmissionDialog}
            onConfirm={submitDataRoomRequest}
            open={isSubmissionDialogOpen}
            warning={
              !requestPermissionsData?.draftNode.permissions.nodes.length ? (
                <Alert color="warning">
                  Note: this computation has no analyst permissions set. Please
                  double-check that it's intentional.
                </Alert>
              ) : null
            }
          />
        )}
      </Box>
    );
  }
);
DraftRequestActions.displayName = "DraftRequestActions";

interface SubmittedRequestActionsProps {
  requestId: string;
}

const SubmittedRequestActions: React.FC<SubmittedRequestActionsProps> = memo(
  ({ requestId }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [openApprovalDialog, setOpenApprovalDialog] =
      useState<boolean>(false);
    const [openMergeDialog, setOpenMergeDialog] = useState<boolean>(false);
    const { cache } = useApolloClient();
    const {
      dataRoomId,
      driverAttestationHash,
      switchToOverview,
      enableAutomergeFeature,
    } = usePublishedDataRoom();
    const {
      data: enclaveConfigurationPinData,
      loading: enclaveConfigurationPinLoading,
    } = useEnclaveConfigurationPinQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Enclave configuration pin could not be retrieved."
          )
        );
      },
      variables: { dataRoomId },
    });
    const { publishedDataRoom } = enclaveConfigurationPinData || {};
    const { enclaveConfigurationPin } = publishedDataRoom || {};
    const [approveRequest, { loading: approvingRequest }] =
      useApproveSubmittedDataRoomRequestMutation({
        onCompleted: ({ approveSubmittedDataRoomRequest: { status } }) => {
          if (status === SubmittedDataRoomRequestProcessingStatus.Merged) {
            cache.evict({
              id: cache.identify({
                __typename: "SubmittedDataRoomRequest",
                id: requestId,
              }),
            });
            cache.gc();
            switchToOverview();
          } else {
            enqueueSnackbar(`Request has been approved`);
            setOpenApprovalDialog(false);
          }
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Can't approve request")
          );
        },
      });
    const [mergeRequest, { loading: mergingRequest }] =
      useMergeSubmittedDataRoomRequestMutation({
        onCompleted: () => {
          switchToOverview();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Can't merge request")
          );
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              __typename: "SubmittedDataRoomRequest",
              id: requestId,
            }),
          });
          cache.gc();
        },
        variables: {
          input: {
            dcrHash: dataRoomId,
            driverAttestationHash,
            id: requestId,
          },
        },
      });
    const { user } = useAuth0();
    const currentUserEmail = user?.email;
    const { data } = useSubmittedRequestSigneesQuery({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, "Can't get signees")
        );
      },
      skip: !requestId,
      variables: {
        id: requestId,
      },
    });
    const hasData = !!data?.submittedDataRoomRequest;
    const unmatchingPin =
      hasData &&
      data.submittedDataRoomRequest.enclaveConfigurationPin !==
        enclaveConfigurationPin;
    const needsRebase = unmatchingPin && !enableAutomergeFeature;
    const canApprove =
      !needsRebase &&
      data?.submittedDataRoomRequest?.signers?.nodes?.some(
        (s) => s.user?.email === currentUserEmail && !s.signature
      );
    const canMerge =
      !needsRebase &&
      data?.submittedDataRoomRequest?.signers?.nodes?.every((s) =>
        Boolean(s.signature)
      );
    return enclaveConfigurationPinLoading ? null : (
      <Box onClick={(event) => event.stopPropagation()}>
        <Actions
          actions={{
            buttons: [
              {
                component: Button,
                disabled: approvingRequest,
                hidden: !canApprove,
                icon: <FontAwesomeIcon icon={faCheck} />,
                name: "Approve",
                onClick: () => {
                  setOpenApprovalDialog(true);
                },
                type: "Approve",
              },
              {
                component: Button,
                disabled: mergingRequest,
                hidden: !canMerge,
                icon: <FontAwesomeIcon icon={faMerge} />,
                name: "Integrate",
                onClick: () => {
                  setOpenMergeDialog(true);
                },
                type: "Integrate",
              },
            ],
          }}
        />
        {openApprovalDialog && (
          <SubmittedRequestApprovalDialog
            loading={approvingRequest}
            onCancel={() => setOpenApprovalDialog(false)}
            onConfirm={() => {
              approveRequest({
                variables: {
                  input: {
                    commitId:
                      data?.submittedDataRoomRequest?.node?.commitId || "",
                    dcrHash: dataRoomId,
                    driverAttestationHash,
                    id: requestId,
                  },
                },
              });
            }}
            open={openApprovalDialog}
            requestId={requestId}
          />
        )}
        {openMergeDialog && (
          <SubmittedRequestMergeDialog
            loading={mergingRequest}
            onCancel={() => setOpenMergeDialog(false)}
            onConfirm={mergeRequest}
            open={openMergeDialog}
          />
        )}
      </Box>
    );
  }
);
SubmittedRequestActions.displayName = "SubmittedRequestActions";

const RequestActions: React.FC<RequestActionsProps> = memo(({ requestId }) => {
  const { context } = useRequestsVars();
  const { isStopped, isDeactivated } = usePublishedDataRoom();
  if (isStopped || isDeactivated) {
    return null;
  }
  switch (context) {
    case "draft":
      return <DraftRequestActions requestId={requestId} />;
    case "submitted":
      return <SubmittedRequestActions requestId={requestId} />;
    default:
      return null;
  }
});
RequestActions.displayName = "RequestActions";

export default RequestActions;
