import { type DataSourceType } from "@decentriq/graphql/dist/types";
import { useMemo } from "react";
import { useOrganizationFeatures, useOrganizationPreferences } from "hooks";

interface GetDisabledAndFilteredImportSyncItemsHookResult {
  getDisabledItems: (items: DataSourceType[]) => DataSourceType[];
  getFilteredItems: (items: DataSourceType[]) => DataSourceType[];
}

const useGetDisabledAndFilteredImportSyncItems =
  (): GetDisabledAndFilteredImportSyncItemsHookResult => {
    const { organizationId, isLoading: isLoadingOrganizationPreferences } =
      useOrganizationPreferences();
    const { importConnectors } = useOrganizationFeatures({
      organizationId,
      skip: isLoadingOrganizationPreferences,
    });
    const importConnectorsByKey = useMemo(
      () =>
        Object.fromEntries(
          Object.entries(importConnectors).map(([key, value]) => [key, value])
        ),
      [importConnectors]
    );
    return useMemo(
      () => ({
        getDisabledItems: (items: DataSourceType[]): DataSourceType[] =>
          items.filter((item) => importConnectorsByKey[item]?.isDisabled),
        getFilteredItems: (items: DataSourceType[]): DataSourceType[] =>
          items.filter((item) => !importConnectorsByKey[item]?.isHidden),
      }),
      [importConnectorsByKey]
    );
  };

export default useGetDisabledAndFilteredImportSyncItems;
