import { useAuth0 } from "@auth0/auth0-react";
import { DqPortal as Portal } from "@decentriq/components";
import { CollaborationTypes } from "@decentriq/types";
import { useBoolean } from "ahooks";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useApiCore } from "contexts";
import {
  DataRoomTypeSelectDialog,
  DataScienceDataRoomCreateDialog,
  MeasurementDcrCreateDialog,
  MediaDcrCreateDialog,
} from "features/dataRoomCreation/components";
import {
  useCreateAdvanceAnalyticsDataRoom,
  useSubmitCreateMeasurementDcrForm,
  useSubmitCreateMediaDcrForm,
} from "features/dataRoomCreation/hooks";
import { useOrganizationPreferences } from "hooks";
import { DataRoomType } from "models";

interface DataRoomCreateProps {
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DataRoomCreate = memo<DataRoomCreateProps>(({ renderTrigger }) => {
  const [
    isTypeSelectionOpen,
    { setTrue: openTypeSelection, setFalse: closeTypeSelection },
  ] = useBoolean(false);
  const { user } = useAuth0();
  const { isMigrated } = useApiCore();
  const currentUserEmail = user?.email ?? "";
  const [dataRoomType, setDataRoomType] = useState<DataRoomType | null>(null);
  const {
    createDataRoom: createAdvanceAnalyticsDataRoom,
    isCreateDataRoomLoading: isAdvanceAnalyticsDataRoomCreating,
  } = useCreateAdvanceAnalyticsDataRoom(currentUserEmail, {
    onCompleted: () => setDataRoomType(null),
    onError: () => setDataRoomType(null),
  });
  const {
    isAllowedToCreateDataRoom,
    hasAnalyticsFeatures: canViewAnalyticsDataRoom,
    canViewMediaDataRoom,
    canViewMeasurements: canViewMeasurementDataRoom,
    enableAudienceBuilder: enableAudienceBuilderOrgFlag,
    numberOfAvailableDataRoomTypes,
  } = useOrganizationPreferences();
  const enableAudienceBuilderMediaDataRoom = useMemo(
    () => enableAudienceBuilderOrgFlag && isMigrated,
    [enableAudienceBuilderOrgFlag, isMigrated]
  );
  // Open proper modal window depending on allowance selected in Admin for a specific organization
  useEffect(() => {
    if (!isTypeSelectionOpen || !isAllowedToCreateDataRoom) {
      closeTypeSelection();
      return;
    }
    if (
      canViewAnalyticsDataRoom &&
      !(canViewMediaDataRoom || canViewMeasurementDataRoom)
    ) {
      setDataRoomType(DataRoomType.DataScience);
      closeTypeSelection();
    }
    if (
      canViewMediaDataRoom &&
      !(canViewAnalyticsDataRoom || canViewMeasurementDataRoom)
    ) {
      setDataRoomType(DataRoomType.MediaInsights);
      closeTypeSelection();
    }
    if (
      canViewMeasurementDataRoom &&
      !(canViewAnalyticsDataRoom || canViewMediaDataRoom)
    ) {
      setDataRoomType(DataRoomType.Measurement);
      closeTypeSelection();
    }
  }, [
    isTypeSelectionOpen,
    closeTypeSelection,
    isAllowedToCreateDataRoom,
    canViewAnalyticsDataRoom,
    canViewMediaDataRoom,
    canViewMeasurementDataRoom,
  ]);
  const handleDataRoomTypeSetting = useCallback(
    (type: DataRoomType | null) => {
      setDataRoomType(type);
      if (type !== null) {
        closeTypeSelection();
      }
    },
    [setDataRoomType, closeTypeSelection]
  );
  const handleCancel = useCallback(
    () => handleDataRoomTypeSetting(null),
    [handleDataRoomTypeSetting]
  );
  const restartCreation = useCallback(() => {
    handleCancel();
    if (numberOfAvailableDataRoomTypes < 2) {
      return;
    }
    openTypeSelection();
  }, [handleCancel, numberOfAvailableDataRoomTypes, openTypeSelection]);
  const { submit: submitCreateMeasurementDcrForm } =
    useSubmitCreateMeasurementDcrForm({
      onCompleted: () => setDataRoomType(null),
      onError: () => setDataRoomType(null),
    });
  const {
    submit: submitCreateMediaDcrForm,
    publishing,
    publishingCalled,
    publishingError,
    sendCollaborationRequest,
  } = useSubmitCreateMediaDcrForm({
    onCancel: handleCancel,
  });
  return (
    <Fragment>
      {renderTrigger(openTypeSelection)}
      <Portal>
        <Fragment>
          <DataRoomTypeSelectDialog
            onCancel={closeTypeSelection}
            onConfirm={handleDataRoomTypeSetting}
            open={isTypeSelectionOpen}
          />
          <DataScienceDataRoomCreateDialog
            loading={isAdvanceAnalyticsDataRoomCreating}
            onCancel={handleCancel}
            onConfirm={createAdvanceAnalyticsDataRoom}
            open={dataRoomType === DataRoomType.DataScience}
            restartCreation={restartCreation}
          />
          <MediaDcrCreateDialog
            filterCollaborationTypes={(collaborationType) =>
              enableAudienceBuilderMediaDataRoom
                ? collaborationType !== CollaborationTypes.ExclusionTargeting
                : collaborationType !== CollaborationTypes.RuleBasedAudiences
            }
            onCancel={handleCancel}
            open={dataRoomType === DataRoomType.MediaInsights}
            publishing={publishing}
            publishingCalled={publishingCalled}
            publishingError={publishingError}
            restartCreation={restartCreation}
            sendCollaborationRequest={sendCollaborationRequest}
            submit={submitCreateMediaDcrForm}
          />
          <MeasurementDcrCreateDialog
            onClose={handleCancel}
            onSubmit={submitCreateMeasurementDcrForm}
            open={dataRoomType === DataRoomType.Measurement}
            restartCreation={restartCreation}
          />
        </Fragment>
      </Portal>
    </Fragment>
  );
});
DataRoomCreate.displayName = "DataRoomCreate";

export default DataRoomCreate;
