import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { DataRoomCreate } from "features/dataRoomCreation";
import { SidebarMenuItem } from "features/sidebar";
import { useOrganizationPreferences } from "hooks";

const NewDataRoomSidebarMenuItem = memo(() => {
  const { isAllowedToCreateDataRoom } = useOrganizationPreferences();
  return (
    <DataRoomCreate
      renderTrigger={(openDataRoomCreateDialog) => (
        <SidebarMenuItem
          dataTestId={testIds.sidebar.sidebarMenuItems.newDcrButton}
          disabled={!isAllowedToCreateDataRoom}
          icon={faPlus}
          label="New DCR"
          onClick={openDataRoomCreateDialog}
          tooltipTitle={
            !isAllowedToCreateDataRoom
              ? "An active license is required to create new data clean rooms. Please check with your organization administrator or contact our support in case of questions."
              : "New DCR"
          }
        />
      )}
    />
  );
});
NewDataRoomSidebarMenuItem.displayName = "NewDataRoomSidebarMenuItem";

export default NewDataRoomSidebarMenuItem;
