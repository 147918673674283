import { difference, orderBy } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  filterAggregationData,
  type InsightsDetailedViewFormValues,
  type OverlapInsightsAggregationHookResult,
  useOverlapInsightsAggregation,
} from "features/mediaDataRoom";

const useAffinityRatioSegmentDemographicsData =
  (): OverlapInsightsAggregationHookResult => {
    const { watch } = useFormContext<InsightsDetailedViewFormValues>();
    // Get data from the form context
    const selectedDataSortDirection = watch("dataSortDirection");

    const {
      aggregationData: segmentGenderAggregationData,
      isEmptyAggregation: isSegmentGenderAggregationEmpty,
      hasSuppressedValues: hasSegmentGenderSuppressedValues,
      possibleValues: segmentGenderPossibleValues,
      suppressedValues: segmentGenderSuppressedValues,
    } = useOverlapInsightsAggregation({
      aggregation: ["interest", "gender"],
    });
    const {
      aggregationData: segmentAgeAggregationData,
      isEmptyAggregation: isSegmentAgeAggregationEmpty,
      hasSuppressedValues: hasSegmentAgeSuppressedValues,
      possibleValues: segmentAgePossibleValues,
      suppressedValues: segmentAgeSuppressedValues,
    } = useOverlapInsightsAggregation({
      aggregation: ["interest", "age"],
    });

    const filteredSegmentGenderAggregationData =
      segmentGenderAggregationData.filter(({ gender }) =>
        filterAggregationData(gender)
      );
    const filteredSegmentAgeAggregationData = segmentAgeAggregationData.filter(
      ({ age }) => filterAggregationData(age)
    );

    const affinityRatioSegmentDemographicsData = orderBy(
      [
        ...filteredSegmentGenderAggregationData,
        ...filteredSegmentAgeAggregationData,
      ],
      ["affinityRatio"],
      [selectedDataSortDirection]
    );

    const affinityRatioSegmentDemographicsHiddenValues = orderBy(
      difference(
        affinityRatioSegmentDemographicsData.map(
          ({ aggregationName }) => aggregationName
        ),
        affinityRatioSegmentDemographicsData
          .map(({ aggregationName }) => aggregationName)
          .slice(0, 10)
      )
    );

    const suppressedValues = orderBy([
      ...segmentGenderSuppressedValues,
      ...segmentAgeSuppressedValues,
    ]);

    return {
      aggregationData: affinityRatioSegmentDemographicsData.slice(0, 10),
      hasHiddenValues: !!affinityRatioSegmentDemographicsHiddenValues.length,
      hasSuppressedValues:
        hasSegmentGenderSuppressedValues || hasSegmentAgeSuppressedValues,
      hiddenValues: affinityRatioSegmentDemographicsHiddenValues,
      isEmptyAggregation:
        isSegmentGenderAggregationEmpty && isSegmentAgeAggregationEmpty,
      possibleValues: [
        ...segmentGenderPossibleValues,
        ...segmentAgePossibleValues,
      ],
      suppressedValues,
    };
  };

export default useAffinityRatioSegmentDemographicsData;
