import { useAuth0 } from "@auth0/auth0-react";
import {
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { useUpdateEffect } from "@decentriq/hooks";
import {
  DEFAULT_PARTICIPANTS_EMAILS,
  matchingIdTypeToGqlValues,
  mediaDataRoomCollaborationTypes,
} from "@decentriq/models";
import {
  CollaborationTypes,
  MediaDataRoomOrganizationRole,
  MediaDataRoomUserRole,
  type PublishMediaDataRoomInput,
  type RawMatchingID,
} from "@decentriq/types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { type SchemaType } from "../../../DqCreateMediaDcrFormProvider/model";
import {
  CreationWizardConfigurationContextProvider,
  type CreationWizardConfigurationContextValue,
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "../../contexts";
import { MediaDataRoomCreationStep } from "../../types";

export interface ConfigurationWrapperProps extends React.PropsWithChildren {
  submit: (input: PublishMediaDataRoomInput) => Promise<void>;
  sendCollaborationRequest: (input: {
    requestRecipientId: string;
    message: string;
    receiver: "publisher" | "dataPartner";
  }) => Promise<void>;
  filterCollaborationTypes?: (
    value: CollaborationTypes,
    index: number,
    array: CollaborationTypes[]
  ) => boolean;
}

const ConfigurationWrapper = memo<ConfigurationWrapperProps>(
  ({
    children,
    submit,
    sendCollaborationRequest,
    filterCollaborationTypes = () => true,
  }) => {
    const { user = {} } = useAuth0();
    const { email: currentUserEmail } = user || {};
    const {
      organizationRole,
      activeStep,
      canSelectDataPartner,
      hasOwnDataToProvide,
    } = useCreationWizardStepper();
    const {
      selectedPublisher,
      hasSelectedPublisher,
      hasSkippedSelection: hasSkippedPublisherSelection,
    } = useCreationWizardPublisher();
    const {
      selectedDataPartner,
      hasSelectedDataPartner,
      hasSkippedSelection: hasSkippedDataPartnerSelection,
    } = useCreationWizardDataPartner();
    const [dataRoomName, setDataRoomName] = useState<string>("");
    // Participants
    const withDataPartner = useMemo<boolean>(
      () =>
        (canSelectDataPartner &&
          (!hasSkippedDataPartnerSelection || !hasOwnDataToProvide)) ||
        organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER,
      [
        canSelectDataPartner,
        hasSkippedDataPartnerSelection,
        hasOwnDataToProvide,
        organizationRole,
      ]
    );
    const [withAgency, setWithAgency] = useState<boolean>(false);
    const [withObserver, setWithObserver] = useState<boolean>(false);
    const [participantsEmails, setParticipantsEmails] = useState<
      Map<MediaDataRoomUserRole, string[]>
    >(DEFAULT_PARTICIPANTS_EMAILS);
    // Handle main participant and main advertiser - to use their emails for showing Org data in the published Media DCR
    const [mainPublisherUserEmail, setMainPublisherUserEmail] =
      useState<string>("");
    const [mainAdvertiserUserEmail, setMainAdvertiserUserEmail] =
      useState<string>("");
    const [showAbsoluteAudienceSizes, setShowAbsoluteAudienceSizes] =
      useState(true);
    const [
      enableAdvertiserAudienceDownload,
      setEnableAdvertiserAudienceDownload,
    ] = useState(false);
    const addParticipantEmail = useCallback(
      (role: MediaDataRoomUserRole, email: string) => {
        const participantsEmailsClone = new Map(participantsEmails);
        const existingParticipants: string[] =
          participantsEmailsClone.get(role) || [];
        const newParticipantEmailsList = participantsEmailsClone.set(role, [
          ...existingParticipants,
          email,
        ]);
        setParticipantsEmails(newParticipantEmailsList);
      },
      [participantsEmails]
    );
    const removeParticipantEmail = useCallback(
      (role: MediaDataRoomUserRole, email: string) => {
        const participantsEmailsClone = new Map(participantsEmails);
        const existingParticipants: string[] =
          participantsEmailsClone.get(role) || [];
        const newParticipantEmailsList = participantsEmailsClone.set(
          role,
          existingParticipants.filter(
            (existingEmail) => existingEmail !== email
          )
        );
        setParticipantsEmails(newParticipantEmailsList);
      },
      [participantsEmails]
    );
    // Set list of available DCR features transformed to enum strings instead of booleans
    const [enabledFeatures, setEnabledFeatures] = useState<
      CollaborationTypes[]
    >([]);
    const mapCollaborationTypes = useCallback(
      ({
        allowInsights,
        allowRemarketing,
        allowLookalike,
        allowExclusionTargeting,
        allowRuleBasedAudiences,
      }: {
        allowInsights: boolean | undefined;
        allowRemarketing: boolean | undefined;
        allowLookalike: boolean | undefined;
        allowExclusionTargeting: boolean | undefined;
        allowRuleBasedAudiences: boolean | undefined;
      }) => [
        ...(allowInsights ? [CollaborationTypes.Insights] : []),
        ...(allowRemarketing ? [CollaborationTypes.Remarketing] : []),
        ...(allowLookalike ? [CollaborationTypes.Lookalike] : []),
        ...(allowExclusionTargeting
          ? [CollaborationTypes.ExclusionTargeting]
          : []),
        ...(allowRuleBasedAudiences
          ? [CollaborationTypes.RuleBasedAudiences]
          : []),
      ],
      []
    );
    const allowedCollaborationTypes = useMemo(() => {
      if (!hasSelectedDataPartner && !hasSelectedPublisher) {
        return mediaDataRoomCollaborationTypes.filter(filterCollaborationTypes);
      }
      if (hasSelectedPublisher && !hasSelectedDataPartner) {
        return mapCollaborationTypes({
          allowExclusionTargeting: selectedPublisher?.allowExclusionTargeting,
          allowInsights: selectedPublisher?.allowInsights,
          allowLookalike: selectedPublisher?.allowLookalike,
          allowRemarketing: selectedPublisher?.allowRetargeting,
          allowRuleBasedAudiences: selectedPublisher?.allowRuleBasedAudiences,
        }).filter(filterCollaborationTypes);
      }
      if (hasSkippedPublisherSelection && hasSelectedDataPartner) {
        return mapCollaborationTypes({
          allowExclusionTargeting: selectedDataPartner?.allowExclusionTargeting,
          allowInsights: selectedDataPartner?.allowInsights,
          allowLookalike: selectedDataPartner?.allowLookalike,
          allowRemarketing: selectedDataPartner?.allowRetargeting,
          allowRuleBasedAudiences: selectedDataPartner?.allowRuleBasedAudiences,
        }).filter(filterCollaborationTypes);
      }
      return mapCollaborationTypes({
        allowExclusionTargeting:
          selectedPublisher?.allowExclusionTargeting &&
          selectedDataPartner?.allowExclusionTargeting,
        allowInsights:
          selectedPublisher?.allowInsights &&
          selectedDataPartner?.allowInsights,
        allowLookalike:
          selectedPublisher?.allowLookalike &&
          selectedDataPartner?.allowLookalike,
        allowRemarketing:
          selectedPublisher?.allowRetargeting &&
          selectedDataPartner?.allowRetargeting,
        allowRuleBasedAudiences:
          selectedPublisher?.allowRuleBasedAudiences &&
          selectedDataPartner?.allowRuleBasedAudiences,
      }).filter(filterCollaborationTypes);
    }, [
      hasSelectedDataPartner,
      hasSelectedPublisher,
      hasSkippedPublisherSelection,
      mapCollaborationTypes,
      selectedPublisher?.allowExclusionTargeting,
      selectedPublisher?.allowInsights,
      selectedPublisher?.allowLookalike,
      selectedPublisher?.allowRetargeting,
      selectedPublisher?.allowRuleBasedAudiences,
      selectedDataPartner?.allowExclusionTargeting,
      selectedDataPartner?.allowInsights,
      selectedDataPartner?.allowLookalike,
      selectedDataPartner?.allowRetargeting,
      selectedDataPartner?.allowRuleBasedAudiences,
      filterCollaborationTypes,
    ]);
    const [matchingIdFormat, setMatchingIdFormat] =
      useState<MatchingColumnFormat | null>(null);
    const [matchingIdHashingAlgorithm, setMatchingIdHashingAlgorithm] =
      useState<TableColumnHashingAlgorithm | null>(null);
    const [requestForCollaborationMessage, setRequestForCollaborationMessage] =
      useState("");
    const collaborationRequestRecipientId = useMemo(() => {
      if (
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
      ) {
        return selectedPublisher?.requestRecipientId;
      }
      if (
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER
      ) {
        return selectedDataPartner?.requestRecipientId;
      }
      return undefined;
    }, [
      activeStep,
      selectedPublisher?.requestRecipientId,
      selectedDataPartner?.requestRecipientId,
    ]);
    const contactButtonEnabled = useMemo(
      () =>
        (activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
          hasSelectedPublisher) ||
        (activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER &&
          hasSelectedDataPartner),
      [hasSelectedDataPartner, hasSelectedPublisher, activeStep]
    );
    const hasMatchingIdSelected = Boolean(matchingIdFormat);
    const arePublisherAndDataPartnerMatchingIdMatched =
      hasSelectedDataPartner &&
      hasSelectedPublisher &&
      selectedDataPartner?.matchingIdFormat ===
        selectedPublisher?.matchingIdFormat &&
      selectedDataPartner?.matchingIdHashingAlgorithm ===
        selectedPublisher?.matchingIdHashingAlgorithm;
    const hasAllowedCollaborationTypes = allowedCollaborationTypes.length > 0;
    const canProceedToConfiguration = useMemo<boolean>(() => {
      const hasSelectedPublisherOrSkipped =
        (hasSelectedPublisher && hasMatchingIdSelected) ||
        hasSkippedPublisherSelection;
      if (!canSelectDataPartner) {
        return hasSelectedPublisherOrSkipped;
      }
      const hasSelectedDataPartnerOrSkipped =
        hasSelectedDataPartner || hasSkippedDataPartnerSelection;
      return (
        hasSelectedPublisherOrSkipped &&
        (activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER ||
          (hasSelectedDataPartnerOrSkipped &&
            (hasSkippedPublisherSelection ||
              hasSkippedDataPartnerSelection ||
              arePublisherAndDataPartnerMatchingIdMatched) &&
            hasAllowedCollaborationTypes))
      );
    }, [
      hasSelectedPublisher,
      hasMatchingIdSelected,
      canSelectDataPartner,
      hasSkippedPublisherSelection,
      hasSkippedDataPartnerSelection,
      activeStep,
      hasSelectedDataPartner,
      arePublisherAndDataPartnerMatchingIdMatched,
      hasAllowedCollaborationTypes,
    ]);
    const contactButtonTooltip = useMemo(
      () =>
        canProceedToConfiguration
          ? null
          : `To continue, please contact the ${
              activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER
                ? "publisher"
                : "data partner"
            } to create a DCR configuration for you.`,
      [canProceedToConfiguration, activeStep]
    );
    const getValues = useCallback<
      (formValues: SchemaType) => PublishMediaDataRoomInput
    >(
      (formValues) => {
        const [matchingIdFormat, matchingIdHashingAlgorithm] =
          matchingIdTypeToGqlValues[formValues.matchingId as RawMatchingID];
        return {
          advertiserEmails: formValues.advertiserEmails || [],
          agencyEmails: formValues.agencyEmails || [],
          dataPartnerEmails: formValues.dataPartnerEmails || [],
          enableAdvertiserAudienceDownload:
            formValues.enableAdvertiserAudienceDownload,
          enableDataPartner: (formValues.dataPartnerEmails || []).length > 0,
          enableExclusionTargeting:
            formValues.enabledFeatures.includes(
              CollaborationTypes.ExclusionTargeting
            ) || false,
          enableInsights:
            formValues.enabledFeatures.includes(CollaborationTypes.Insights) ||
            false,
          enableLookalike:
            formValues.enabledFeatures.includes(CollaborationTypes.Lookalike) ||
            false,
          enableRemarketing:
            formValues.enabledFeatures.includes(
              CollaborationTypes.Remarketing
            ) || false,
          enableRetargeting:
            formValues.enabledFeatures.includes(
              CollaborationTypes.Remarketing
            ) || false,
          enableRuleBasedAudiences:
            formValues.enabledFeatures.includes(
              CollaborationTypes.RuleBasedAudiences
            ) || false,
          hideAbsoluteValuesFromInsights: !formValues.showAbsoluteAudienceSizes,
          mainAdvertiserEmail: mainAdvertiserUserEmail,
          mainPublisherEmail: mainPublisherUserEmail,
          matchingIdFormat: matchingIdFormat,
          matchingIdHashingAlgorithm: matchingIdHashingAlgorithm,
          name: formValues.name,
          observerEmails: formValues.observerEmails || [],
          publisherEmails: formValues.publisherEmails || [],
        };
      },
      [mainAdvertiserUserEmail, mainPublisherUserEmail]
    );
    const handleSendCollaborationRequest = useCallback(async () => {
      await sendCollaborationRequest({
        message: requestForCollaborationMessage,
        receiver:
          activeStep ===
          MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
            ? "publisher"
            : "dataPartner",
        requestRecipientId: collaborationRequestRecipientId!,
      });
    }, [
      collaborationRequestRecipientId,
      requestForCollaborationMessage,
      sendCollaborationRequest,
      activeStep,
    ]);
    const handleSubmit = useCallback(
      (formValues: SchemaType) => submit(getValues(formValues)),
      [getValues, submit]
    );
    useEffect(() => {
      setParticipantsEmails((participantsEmails) => {
        if (organizationRole === null) {
          return DEFAULT_PARTICIPANTS_EMAILS;
        }
        const participantsEmailsClone = new Map(participantsEmails);
        if (organizationRole === MediaDataRoomOrganizationRole.ADVERTISER) {
          participantsEmailsClone.set(
            MediaDataRoomUserRole.Publisher,
            selectedPublisher?.publisherParticipants || []
          );
          participantsEmailsClone.set(
            MediaDataRoomUserRole.DataPartner,
            selectedDataPartner?.participants || []
          );
          if (currentUserEmail) {
            participantsEmailsClone.set(MediaDataRoomUserRole.Advertiser, [
              currentUserEmail,
            ]);
          }
        }
        if (organizationRole === MediaDataRoomOrganizationRole.PUBLISHER) {
          if (currentUserEmail) {
            participantsEmailsClone.set(MediaDataRoomUserRole.Publisher, [
              currentUserEmail,
            ]);
          }
        }
        if (organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER) {
          if (currentUserEmail) {
            participantsEmailsClone.set(MediaDataRoomUserRole.DataPartner, [
              currentUserEmail,
            ]);
          }
        }
        participantsEmailsClone.set(MediaDataRoomUserRole.Observer, []);
        participantsEmailsClone.set(MediaDataRoomUserRole.Agency, []);
        return participantsEmailsClone;
      });
    }, [
      selectedPublisher?.publisherParticipants,
      selectedDataPartner?.participants,
      currentUserEmail,
      organizationRole,
    ]);
    useUpdateEffect(() => {
      setEnabledFeatures([]);
      setMainPublisherUserEmail("");
      setDataRoomName("");
      setWithAgency(false);
      setWithObserver(false);
      setMatchingIdFormat(selectedPublisher?.matchingIdFormat || null);
      setMatchingIdHashingAlgorithm(
        selectedPublisher?.matchingIdHashingAlgorithm || null
      );
    }, [selectedPublisher?.id]);
    const shouldResetCollaborationMessage = [
      MediaDataRoomCreationStep.SELECT_DATA_PARTNER,
      MediaDataRoomCreationStep.SELECT_PUBLISHER,
    ].includes(activeStep);
    useEffect(() => {
      if (shouldResetCollaborationMessage) {
        setRequestForCollaborationMessage("");
      }
    }, [shouldResetCollaborationMessage, setRequestForCollaborationMessage]);
    useEffect(() => {
      setEnabledFeatures((currentlyEnabled) => {
        const currentlyAllowedFeatures = currentlyEnabled.filter((feature) =>
          allowedCollaborationTypes.includes(feature)
        );
        if (currentlyAllowedFeatures.length !== currentlyEnabled.length) {
          return currentlyAllowedFeatures;
        }
        return currentlyEnabled;
      });
    }, [allowedCollaborationTypes, setEnabledFeatures]);
    const [participantsEmailsPublisherFirst] =
      participantsEmails.get(MediaDataRoomUserRole.Publisher) || [];
    useEffect(() => {
      setMainPublisherUserEmail(participantsEmailsPublisherFirst);
    }, [participantsEmailsPublisherFirst]);
    const [participantsEmailsAdvertiserFirst] =
      participantsEmails.get(MediaDataRoomUserRole.Advertiser) || [];
    useEffect(() => {
      setMainAdvertiserUserEmail(participantsEmailsAdvertiserFirst);
    }, [participantsEmailsAdvertiserFirst]);
    const contextValue: CreationWizardConfigurationContextValue = useMemo(
      () => ({
        addParticipantEmail,
        allowedCollaborationTypes,
        canProceedToConfiguration,
        collaborationRequestRecipientId,
        contactButtonEnabled,
        contactButtonTooltip,
        dataRoomName,
        enableAdvertiserAudienceDownload,
        enabledFeatures,
        getValues,
        handleSendCollaborationRequest,
        handleSubmit,
        hasSelectedDataPartner,
        hasSelectedPublisher,
        mainAdvertiserUserEmail,
        mainPublisherUserEmail,
        matchingIdFormat,
        matchingIdHashingAlgorithm,
        participantsEmails,
        removeParticipantEmail,
        requestForCollaborationMessage,
        setDataRoomName,
        setEnableAdvertiserAudienceDownload,
        setEnabledFeatures,
        setMainAdvertiserUserEmail,
        setMainPublisherUserEmail,
        setMatchingIdFormat,
        setMatchingIdHashingAlgorithm,
        setParticipantsEmails,
        setRequestForCollaborationMessage,
        setShowAbsoluteAudienceSizes,
        setWithAgency,
        setWithObserver,
        showAbsoluteAudienceSizes,
        withAgency,
        withDataPartner,
        withObserver,
      }),
      [
        addParticipantEmail,
        allowedCollaborationTypes,
        canProceedToConfiguration,
        collaborationRequestRecipientId,
        contactButtonEnabled,
        contactButtonTooltip,
        dataRoomName,
        enableAdvertiserAudienceDownload,
        enabledFeatures,
        getValues,
        handleSendCollaborationRequest,
        handleSubmit,
        hasSelectedDataPartner,
        hasSelectedPublisher,
        mainAdvertiserUserEmail,
        mainPublisherUserEmail,
        matchingIdFormat,
        matchingIdHashingAlgorithm,
        participantsEmails,
        removeParticipantEmail,
        requestForCollaborationMessage,
        setDataRoomName,
        setEnableAdvertiserAudienceDownload,
        setMainAdvertiserUserEmail,
        setMainPublisherUserEmail,
        setMatchingIdFormat,
        setMatchingIdHashingAlgorithm,
        setParticipantsEmails,
        setRequestForCollaborationMessage,
        setShowAbsoluteAudienceSizes,
        setWithAgency,
        setWithObserver,
        showAbsoluteAudienceSizes,
        withAgency,
        withDataPartner,
        withObserver,
      ]
    );
    return (
      <CreationWizardConfigurationContextProvider value={contextValue}>
        {children}
      </CreationWizardConfigurationContextProvider>
    );
  }
);
ConfigurationWrapper.displayName = "ConfigurationWrapper";

export default ConfigurationWrapper;
