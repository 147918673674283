import { DqCard, DqCardContent } from "@decentriq/components";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, styled, type Theme, Tooltip, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { memo } from "react";

const StyledImg = styled("img")(({ theme }) => ({
  maxHeight: "70px",
  maxWidth: "100%",
  padding: theme.spacing(1),
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  maxHeight: "70px",
  maxWidth: "100%",
  padding: theme.spacing(1),
}));

interface OrganizationTileProps {
  name: string;
  marketIds: string[];
  logo: string | null | undefined;
  imageSx?: SxProps<Theme>;
  selected?: boolean;
  onSelect?: () => void;
}

const OrganizationTile: React.FC<OrganizationTileProps> = ({
  name,
  logo,
  marketIds,
  imageSx,
  selected,
  onSelect,
}) => (
  <DqCard
    selectable={
      onSelect
        ? {
            checked: selected,
            component: "radio",
            onChange: onSelect,
            value: name,
          }
        : undefined
    }
  >
    <DqCardContent>
      {logo ? (
        <StyledImg
          alt="publisher logo"
          loading="lazy"
          src={`data:image;base64,${logo}`}
          sx={imageSx}
        />
      ) : (
        <StyledIcon height="43px" icon={faNewspaper} size="3x" sx={imageSx} />
      )}
      <Tooltip title={marketIds.join(", ")}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mb: 1,
            mt: 1,
          }}
        >
          {marketIds
            .map((id) => (
              <Box key={id} sx={{ mb: 0.5, mr: 0.5, mt: 0 }}>
                <Chip key={id}>{id}</Chip>
              </Box>
            ))
            .slice(0, 3)}
          {marketIds.length > 3 ? (
            <Box sx={{ mb: 0.5, mr: 0.5, mt: 0 }}>
              <Chip>{`+${marketIds.length - 3}`}</Chip>
            </Box>
          ) : null}
        </Box>
      </Tooltip>
      <Tooltip title={name}>
        <Typography
          fontWeight={500}
          level="body-md"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
          textAlign="center"
        >
          {name}
        </Typography>
      </Tooltip>
    </DqCardContent>
  </DqCard>
);

OrganizationTile.displayName = "OrganizationTile";

export default memo(OrganizationTile);
