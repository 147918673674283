import { DqCard, DqCardContent } from "@decentriq/components";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBriefcase as fatBriefcase } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AspectRatio, Box, Chip, Stack, Tooltip, Typography } from "@mui/joy";
import { type PropsWithChildren } from "react";

const MAX_MARKET_IDS = 3;

interface OrganizationSheetProps extends PropsWithChildren {
  logo?: string | null | undefined;
  icon?: IconProp | undefined;
  name: string;
  ids?: string[] | undefined;
  selected?: boolean;
  onSelect?: () => void;
}

const OrganizationSheet: React.FC<OrganizationSheetProps> = ({
  children,
  logo,
  icon = fatBriefcase,
  name,
  ids = [],
  onSelect,
  selected,
}) => {
  return (
    <DqCard
      selectable={
        onSelect
          ? {
              checked: selected,
              component: "radio",
              onChange: onSelect,
              value: name,
            }
          : undefined
      }
    >
      <DqCardContent alignItems="flex-end">
        {children}
        <Stack alignItems="center" p={2} width="100%">
          {logo ? (
            <AspectRatio
              maxHeight={48}
              objectFit="contain"
              ratio={1}
              sx={{ height: "3em", width: "100%" }}
              variant="plain"
            >
              <img
                alt={`${name} logo`}
                loading="lazy"
                src={
                  logo.startsWith("data:image") || logo.startsWith("http")
                    ? logo
                    : `data:image;base64,${logo}`
                }
              />
            </AspectRatio>
          ) : (
            <FontAwesomeIcon icon={icon} size="3x" />
          )}
          <Box width="100%">
            <Tooltip title={name}>
              <Typography level="title-md" noWrap={true} textAlign="center">
                {name}
              </Typography>
            </Tooltip>
          </Box>
          <Stack direction="row">
            {ids.slice(0, MAX_MARKET_IDS).map((id) => (
              <Chip key={id} size="sm">
                {id}
              </Chip>
            ))}
            {ids.length > MAX_MARKET_IDS ? (
              <Tooltip title={ids.slice(MAX_MARKET_IDS).join(", ")}>
                <Chip size="sm">{`+${ids.length - MAX_MARKET_IDS}`}</Chip>
              </Tooltip>
            ) : null}
          </Stack>
        </Stack>
      </DqCardContent>
    </DqCard>
  );
};

export default OrganizationSheet;
