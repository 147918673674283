import { DqCard, DqCardContent } from "@decentriq/components";
import {
  faFileImport as fatFileImport,
  faFileLines as fatFileLines,
} from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ModalDialog,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import { forwardRef, memo } from "react";
import { useCreationWizardStepper } from "../../contexts";

const SelectDataSourceRoleStep = memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const {
      hasOwnDataToProvide,
      setHasOwnDataToProvide,
      handleBackStep,
      handleNextStep,
    } = useCreationWizardStepper();
    const options = [
      {
        icon: fatFileLines,
        title: "Bring my own data",
        value: true,
      },
      {
        icon: fatFileImport,
        title: "From data partner",
        value: false,
      },
    ];
    return (
      <ModalDialog ref={ref}>
        <DialogTitle>Create new media clean room</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack>
            <Typography level="title-md">Data source</Typography>
            <Typography level="body-sm">Select the data source</Typography>
            <RadioGroup
              name="dataSourceType"
              onChange={(event) =>
                setHasOwnDataToProvide(Boolean(Number(event.target.value)))
              }
              value={Number(hasOwnDataToProvide).toString()}
            >
              <Grid columnSpacing={1} container={true} xs={12}>
                {options.map(({ icon, title, value }) => (
                  <Grid key={title} xs={6}>
                    <DqCard
                      selectable={{
                        component: "radio",
                        value: Number(value).toString(),
                      }}
                    >
                      <DqCardContent alignItems="center" gap={1}>
                        <FontAwesomeIcon icon={icon} size="3x" />
                        <Typography level="title-md" textAlign="center">
                          {title}
                        </Typography>
                      </DqCardContent>
                    </DqCard>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleBackStep}>Back</Button>
          <Button color="primary" onClick={handleNextStep} variant="solid">
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  })
);
SelectDataSourceRoleStep.displayName = "SelectDataSourceRoleStep";

export default SelectDataSourceRoleStep;
