import { memo } from "react";
import { PublisherPortalOverviewTabs } from "features/admin";

const PublisherPortalOverviewPage = memo(() => {
  return <PublisherPortalOverviewTabs />;
});

PublisherPortalOverviewPage.displayName = "PublisherPortalOverviewPage";

export default PublisherPortalOverviewPage;
