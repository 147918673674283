import { DqPortal as Portal } from "@decentriq/components";
import {
  faKeySkeletonLeftRight,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { useBoolean } from "ahooks";
import { Actions } from "components";
import ChangeKeychainPasswordDialog from "../ChangeKeychainPasswordDialog/ChangeKeychainPasswordDialog";
import EmptyKeychainDialog from "../EmptyKeychainDialog/EmptyKeychainDialog";

export enum KeychainActionTypes {
  ChangePassword,
  Empty,
}

const KeychainActions: React.FC = () => {
  const [
    isChangePasswordDialogOpen,
    { setTrue: openChangePasswordDialog, setFalse: closeChangePasswordDialog },
  ] = useBoolean(false);
  const [
    isEmptyKeychainDialogOpen,
    { setTrue: openEmptyKeychainDialog, setFalse: closeEmptyKeychainDialog },
  ] = useBoolean(false);
  const actions = {
    menuLists: [
      [
        {
          icon: faKeySkeletonLeftRight,
          name: "Change Keychain password",
          onClick: openChangePasswordDialog,
          tooltipTitle: "Change Keychain password",
          type: KeychainActionTypes.ChangePassword,
        },
        {
          icon: faTrashCan,
          isRed: true,
          name: "Empty Keychain",
          onClick: openEmptyKeychainDialog,
          tooltipTitle: "Empty Keychain",
          type: KeychainActionTypes.Empty,
        },
      ],
    ],
  };
  return (
    <Actions actions={actions}>
      <Portal>
        <EmptyKeychainDialog
          onCancel={closeEmptyKeychainDialog}
          open={isEmptyKeychainDialogOpen}
        />
        <ChangeKeychainPasswordDialog
          onCancel={closeChangePasswordDialog}
          open={isChangePasswordDialogOpen}
        />
      </Portal>
    </Actions>
  );
};

export default KeychainActions;
