import { faPersonDigging as fatPersonDigging } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Typography } from "@mui/joy";

const UnderMaintenancePage = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <FontAwesomeIcon
        fixedWidth={true}
        icon={fatPersonDigging}
        style={{
          height: "5em",
          marginBottom: "0.5em",
          width: "5em",
        }}
      />
      <Typography level="title-md">
        <strong>We are improving our platform</strong>
      </Typography>
      <Typography
        sx={{
          display: "block",
          marginBlockEnd: "0.7em",
          marginBlockStart: "0.3em",
          marginInlineEnd: "0px",
          marginInlineStart: "0px",
          maxWidth: "700px",
          textAlign: "center",
        }}
      >
        Apologies for the inconvenience, we are doing some maintenance work and
        should be back soon.
      </Typography>
      <Button
        color="primary"
        component="a"
        href="mailto:support@decentriq.com"
        variant="solid"
      >
        CONTACT US
      </Button>
    </Container>
  );
};

export default UnderMaintenancePage;
