import { InfoTooltip } from "@decentriq/components";
import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import {
  Alert,
  Checkbox,
  FormControl,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Fragment, memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";
import { WORKER_TYPE_LABEL } from "features/computeNode/components/ComputeNodeType/useComputeNodeType";

const workerTypeTooltip: Partial<Record<WorkerTypeShortName, string>> = {
  [WorkerTypeShortName.Sql]:
    "Only use for demos. This is our handrolled SQL execution engine. The only reason to use this, is because we have the “minimum aggregation” feature (”k-anonymity”) in there.",
};

const AdvancedAnalyticsDcrFeatures = memo(() => {
  const {
    enclaveWorkers,
    isEnclaveWorkersEditorDisabled,
    hasAnalyticsFeatures,
    isEditorDisabled,
    updateOrganizationFlags,
    updateOrganizationEnclaveWorkers,
    availableEnclaveWorkers,
  } = useOrganizationAdminConfiguration();
  return (
    <Stack>
      <Typography level="title-md">Advanced Analytics DCR</Typography>
      <FormControl>
        <Tooltip title="This allows users in this organisation to create Advanced Analytics DCRs (if their license allows).">
          <Checkbox
            checked={hasAnalyticsFeatures}
            disabled={isEditorDisabled}
            label="Enable Advanced Analytics DCR"
            name="hasAnalyticsFeatures"
            onChange={updateOrganizationFlags}
          />
        </Tooltip>
        {availableEnclaveWorkers.length === 0 && hasAnalyticsFeatures && (
          <Alert color="warning">
            At least one computation type should be selected.
          </Alert>
        )}
      </FormControl>
      <Typography>
        Available computation types
        <InfoTooltip
          tooltip={
            <Fragment>
              This controls which computation types are available in Advanced
              Analytics DCRs to users of this organisation. Note that Python
              computation type must be enabled when the org should be able to
              create MDCR/Measurement DCRs (aka any of the org roles under Media
              DCR or <strong>Enable Measurement DCR</strong> are enabled). Note
              that at least one computation type should selected to avoid
              situations where <strong>Enable Advanced Analytics DCR</strong> is
              true but no computation types are available.
            </Fragment>
          }
          top="0"
        />
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ "& > *": { flex: "0 1 calc(25% - 8px * 3 / 4)" } }}
      >
        {enclaveWorkers.map(({ id, shortName }) => (
          <FormControl key={id}>
            <Tooltip title={workerTypeTooltip[shortName]}>
              <Checkbox
                checked={availableEnclaveWorkers.some(
                  (worker) => worker.id === id
                )}
                disabled={isEnclaveWorkersEditorDisabled}
                label={WORKER_TYPE_LABEL[shortName]}
                onChange={updateOrganizationEnclaveWorkers}
                value={id}
              />
            </Tooltip>
          </FormControl>
        ))}
      </Stack>
    </Stack>
  );
});

AdvancedAnalyticsDcrFeatures.displayName = "AdvancedAnalyticsDcrFeatures";

export default AdvancedAnalyticsDcrFeatures;
