import { DqCard, DqCardContent } from "@decentriq/components";
import { Alert, Chip, Grid, Typography } from "@mui/joy";
import { memo } from "react";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr";
import { useLookalikeAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/containers/LookalikeAdvertiserAudienceGenerator";
import AudienceQualityStatisticsDrawer from "../../AudienceQualityStatisticsDrawer/AudienceQualityStatisticsDrawer";
import PositiveCurveScoreBar from "./PositiveCurveScoreBar";
import QualityScore from "./QualityScore";

const AudienceQualityStatistics: React.FC = () => {
  const { estimatedAudienceSize, selectedLookalikeAudience } =
    useLookalikeAdvertiserAudienceGenerator();
  const {
    supportedFeatures: {
      enableExtendedLalQualityStats: enableDcrExtendedLalQualityStats = false,
    },
  } = usePublishedMediaInsightsDcr();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const { test_set_size, test_set_positive_examples_size } =
    selectedLookalikeAudience?.quality?.roc_curve || {};

  return (
    <>
      {enableDcrExtendedLalQualityStats && (
        <Typography level="body-sm" mb={1} mt={4}>
          Audience preview reflecting the applied filters
          <Chip color="warning" sx={{ ml: 1 }}>
            Beta
          </Chip>
        </Typography>
      )}
      <Grid
        columnSpacing={enableDcrExtendedLalQualityStats ? 2 : 0}
        container={true}
        justifyContent={
          enableDcrExtendedLalQualityStats ? "flex-start" : "flex-end"
        }
        mt={1}
        xs={12}
      >
        <Grid xs={enableDcrExtendedLalQualityStats ? 2 : 5}>
          <DqCard>
            <DqCardContent>
              <Typography fontWeight={500} level="body-sm">
                Estimated audience size
              </Typography>
              <Typography fontSize={36} fontWeight={600}>
                {formatter.format(estimatedAudienceSize)}
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
        {enableDcrExtendedLalQualityStats && (
          <>
            <Grid xs={2}>
              <DqCard>
                <DqCardContent>
                  <QualityScore />
                </DqCardContent>
              </DqCard>
            </Grid>
            <Grid container={true} mt={0} xs={8}>
              <DqCard>
                <PositiveCurveScoreBar />
              </DqCard>
            </Grid>
          </>
        )}
      </Grid>
      {(test_set_positive_examples_size || test_set_size) < 50 && (
        <Alert color="warning" sx={{ mt: 1 }}>
          There were too few individuals in the overlapping audience to create a
          large holdout group for testing. The quality metric was evaluated with
          only {test_set_positive_examples_size || test_set_size} individuals
          {test_set_positive_examples_size ? "in the seed audience" : ""}, which
          may result in a less accurate assessment of model quality
        </Alert>
      )}
      {enableDcrExtendedLalQualityStats && <AudienceQualityStatisticsDrawer />}
    </>
  );
};

AudienceQualityStatistics.displayName = "AudienceQualityStatistics";

export default memo(AudienceQualityStatistics);
