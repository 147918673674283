import { DqLoader, DqTable } from "@decentriq/components";
import { usePublisherPortalOverviewQuery } from "@decentriq/graphql/dist/hooks";
import { type PublisherPortalOverviewQuery } from "@decentriq/graphql/dist/types";
import { Chip, Stack, Tooltip, Typography } from "@mui/joy";
import { type MRT_ColumnDef, type MRT_Row } from "material-react-table";
import { memo, useMemo } from "react";

type PublisherPortalOverviewListItem =
  PublisherPortalOverviewQuery["organizations"]["nodes"][number];

const PublisherDisplayConfigurationTable = memo(() => {
  const { data, loading } = usePublisherPortalOverviewQuery();

  const publisherDisplayConfigurations = useMemo(() => {
    return (
      data?.organizations?.nodes.filter(
        (organization) => !!organization?.publisherDisplayConfiguration
      ) ?? ([] as PublisherPortalOverviewListItem[])
    );
  }, [data]);

  const publisherDisplayConfigurationsColumns: MRT_ColumnDef<PublisherPortalOverviewListItem>[] =
    [
      {
        accessorKey: "name",
        header: "Organization Name",
      },
      {
        accessorKey: "mediaScope",
        header: "Media Scope",
      },
      {
        accessorKey: "publisherDisplayConfiguration.publisherName",
        header: "Publisher Display Configuration",
      },
      {
        Cell: ({ cell }) => {
          const marketIds =
            cell.row.original.publisherDisplayConfiguration?.marketIds;
          const { hasMore, effectiveMarketIds } = useMemo(() => {
            if (marketIds?.length && marketIds.length > 3) {
              return {
                effectiveMarketIds: marketIds.slice(0, 3),
                hasMore: true,
              };
            }
            return { effectiveMarketIds: marketIds, hasMore: false };
          }, [marketIds]);
          return (
            <Tooltip title={marketIds?.join(", ")}>
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={1}
                useFlexGap={true}
              >
                {effectiveMarketIds?.map((marketId) => (
                  <Chip key={marketId}>{marketId}</Chip>
                ))}
                {hasMore && (
                  <Chip color="warning">+{marketIds?.length ?? 0 - 5}</Chip>
                )}
              </Stack>
            </Tooltip>
          );
        },
        header: "Market IDs",
      },
      {
        Cell: ({ cell }) => {
          const users =
            cell.row.original.publisherDisplayConfiguration
              ?.collaborationRequestUsers;
          return (
            <Stack
              direction="row"
              flexWrap="wrap"
              spacing={1}
              useFlexGap={true}
            >
              {users?.map((user) => <Chip key={user}>{user}</Chip>)}
            </Stack>
          );
        },
        header: "Collaboration Request Users",
        size: 250,
      },
      {
        Cell: ({ cell }) => {
          return (
            <Tooltip
              title={
                cell.row.original.publisherDisplayConfiguration?.description
              }
            >
              <Typography
                level="body-sm"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {cell.row.original.publisherDisplayConfiguration?.description}
              </Typography>
            </Tooltip>
          );
        },
        header: "Description",
      },
    ];

  const renderPublisherDisplayConfigurationExpandedRow = ({
    row,
  }: {
    row: MRT_Row<PublisherPortalOverviewListItem>;
  }) => {
    const activationConfigurations =
      row.original.activationConfigurations.nodes ?? [];

    const columns: MRT_ColumnDef<
      PublisherPortalOverviewListItem["activationConfigurations"]["nodes"][number]
    >[] = [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorFn: (row) =>
          `${row.matchingIdFormat} - ${row.matchingIdHashingAlgorithm}`,
        header: "Matching ID",
      },
      {
        accessorFn: (row) => (row.matchAnyDomain ? "Yes" : "No"),
        accessorKey: "matchAnyDomain",
        header: "Match Any Domain",
        size: 150,
      },
      {
        accessorFn: (row) => (row.allowInsights ? "Yes" : "No"),
        accessorKey: "allowInsights",
        header: "Allow Insights",
        size: 150,
      },
      {
        accessorFn: (row) => (row.allowLookalike ? "Yes" : "No"),
        accessorKey: "allowLookalike",
        header: "Allow Lookalike",
        size: 150,
      },
      {
        accessorFn: (row) => (row.allowRetargeting ? "Yes" : "No"),
        accessorKey: "allowRetargeting",
        header: "Allow Retargeting",
        size: 150,
      },
      {
        accessorFn: (row) => (row.allowMeasurements ? "Yes" : "No"),
        accessorKey: "allowMeasurements",
        header: "Allow Measurements",
        size: 150,
      },
      {
        accessorFn: (row) => (row.allowExclusionTargeting ? "Yes" : "No"),
        accessorKey: "allowExclusionTargeting",
        header: "Allow Exclusion Targeting",
        size: 150,
      },
      {
        accessorFn: (row) => (row.allowRuleBasedAudiences ? "Yes" : "No"),
        accessorKey: "allowRuleBasedAudiences",
        header: "Allow Rule Based Audiences",
        size: 150,
      },
      {
        Cell: ({ cell }) => {
          return (
            <Stack
              direction="row"
              flexWrap="wrap"
              spacing={1}
              useFlexGap={true}
            >
              {cell.row.original.publisherParticipants.map((participant) => (
                <Chip key={participant}>{participant}</Chip>
              ))}
            </Stack>
          );
        },
        header: "Publisher Participants",
      },
      {
        Cell: ({ cell }) => {
          return (
            <Stack
              direction="row"
              flexWrap="wrap"
              spacing={1}
              useFlexGap={true}
            >
              {cell.row.original.advertiserDomains.map((domain) => (
                <Chip key={domain}>{domain}</Chip>
              ))}
            </Stack>
          );
        },
        header: "Advertiser Domains",
      },
    ];

    if (activationConfigurations.length === 0) {
      return null;
    }

    return (
      <Stack width="100%">
        <Typography level="title-sm">Activation Configurations</Typography>
        <DqTable
          columns={columns}
          data={activationConfigurations}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: "var(--joy-palette-neutral-200)",
            },
          }}
          muiTableProps={{
            sx: {
              backgroundColor: "var(--joy-palette-neutral-100)",
              width: "100%",
            },
          }}
        />
      </Stack>
    );
  };

  if (loading) {
    return (
      <DqLoader
        sx={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          padding: "2.5rem",
          width: "100%",
        }}
      />
    );
  }

  return (
    <Stack>
      <Typography level="title-lg">Publisher Display Configurations</Typography>
      <DqTable
        columns={publisherDisplayConfigurationsColumns}
        data={publisherDisplayConfigurations}
        muiDetailPanelProps={{
          sx: {
            backgroundColor: "var(--joy-palette-neutral-100)",
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            backgroundColor: row.getIsExpanded()
              ? "var(--joy-palette-neutral-100)"
              : "auto",
          },
        })}
        muiTableProps={{
          sx: {
            height: "100%",
          },
        }}
        renderDetailPanel={renderPublisherDisplayConfigurationExpandedRow}
      />
    </Stack>
  );
});

PublisherDisplayConfigurationTable.displayName =
  "PublisherDisplayConfigurationTable";

export default PublisherDisplayConfigurationTable;
