import { joyTheme, materialTheme } from "@decentriq/theme";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { memo, type PropsWithChildren } from "react";

const StylesWrapper = memo<PropsWithChildren>(({ children }) => {
  const styles = {
    ".MuiButton-startDecorator .svg-inline--fa": {
      color: "var(--Icon-color)",
      fontSize: "var(--Icon-fontSize)",
      margin: "var(--Icon-margin)",
    },
    ".svg-inline--fa.fa-fw": { width: "1em" },
    ":focus": { outline: "none" },
  };
  return (
    <StyledEngineProvider injectFirst={true}>
      <GlobalStyles styles={styles} />
      <ThemeProvider theme={materialTheme}>
        <MaterialCssVarsProvider
          theme={{
            [MATERIAL_THEME_ID]:
              // @ts-expect-error Cause Material UI is getting deprecated
              materialExtendTheme(materialTheme),
          }}
        >
          <JoyCssVarsProvider theme={joyTheme}>
            <CssBaseline />
            {children}
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
StylesWrapper.displayName = "StylesWrapper";

export default StylesWrapper;
