import { type AvailablePublisherFragment } from "@decentriq/graphql/dist/types";
import { type ActiveMarket } from "@decentriq/types";
import { createContext, useContext } from "react";

export interface CreationWizardPublisherContextValue {
  advertiserMarketFilters: (ActiveMarket & { selected: boolean })[];
  availablePublishers: AvailablePublisherFragment[];
  hasSelectedPublisher: boolean;
  hasSkippedSelection: boolean;
  invitePublisher: () => void;
  isAdvertiserMarketFiltersLoading: boolean;
  isAvailablePublishersLoading: boolean;
  selectedPublisher: AvailablePublisherFragment | null;
  selectPublisher: (publisher: AvailablePublisherFragment | null) => void;
  updateAdvertiserMarketFilters: (marketIds: string[]) => void;
}

export const CreationWizardPublisherContext =
  createContext<CreationWizardPublisherContextValue | null>(null);

export const CreationWizardPublisherContextProvider =
  CreationWizardPublisherContext.Provider;

export const useCreationWizardPublisher = () => {
  const contextValue = useContext(CreationWizardPublisherContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardPublisher must be used within a CreationWizardPublisherWrapper"
    );
  }
  return contextValue;
};
