import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Stack, Tooltip, Typography } from "@mui/joy";
import { memo } from "react";
import {
  AttributionSelector,
  MatchingIdChip,
  NameInput,
  RolesAndParticipants,
} from "./components";

const DqCreateMeasurementDcrFormControls = memo(() => {
  return (
    <Stack>
      <Typography level="title-md">1. Data clean room configuration</Typography>
      <NameInput />
      <Typography level="title-md">2. Roles and participants</Typography>
      <Alert>
        All participants need to have a Decentriq account. Invite external
        participants via the Admin portal.
      </Alert>
      <RolesAndParticipants />
      <Typography level="title-md">
        3. Measurement clean room configuration
      </Typography>
      <MatchingIdChip />
      <Typography
        endDecorator={
          <Tooltip
            title={
              <span>
                The maximum number days a click or view may be before a
                conversion and be attributed. Clicks and views may be set
                separately, but the view window may not be longer than the click
                window.
                <br />
                For the click-only attribution set the View Lookback Window to
                “None”.
                <br />
                The “Last Click + Viewthrough” Attribution Rule prioritizes
                clicks over views, all other rules treat clicks and views
                equally.
              </span>
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        }
        level="title-md"
      >
        4. Click lookback and attribution configuration
      </Typography>
      <AttributionSelector />
    </Stack>
  );
});
DqCreateMeasurementDcrFormControls.displayName =
  "DqCreateMeasurementDcrFormControls";

export default DqCreateMeasurementDcrFormControls;
