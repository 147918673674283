// @ts-nocheck
import { Box } from "@mui/joy";
import { useMatch } from "react-router-dom";
import {
  DataRoomDescriptionEditor,
  DataRoomNameEditor,
  DataRoomNameEditorEndAdornment,
} from "containers";
import DataRoomBrandingLogo from "containers/DataRoomBrandingLogo/DataRoomBrandingLogo";
import { useDataRoom } from "contexts";
import { DataRoomActions } from "features";
import EnclaveSpecActions from "features/publishedDataRoom/components/PublishedDataRoomActions/EnclaveSpecActions/EnclaveSpecActions";
import TestModeSwitch from "features/publishedDataRoom/components/PublishedDataRoomActions/TestModeSwitch/TestModeSwitch";
import { dataRoomPrefixType } from "models";

const Mainbar = () => {
  const { isPublished } = useDataRoom();
  const { params } =
    useMatch({
      end: false,
      path: "/datarooms/:type/:dataRoomId",
    }) || {};
  const { type, dataRoomId } = params || {};
  const __typename = dataRoomPrefixType[type];
  const actions = ({ buttons, menuLists }) => ({
    buttons,
    menuLists,
  });
  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: "minmax(0, 1fr) auto",
          justifyContent: "space-between",
          minHeight: "32px !important",
          mx: 1.25,
          my: 2,
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexDirection: "row",
            maxWidth: "100%",
            mr: 0.5,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              "&:not(:first-child)": { ml: 0.5 },
              "&:not(:last-child)": { mr: 0.5 },
              alignItems: "center",
              display: "flex",
              lineHeight: "24px",
              maxWidth: "100%",
              p: 0.5,
            }}
          >
            <DataRoomNameEditor
              dataRoomId={dataRoomId}
              endAdornment={
                <DataRoomNameEditorEndAdornment
                  __typename={__typename}
                  id={dataRoomId}
                />
              }
            />
          </Box>
          {isPublished && <DataRoomBrandingLogo id={dataRoomId} />}
        </Box>
        <Box>
          <Box sx={{ display: "flex" }}>
            {isPublished && <TestModeSwitch />}
            {isPublished && <EnclaveSpecActions />}
            <DataRoomActions
              __typename={__typename}
              actions={actions}
              id={dataRoomId}
              inline={false}
            />
          </Box>
        </Box>
      </Box>
      <DataRoomDescriptionEditor dataRoomId={dataRoomId} />
    </Box>
  );
};

export default Mainbar;
