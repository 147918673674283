import { DqCard, DqCardContent } from "@decentriq/components";
import { faEyeSlash, faFileSignature } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/joy";
import { memo } from "react";

interface EndUserTermsBlockProps {
  onHide: () => void;
}

const EndUserTermsBlock = memo<EndUserTermsBlockProps>(({ onHide }) => (
  <DqCard color="info" variant="soft">
    <DqCardContent alignItems="flex-start" gap={1}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography level="h4">End-user terms</Typography>
        <Button
          onClick={onHide}
          size="sm"
          startDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
          }
          sx={{
            "--Icon-color": "inherit",
          }}
          variant="plain"
        >
          Do not show again
        </Button>
      </Stack>
      <Typography level="body-md">
        Don’t forget to read the End-user terms before using the Decentriq
        Platform.
      </Typography>
      <Button
        color="primary"
        component="a"
        fullWidth={true}
        href="https://www.decentriq.com/end-user-terms"
        startDecorator={
          <FontAwesomeIcon fixedWidth={true} icon={faFileSignature} />
        }
        sx={{ mt: "auto" }}
        target="_blank"
        variant="solid"
      >
        End-user terms
      </Button>
    </DqCardContent>
  </DqCard>
));

EndUserTermsBlock.displayName = "EndUserTermsBlock";

export default EndUserTermsBlock;
