import { DqCard, DqCardContent } from "@decentriq/components";
import { Alert, Stack, Typography } from "@mui/joy";
import { isNil } from "lodash";
import { memo } from "react";
import { formatNumber } from "features/mediaDataRoom/utils";
import AudienceQualityStatisticsLoading from "./AudienceQualityStatisticsLoading";

type EstimatedAudienceSizeProps = {
  estimatedAudienceSize: number | null | undefined;
  excludedAudienceSize?: number | null;
  loading?: boolean;
};

const EstimatedAudienceSize = memo<EstimatedAudienceSizeProps>(
  ({ estimatedAudienceSize, excludedAudienceSize, loading }) => {
    return (
      <DqCard>
        <DqCardContent>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            Estimated audience size
          </Typography>
          {loading ? (
            <AudienceQualityStatisticsLoading />
          ) : (
            <Stack alignItems="center">
              <Typography fontWeight={500} level="h1">
                {!isNil(estimatedAudienceSize)
                  ? formatNumber(estimatedAudienceSize)
                  : "-"}
              </Typography>
              {excludedAudienceSize && (
                <Alert size="sm">
                  {excludedAudienceSize} users from the seed audience have been
                  replaced with the next most similar users.
                </Alert>
              )}
            </Stack>
          )}
        </DqCardContent>
      </DqCard>
    );
  }
);
EstimatedAudienceSize.displayName = "EstimatedAudienceSize";

export default EstimatedAudienceSize;
