import { useAccessTokensQuery } from "@decentriq/graphql/dist/hooks";
import { Box, List } from "@mui/joy";
import { memo } from "react";
import AccessTokenListItem from "../AccessTokenListItem/AccessTokenListItem";
import AccessTokensConstructorForm from "../AccessTokensConstructorForm/AccessTokensConstructorForm";
import AccessTokensTile from "../AccessTokensTile/AccessTokensTile";

const AccessTokensConstructor = memo(() => {
  const { data: tokensData } = useAccessTokensQuery();
  const tokens = (tokensData?.myself?.apiTokens?.nodes || []).filter(
    ({ revoked }) => !revoked
  );

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <AccessTokensConstructorForm />
      {tokens.length > 0 && (
        <List>
          {tokens.map(({ id, revoked }) => (
            <AccessTokenListItem key={id} revoked={revoked}>
              <AccessTokensTile revoked={revoked} tokenId={id} />
            </AccessTokenListItem>
          ))}
        </List>
      )}
    </Box>
  );
});
AccessTokensConstructor.displayName = "AccessTokensConstructor";

export default AccessTokensConstructor;
