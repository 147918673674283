import { DqEmptyData, DqTable } from "@decentriq/components";
import {
  type FullActivationConfigurationFragment,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/joy";
import { type TableRowProps } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { Fragment, memo, useMemo } from "react";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import { CollaborationTypes } from "models";
import DeleteActivationConfigurationButton from "../DeleteActivationConfigurationButton/DeleteActivationConfigurationButton";

const ActivationConfigurationsTable = memo(() => {
  const { activationConfigurations, publisherDisplayConfiguration } =
    usePublisherPortal();
  const hasDisplayConfiguration = Boolean(publisherDisplayConfiguration);
  const columns: MRT_ColumnDef<FullActivationConfigurationFragment>[] =
    useMemo(() => {
      return [
        {
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="User defined name for the config. Not publicly visible, only within your organization.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "name",
          header: "Configuration name",
          id: "name",
          maxSize: 150,
        },
        {
          Cell: ({ cell, row }) => {
            const advertiserDomains = cell.getValue<string[]>() || [];
            const { matchAnyDomain, id } = row?.original || {};
            return matchAnyDomain ? (
              "Match any domain"
            ) : (
              <div>
                {advertiserDomains.map((domain, index) => (
                  <Fragment key={`${id}-match-${index}`}>
                    {index > 0 && <br />}
                    <span>{domain}</span>
                  </Fragment>
                ))}
              </div>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="Advertisers registered to these domains will be able to create clean rooms using this config. If there is both a domain and global config, the domain config has precedence.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "advertiserDomains",
          header: "Advertiser",
          id: "advertiserDomains",
          maxSize: 150,
        },
        {
          Cell: ({ row }) => {
            const {
              allowInsights,
              allowLookalike,
              allowRetargeting,
              allowMeasurements,
              allowRuleBasedAudiences,
              allowExclusionTargeting,
            } = row?.original || {};
            const collaborationTypes = [
              allowInsights ? CollaborationTypes.Insights : undefined,
              allowRetargeting ? CollaborationTypes.Remarketing : undefined,
              allowLookalike ? CollaborationTypes.Lookalike : undefined,
              allowRuleBasedAudiences
                ? CollaborationTypes.RuleBasedAudiences
                : undefined,
              allowMeasurements ? CollaborationTypes.Measurements : undefined,
              allowExclusionTargeting
                ? CollaborationTypes.ExclusionTargeting
                : undefined,
            ].filter(Boolean);
            const value = collaborationTypes.join(", ");
            return (
              <Tooltip title={value}>
                <span style={{ verticalAlign: "middle" }}>
                  {collaborationTypes.join(", ")}
                </span>
              </Tooltip>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="These are the DCR features that are available in this config. Other features will be greyed out in the publisher discovery menu, and unavailble in the clean room creation dialog.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "allowInsights",
          header: "Collaboration types",
          id: "dcrFeatures",
        },
        {
          Cell: ({ row }) => {
            const { matchingIdFormat, matchingIdHashingAlgorithm } =
              row?.original || {};
            return matchingIdTypeAndHashingAlgorithmPresentation({
              matchingIdFormat,
              matchingIdHashingAlgorithm:
                matchingIdHashingAlgorithm as TableColumnHashingAlgorithm,
            });
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="The type of matching key. The advertiser will be able to match against this key, and you must provision datalabs using this key to any clean rooms they create with this config. Types with a specific format (e.g. email, hashed email) have some format validation.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "matchingIdFormat",
          header: "Matching ID",
          id: "matchingId",
        },
        {
          Cell: ({ cell, row }) => {
            const publisherParticipants = cell.getValue<string[]>() || [];
            const { id } = row?.original || {};
            return (
              <div>
                {publisherParticipants.map((participant, index) => (
                  <Fragment key={`${id}-participants-${index}`}>
                    {index > 0 && <br />}
                    <span>{participant}</span>
                  </Fragment>
                ))}
              </div>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="These are the users from your organization that will be in clean rooms using this config. These accounts have permission upload data, download data, and view insights.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "publisherParticipants",
          header: "Accounts",
          id: "publisherParticipants",
        },
      ];
    }, []);
  if (activationConfigurations.length === 0) {
    return (
      <DqEmptyData
        title={`No DCR configurations available. ${hasDisplayConfiguration ? "Please create one." : "Please create a display configuration first, then set up a DCR configuration."}`}
      />
    );
  }
  return (
    <DqTable
      columns={columns}
      data={activationConfigurations}
      enableRowActions={true}
      muiTableBodyRowProps={({ row }) =>
        ({
          "data-testid":
            testIds.publisherPortal.activationConfigurations.recordHelper +
            row.original.name.toLowerCase(),
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": { pr: 2 },
          },
        }) as TableRowProps
      }
      muiTableHeadRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": {
            "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
              right: "-1rem",
            },
            pr: 2,
          },
        },
      }}
      muiTableProps={{
        style: {
          "--col-mrt_row_actions-size": 36 + 16,
          "--header-mrt_row_actions-size": 36 + 16,
        } as React.CSSProperties,
      }}
      renderRowActions={({
        row: {
          original: { id, name },
        },
      }) => <DeleteActivationConfigurationButton id={id} name={name} />}
    />
  );
});
ActivationConfigurationsTable.displayName = "ActivationConfigurationsTable";

export default ActivationConfigurationsTable;
