import { MediaDataRoomOrganizationRole } from "@decentriq/types";
import {
  faBullhorn,
  faDatabase,
  faGlasses,
  faNewspaper,
  faUserTie,
} from "@fortawesome/pro-light-svg-icons";
import { faPlus as farPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "@mui/joy";
import { memo } from "react";
import {
  useCreationWizardConfiguration,
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "../../../../contexts";
import { ParticipantsEditor } from "./components";

const RolesEditor: React.FC = memo(() => {
  const {
    withAgency,
    withObserver,
    setWithAgency,
    setWithObserver,
    setMainAdvertiserUserEmail,
    setMainPublisherUserEmail,
    mainPublisherUserEmail,
    mainAdvertiserUserEmail,
    withDataPartner,
  } = useCreationWizardConfiguration();
  const { selectedPublisher } = useCreationWizardPublisher();
  const { selectedDataPartner } = useCreationWizardDataPartner();
  const { organizationRole } = useCreationWizardStepper();
  const isPublisherEmailsFieldReadOnly = !(
    organizationRole === MediaDataRoomOrganizationRole.PUBLISHER ||
    (organizationRole === MediaDataRoomOrganizationRole.ADVERTISER &&
      selectedPublisher === null) ||
    organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER
  );
  const isDataPartnerEmailsFieldReadOnly = !(
    organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER ||
    (organizationRole === MediaDataRoomOrganizationRole.ADVERTISER &&
      selectedDataPartner === null) ||
    organizationRole === MediaDataRoomOrganizationRole.PUBLISHER
  );
  return (
    <Stack>
      <ParticipantsEditor
        icon={faNewspaper}
        label="Publisher"
        mainParticipantValue={mainPublisherUserEmail}
        name="publisherEmails"
        onMainParticipantChange={setMainPublisherUserEmail}
        readOnly={isPublisherEmailsFieldReadOnly}
      />
      <ParticipantsEditor
        icon={faBullhorn}
        label="Advertiser"
        mainParticipantValue={mainAdvertiserUserEmail}
        name="advertiserEmails"
        onMainParticipantChange={setMainAdvertiserUserEmail}
      />
      {withDataPartner && (
        <ParticipantsEditor
          icon={faDatabase}
          label="Data partner"
          name="dataPartnerEmails"
          readOnly={isDataPartnerEmailsFieldReadOnly}
        />
      )}
      {withObserver && (
        <ParticipantsEditor
          icon={faGlasses}
          label="Observer"
          name="observerEmails"
          onRemove={() => setWithObserver(false)}
          tooltipTitle="Observer users can see overlap statistics, insights, the list of audiences and the audit log but not perform any actions in the data clean room."
        />
      )}
      {withAgency && (
        <ParticipantsEditor
          icon={faUserTie}
          label="Agency"
          name="agencyEmails"
          onRemove={() => setWithAgency(false)}
          tooltipTitle="Agency users can perform the same actions as advertiser users, except for uploading data."
        />
      )}
      <Stack direction="row" flexWrap="wrap">
        {!withObserver && (
          <Button
            color="primary"
            onClick={() => setWithObserver(true)}
            startDecorator={
              <span className="fa-layers fa-fw fa-1x">
                <FontAwesomeIcon fixedWidth={true} icon={faGlasses} />
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={farPlus}
                  transform="shrink-6 up-4 right-13"
                />
              </span>
            }
            sx={{
              "--Button-gap": "1.25rem",
              "--Icon-color": "currentColor",
              whiteSpace: "nowrap",
            }}
            variant="soft"
          >
            Add Observer
          </Button>
        )}
        {!withAgency && (
          <Button
            color="primary"
            onClick={() => setWithAgency(true)}
            startDecorator={
              <span className="fa-layers fa-fw fa-1x">
                <FontAwesomeIcon fixedWidth={true} icon={faUserTie} />
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={farPlus}
                  transform="shrink-6 up-4 right-10"
                />
              </span>
            }
            sx={{
              "--Button-gap": "1rem",
              "--Icon-color": "currentColor",
              whiteSpace: "nowrap",
            }}
            variant="soft"
          >
            Add Agency
          </Button>
        )}
      </Stack>
    </Stack>
  );
});
RolesEditor.displayName = "RolesEditor";

export default RolesEditor;
