import {
  Alert,
  Checkbox,
  FormControl,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";

const MeasurementDcrFeatures = memo(() => {
  const {
    canViewMeasurements,
    isEditorDisabled,
    updateOrganizationFlags,
    isPythonComputationTypeEnabled,
  } = useOrganizationAdminConfiguration();

  return (
    <Stack>
      <Typography level="title-md">Measurement DCR</Typography>
      <FormControl>
        <Stack>
          <Tooltip title="This allows users in this organisation to create Measurement DCRs. Note that this feature is still in BETA. Note that Python computation type must be enabled to use Measurement DCRs.">
            <Checkbox
              checked={canViewMeasurements}
              disabled={isEditorDisabled}
              label="Enable Measurement DCRs (BETA)"
              name="canViewMeasurements"
              onChange={updateOrganizationFlags}
            />
          </Tooltip>
          {canViewMeasurements && !isPythonComputationTypeEnabled && (
            <Alert color="warning">
              Python computation type must be enabled to run Measurement DCRs.
            </Alert>
          )}
        </Stack>
      </FormControl>
    </Stack>
  );
});

MeasurementDcrFeatures.displayName = "MeasurementDcrFeatures";

export default MeasurementDcrFeatures;
