import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpen as falBookOpen,
  faFileSignature as falFileSignature,
  faQuestionCircle as falQuestionCircle,
  faRocketLaunch as falRocketLaunch,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from "@mui/joy";
import { type SxProps } from "@mui/system";
import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import { SupportDialog } from "components";
import { useDocsLink } from "hooks";

interface UserSupportMenuItemProps {
  icon: IconProp;
  onClick?: () => void;
  title: string;
  component?: React.ElementType;
  href?: string;
  target?: string;
  underline?: "none" | "hover" | "always";
  sx?: SxProps;
}

const UserSupportMenuItem = memo<UserSupportMenuItemProps>(
  ({ icon, onClick, title, ...restProps }) => {
    return (
      <ListItem>
        <ListItemButton
          onClick={onClick}
          sx={{ flexDirection: "column" }}
          {...restProps}
        >
          <ListItemDecorator>
            <FontAwesomeIcon fixedWidth={true} icon={icon} size="lg" />
          </ListItemDecorator>
          <ListItemContent>{title}</ListItemContent>
        </ListItemButton>
      </ListItem>
    );
  }
);
UserSupportMenuItem.displayName = "UserSupportMenuItem";

const UserSupportMenu = memo(() => {
  const [
    isSupportDialogOpen,
    { setFalse: closeSupportDialog, setTrue: openSupportDialog },
  ] = useBoolean(false);
  const docsBaseLink = useDocsLink();
  return (
    <Fragment>
      <List
        orientation="horizontal"
        sx={{
          "& .MuiListItemDecorator-root": { marginInlineEnd: 0 },
          "--ListItem-gap": "0.25rem",
          "--ListItemDecorator-size": "1.25rem",
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          flexGrow: 0,
          marginTop: "auto",
        }}
      >
        <UserSupportMenuItem
          component={Link}
          href={`${docsBaseLink}/release-notes`}
          icon={falRocketLaunch}
          target="_blank"
          title="Release notes"
          underline="none"
        />
        <UserSupportMenuItem
          component={Link}
          href={docsBaseLink}
          icon={falBookOpen}
          target="_blank"
          title="Documentation"
          underline="none"
        />
        <UserSupportMenuItem
          component={Link}
          href="https://www.decentriq.com/end-user-terms"
          icon={falFileSignature}
          target="_blank"
          title="End-user terms"
          underline="none"
        />
        <UserSupportMenuItem
          icon={falQuestionCircle}
          onClick={openSupportDialog}
          title="Contact us"
        />
      </List>
      <SupportDialog onClose={closeSupportDialog} open={isSupportDialogOpen} />
    </Fragment>
  );
});
UserSupportMenu.displayName = "UserSupportMenu";

export default UserSupportMenu;
