import { useOrganizationMicrosoftDspMemberQuery } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import { useMemo } from "react";
import { useOrganizationFeatures, useOrganizationPreferences } from "hooks";
import { MicrosoftDspMember } from "models";

interface GetDisabledAndFilteredExportSyncItemsHookResult {
  getDisabledItems: (items: DataTargetType[]) => DataTargetType[];
  getFilteredItems: (items: DataTargetType[]) => DataTargetType[];
}

const useGetDisabledAndFilteredExportSyncItems =
  (): GetDisabledAndFilteredExportSyncItemsHookResult => {
    const { organizationId, isLoading: isLoadingOrganizationPreferences } =
      useOrganizationPreferences();
    const { exportConnectors } = useOrganizationFeatures({
      organizationId,
      skip: isLoadingOrganizationPreferences,
    });
    const exportConnectorsByKey = useMemo(
      () =>
        Object.fromEntries(
          Object.entries(exportConnectors).map(([key, value]) => [key, value])
        ),
      [exportConnectors]
    );
    const { data: organizationMicrosoftDspMemberQueryData } =
      useOrganizationMicrosoftDspMemberQuery({
        skip: !organizationId,
        variables: { organizationId },
      });
    const microsoftDspMember = organizationMicrosoftDspMemberQueryData
      ?.organization?.microsoftDspMember as MicrosoftDspMember;
    const isMicrosoftDspDisabled =
      !microsoftDspMember || microsoftDspMember === MicrosoftDspMember.None;
    return useMemo(
      () => ({
        getDisabledItems: (items: DataTargetType[]): DataTargetType[] =>
          items.filter(
            (item) =>
              exportConnectorsByKey[item]?.isDisabled ||
              (item === DataTargetType.MicrosoftDsp && isMicrosoftDspDisabled)
          ),
        getFilteredItems: (items: DataTargetType[]): DataTargetType[] =>
          items.filter((item) => !exportConnectorsByKey[item]?.isHidden),
      }),
      [exportConnectorsByKey, isMicrosoftDspDisabled]
    );
  };

export default useGetDisabledAndFilteredExportSyncItems;
