import { DqCard } from "@decentriq/components";
import { Alert, Chip, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import {
  EstimatedAudienceSize,
  QualityScore,
  useMediaDataRoom,
} from "features/mediaDataRoom";
import { useLookalikeAdvertiserAudienceGenerator } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator";
import { AudienceQualityStatisticsDrawer } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator/components";
import { PositiveCurveScoreBar } from "./components";

const AudienceQualityStatistics = memo(() => {
  const { selectedAudienceStatistics } =
    useLookalikeAdvertiserAudienceGenerator();
  const {
    testSetPositiveExamplesSize,
    testSetSize,
    estimatedAudienceSize,
    audienceQualityScore,
    excludedAudienceSize,
    computeState: { loading: isStatisticsComputationLoading },
  } = selectedAudienceStatistics!;
  const {
    supportedFeatures: { showAbsoluteValues },
  } = useMediaDataRoom();
  return (
    <Stack direction="column" flex={1} height="fit-content" spacing={1.5}>
      <Typography
        endDecorator={<Chip color="warning">Beta</Chip>}
        fontWeight={600}
        justifyContent="center"
        level="title-md"
      >
        Lookalike audience quality statistics
      </Typography>
      {isStatisticsComputationLoading && (
        <Alert color="neutral">
          <div>
            <Typography level="title-md" sx={{ color: "inherit" }}>
              Audience is computing
            </Typography>
            <Typography level="body-sm" sx={{ color: "inherit" }}>
              This may take up to an hour. You can still save the audience
              during this time, but results won't be immediate.
            </Typography>
          </div>
        </Alert>
      )}
      {showAbsoluteValues && (
        <EstimatedAudienceSize
          estimatedAudienceSize={estimatedAudienceSize}
          excludedAudienceSize={excludedAudienceSize}
          loading={isStatisticsComputationLoading}
        />
      )}
      <QualityScore
        loading={isStatisticsComputationLoading}
        qualityScore={audienceQualityScore}
      />
      <DqCard>
        <PositiveCurveScoreBar />
      </DqCard>
      {!isStatisticsComputationLoading &&
        showAbsoluteValues &&
        (testSetPositiveExamplesSize || testSetSize) < 50 && (
          <Alert color="warning">
            There were too few individuals in the overlapping audience to create
            a large holdout group for testing. This may result in a less
            accurate assessment of model quality.
          </Alert>
        )}
      <AudienceQualityStatisticsDrawer />
    </Stack>
  );
});
AudienceQualityStatistics.displayName = "AudienceQualityStatistics";

export default AudienceQualityStatistics;
