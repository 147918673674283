import { testIds } from "@decentriq/utils";
import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { DataPartnerPortalWrapper } from "features/dataPartnerPortal/contexts";
import DataPartnerConfigurations from "../DataPartnerConfigurations/DataPartnerConfigurations";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    "--Tabs-spacing": 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

export enum DataPartnerPortalTabs {
  CONFIGURATIONS = "configurations",
}

interface DataPartnerPortalProps {
  activeTab: DataPartnerPortalTabs;
  baseUrl: string;
}

const DataPartnerPortal: React.FC<DataPartnerPortalProps> = ({
  activeTab,
  baseUrl,
}) => {
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="h4">Data partner portal</Typography>
      </Stack>
      <Tabs sx={tabsSx} value={activeTab}>
        <TabList>
          <Tab
            component={Link}
            data-testid={testIds.dataPartnerPortal.tabList.configurations}
            to={`${baseUrl}/${DataPartnerPortalTabs.CONFIGURATIONS}`}
            value={DataPartnerPortalTabs.CONFIGURATIONS}
          >
            Configurations
          </Tab>
        </TabList>
        <TabPanel sx={tabPanelSx} value={DataPartnerPortalTabs.CONFIGURATIONS}>
          <DataPartnerPortalWrapper>
            <DataPartnerConfigurations />
          </DataPartnerPortalWrapper>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};
DataPartnerPortal.displayName = "DataPartnerPortal";

export default DataPartnerPortal;
