import { type DataRoomsListQuery } from "@decentriq/graphql/dist/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled, Typography } from "@mui/joy";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";
import { StateIcon } from "components";
import { useTimeFormatter } from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";

type RecentDataRoomVariant = "outlined" | "default";

interface RecentDataRoomProps {
  dataRoom: DataRoomsListQuery["dataRooms"]["nodes"][number];
  variant: RecentDataRoomVariant;
}

const StyledContainer = styled(Box)<{
  variant: RecentDataRoomVariant;
}>(({ theme: { palette, spacing, radius }, variant }) => ({
  borderRadius: radius.sm,
  cursor: "pointer",
  overflow: "hidden",
  padding: spacing(1),
  ...(variant === "outlined"
    ? {
        "&:hover": { background: palette.primary[50] },
      }
    : {
        border: `1px solid ${palette.divider}`,
      }),
}));

const RecentDataRoom = memo<RecentDataRoomProps>(
  ({ dataRoom, variant = "default" }) => {
    const { __typename, id, isStopped, name, updatedAt } = dataRoom;
    const timeFormatter = useTimeFormatter();
    const navigate = useNavigate();
    const dataRoomLink = dataRoomPathBuilder(
      id,
      __typename as DataRoomTypeNames
    );
    const isLocked =
      __typename === DataRoomTypeNames.PublishedDataRoom ||
      __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
      __typename === DataRoomTypeNames.PublishedMediaInsightsDcr ||
      __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom;
    return (
      <StyledContainer onClick={() => navigate(dataRoomLink)} variant={variant}>
        <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
          <StateIcon
            status={isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"}
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              gap: 1,
              overflow: "hidden",
            }}
          >
            <Typography
              fontWeight="fontWeightSemiBold"
              level="body-md"
              noWrap={true}
            >
              {name}
            </Typography>
          </Box>
          <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
        </Box>
        <Typography level="body-sm">
          Last activity{" "}
          <TimeAgo
            date={updatedAt}
            formatter={timeFormatter}
            style={{ whiteSpace: "nowrap" }}
          />
        </Typography>
      </StyledContainer>
    );
  }
);

RecentDataRoom.displayName = "RecentDataRoom";

export default RecentDataRoom;
