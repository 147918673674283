import { testIds } from "@decentriq/utils";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { type SchemaType } from "../../../DqCreateMediaDcrFormProvider/model";
import {
  useCreationWizardConfiguration,
  useCreationWizardStepper,
} from "../../contexts";
import {
  CollaborationTypesCheckboxes,
  MatchingIdSelect,
  NameInput,
  OptionsSwitches,
  RolesEditor,
} from "./components";

const CollaborationConfigurationStep = memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const { control, trigger, getValues } = useFormContext<SchemaType>();
    const { handleSubmit } = useCreationWizardConfiguration();
    const { handleBackStep } = useCreationWizardStepper();
    const onPublishClick = async () => {
      const isValid = await trigger();
      if (isValid) handleSubmit(getValues());
    };
    return (
      <ModalDialog ref={ref}>
        <DialogTitle>Create new media clean room</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack>
            <Typography level="title-md">
              1. Data clean room configuration
            </Typography>
            <NameInput />
            <Typography level="title-md">2. Roles and participants</Typography>
            <Alert>
              All participants need to have a Decentriq account. Invite external
              participants via the Admin portal.
            </Alert>
            <RolesEditor />
            <Typography level="title-md">
              3. Media clean room configuration
            </Typography>
            <MatchingIdSelect />
            <CollaborationTypesCheckboxes />
            <OptionsSwitches />
            <Controller
              control={control}
              name="enabledFeatures"
              render={({ fieldState }) => {
                const { error } = fieldState;
                return (
                  <FormControl error={Boolean(error)}>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleBackStep}>Back</Button>
          <Button
            color="primary"
            data-testid={
              testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard.submitButton
            }
            onClick={onPublishClick}
            variant="solid"
          >
            Publish
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  })
);
CollaborationConfigurationStep.displayName = "CollaborationConfigurationStep";

export default CollaborationConfigurationStep;
