import { DqCard, DqCardContent } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { isNil } from "lodash";
import { memo } from "react";
import { formatNumber } from "features/mediaDataRoom/utils";
import AudienceQualityStatisticsLoading from "./AudienceQualityStatisticsLoading";

type AddressableAudienceProps = {
  addressableAudience: number | null | undefined;
  loading?: boolean;
};

const AddressableAudience: React.FC<AddressableAudienceProps> = ({
  addressableAudience,
  loading,
}) => {
  return (
    <DqCard>
      <DqCardContent>
        <Typography
          gutterBottom={true}
          level="body-sm"
          textAlign="center"
          textColor="inherit"
        >
          Addressable audience
        </Typography>
        {loading ? (
          <AudienceQualityStatisticsLoading />
        ) : (
          <Typography fontWeight={800}>
            {!isNil(addressableAudience)
              ? formatNumber(addressableAudience)
              : "-"}
          </Typography>
        )}
      </DqCardContent>
    </DqCard>
  );
};

AddressableAudience.displayName = "AddressableAudience";

export default memo(AddressableAudience);
