import { testIds } from "@decentriq/utils";
import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { type SxProps } from "@mui/system";
import { Fragment, memo } from "react";
import { Link, useResolvedPath } from "react-router-dom";
import {
  OrganizationsPage,
  PublisherPortalOverviewPage,
  UsersPage,
} from "pages";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    "--Tabs-spacing": 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

export enum AdminPortalTabs {
  ORGANIZATIONS = "organizations",
  USERS = "users",
  PUBLISHER_PORTAL_OVERVIEW = "publisher-portal-overview",
}

type AdminPortalProps = {
  activeTab: AdminPortalTabs;
};

const AdminPortal: React.FC<AdminPortalProps> = ({ activeTab }) => {
  const { pathname } = useResolvedPath("/admin");
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="h4">Admin portal</Typography>
      </Stack>
      <Tabs sx={tabsSx} value={activeTab}>
        <TabList>
          <Tab
            component={Link}
            data-testid={testIds.adminPortal.tabList.organizations}
            to={`${pathname}/${AdminPortalTabs.ORGANIZATIONS}`}
            value={AdminPortalTabs.ORGANIZATIONS}
          >
            Manage organizations
          </Tab>
          <Tab
            component={Link}
            to={`${pathname}/${AdminPortalTabs.USERS}`}
            value={AdminPortalTabs.USERS}
          >
            Manage users
          </Tab>
          <Tab
            component={Link}
            to={`${pathname}/${AdminPortalTabs.PUBLISHER_PORTAL_OVERVIEW}`}
            value={AdminPortalTabs.PUBLISHER_PORTAL_OVERVIEW}
          >
            Publisher portal overview
          </Tab>
        </TabList>
        <TabPanel sx={tabPanelSx} value={AdminPortalTabs.ORGANIZATIONS}>
          <OrganizationsPage />
        </TabPanel>
        <TabPanel sx={tabPanelSx} value={AdminPortalTabs.USERS}>
          <UsersPage />
        </TabPanel>
        <TabPanel
          sx={tabPanelSx}
          value={AdminPortalTabs.PUBLISHER_PORTAL_OVERVIEW}
        >
          <PublisherPortalOverviewPage />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

AdminPortal.displayName = "AdminPortal";

export default memo(AdminPortal);
