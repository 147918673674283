import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { Chip, Stack, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";
import { WORKER_TYPE_COLOR } from "models";

const OrganizationFeaturesDisplay = memo(() => {
  const {
    availableEnclaveWorkers,
    hasAdvertiserFeatures,
    hasAnalyticsFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  } = useOrganizationAdminConfiguration();
  return (
    <Stack>
      <Typography level="title-sm">Available computation types</Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ "& > *": { flex: "0 1 calc(25% - 8px * 3 / 4)" } }}
      >
        {availableEnclaveWorkers.map(({ shortName }) =>
          shortName === WorkerTypeShortName.Python ? (
            <Fragment key={shortName}>
              <Chip
                sx={({ palette }) => ({
                  backgroundColor: palette.chip[WORKER_TYPE_COLOR[shortName]],
                })}
              >
                {shortName}
              </Chip>
              <Chip
                sx={({ palette }) => ({
                  backgroundColor:
                    palette.chip[WORKER_TYPE_COLOR[WorkerTypeShortName.Match]],
                })}
              >
                Matching
              </Chip>
            </Fragment>
          ) : (
            <Chip
              sx={({ palette }) => ({
                backgroundColor: palette.chip[WORKER_TYPE_COLOR[shortName]],
              })}
            >
              {shortName}
            </Chip>
          )
        )}
      </Stack>
      <Typography level="title-md">Features</Typography>
      <Stack direction="row" flexWrap="wrap">
        {hasAdvertiserFeatures && <Chip>Advertiser</Chip>}
        {hasAnalyticsFeatures && <Chip>Analytics</Chip>}
        {hasPublisherFeatures && <Chip>Publisher</Chip>}
        {hasDataPartnerFeatures && <Chip>Data partner</Chip>}
      </Stack>
    </Stack>
  );
});

OrganizationFeaturesDisplay.displayName = "OrganizationFeaturesDisplay";

export default OrganizationFeaturesDisplay;
