// TODO: Fix joy migration
import {
  faExternalLinkSquare as falExternalLinkSquare,
  faFileCertificate as falFileCertificate,
  faKey as falKey,
  faMicrochip as falMicrochip,
  faShieldCheck as falShieldCheck,
} from "@fortawesome/pro-light-svg-icons";
import { faShieldKeyhole as fatShieldKeyhole } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";

interface PublishedAndProtectedDialogProps {
  open: boolean;
  onClose: () => void;
}

const PublishedAndProtectedDialog = memo<PublishedAndProtectedDialogProps>(
  ({ open, onClose }) => {
    return (
      <Modal onClose={onClose} open={open}>
        <ModalDialog role="alertdialog">
          <DialogTitle>
            <Box sx={{ color: "secondary.500", mb: 1, textAlign: "center" }}>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={fatShieldKeyhole}
                size="4x"
              />
            </Box>
            <Typography level="title-md" sx={{ textWrap: "balance" }}>
              <strong>
                This data clean room is published and protected by confidential
                computing guarantees
              </strong>
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ color: "inherit" }}>
            <Typography
              component="div"
              level="body-sm"
              sx={{ color: "inherit" }}
            >
              Data clean room participants can securely provision their
              datasets. Hardware technologies like Intel® SGX and AMD SEV-SNP
              help to protect this data from disclosure or modification by
              partitioning Decentriq Platform application into the enclaves in
              memory that increase security:
            </Typography>
            <List
              size="sm"
              sx={{
                "& > li": {
                  "& > span": {
                    "& > svg": {
                      fontSize: "1rem",
                    },
                    display: "inline-block",
                  },
                  alignItems: "baseline",
                },
                "--ListItemDecorator-size": "1.75rem",
                textAlign: "left",
                textWrap: "auto",
              }}
            >
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon fixedWidth={true} icon={falShieldCheck} />
                </ListItemDecorator>
                <ListItemContent>
                  Enclaves have hardware-assisted confidentiality and
                  integrity-added protections to help prevent access from
                  processes at higher privilege levels.
                </ListItemContent>
              </ListItem>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon fixedWidth={true} icon={falMicrochip} />
                </ListItemDecorator>
                <ListItemContent>
                  Hardware-enhanced content protection capabilities help content
                  owners with protecting their intellectual property through
                  unaltered or unmodified streaming.
                </ListItemContent>
              </ListItem>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={falFileCertificate}
                  />
                </ListItemDecorator>
                <ListItemContent>
                  Through attestation services it is also possible to receive
                  verification on the identity of the application running in the
                  enclave.
                </ListItemContent>
              </ListItem>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon fixedWidth={true} icon={falKey} />
                </ListItemDecorator>
                <ListItemContent>
                  Data is end-to-end encrypted so nobody except data clean room
                  participants — not even Decentriq — can decrypt and read it.
                </ListItemContent>
              </ListItem>
            </List>
          </DialogContent>
          <Divider />
          <Button
            component="a"
            endDecorator={
              <FontAwesomeIcon fixedWidth={true} icon={falExternalLinkSquare} />
            }
            href="https://www.intel.com/content/www/us/en/developer/tools/software-guard-extensions/attestation-services.html"
            sx={{ borderRadius: 0, marginInline: "var(--Divider-inset)" }}
            target="_blank"
          >
            How Intel® SGX remote attestation works
          </Button>
          <Divider />
          <Button
            component="a"
            endDecorator={
              <FontAwesomeIcon fixedWidth={true} icon={falExternalLinkSquare} />
            }
            href="https://blog.decentriq.com/swiss-cheese-to-cheddar-securing-amd-sev-snp-early-boot-2/"
            sx={{ borderRadius: 0, marginInline: "var(--Divider-inset)" }}
            target="_blank"
          >
            How Decentriq operates on AMD SEV-SNP
          </Button>
          <Divider />
          <DialogActions>
            <Button onClick={onClose}>Okay</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);
PublishedAndProtectedDialog.displayName = "PublishedAndProtectedDialog";

export default PublishedAndProtectedDialog;
