import { DqCard, DqCardContent } from "@decentriq/components";
import { MediaDataRoomOrganizationRole } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import {
  faBullhorn as fatBullhorn,
  faDatabase as fatDatabase,
  faNewspaper as fatNewspaper,
} from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ModalDialog,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import { forwardRef, memo, useState } from "react";
import { useOrganizationPreferences } from "hooks";
import { useCreationWizardStepper } from "../../contexts";

const SelectOrganizationRoleStep = memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const {
      hasAdvertiserFeatures,
      hasPublisherFeatures,
      hasDataPartnerFeatures,
    } = useOrganizationPreferences();
    const { setOrganizationRole: submitOrganizationRole, handleBackStep } =
      useCreationWizardStepper();
    const [organizationRole, setOrganizationRole] =
      useState<MediaDataRoomOrganizationRole | null>(null);
    const roles = [
      {
        enabled: hasAdvertiserFeatures,
        icon: fatBullhorn,
        role: MediaDataRoomOrganizationRole.ADVERTISER,
        testId:
          testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectOrganizationRole
            .advertiser,
      },
      {
        enabled: hasPublisherFeatures,
        icon: fatNewspaper,
        role: MediaDataRoomOrganizationRole.PUBLISHER,
        testId:
          testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectOrganizationRole
            .publisher,
      },
      {
        enabled: hasDataPartnerFeatures,
        icon: fatDatabase,
        role: MediaDataRoomOrganizationRole.DATA_PARTNER,
        testId:
          testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectOrganizationRole
            .dataPartner,
      },
    ];
    return (
      <ModalDialog ref={ref}>
        <DialogTitle>Create new media clean room</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack>
            <Typography level="title-md">Organization role</Typography>
            <Typography level="body-sm">
              Select in which role you want to create the data clean room
            </Typography>
            <RadioGroup
              name="organizationRole"
              onChange={(event) =>
                setOrganizationRole(
                  event.target.value as MediaDataRoomOrganizationRole
                )
              }
              value={organizationRole}
            >
              <Grid columnSpacing={1} container={true} xs={12}>
                {roles
                  .filter(({ enabled }) => enabled)
                  .map(({ icon, role, testId }) => (
                    <Grid key={role} xs={4}>
                      <DqCard
                        data-testid={testId}
                        selectable={{
                          component: "radio",
                          value: role,
                        }}
                      >
                        <DqCardContent gap={1}>
                          <FontAwesomeIcon icon={icon} size="3x" />
                          <Typography
                            level="title-md"
                            noWrap={true}
                            textAlign="center"
                          >
                            {role}
                          </Typography>
                        </DqCardContent>
                      </DqCard>
                    </Grid>
                  ))}
              </Grid>
            </RadioGroup>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleBackStep}>Back</Button>
          <Button
            color="primary"
            disabled={!organizationRole}
            onClick={() => {
              if (!organizationRole) {
                return;
              }
              submitOrganizationRole(organizationRole);
            }}
            variant="solid"
          >
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  })
);
SelectOrganizationRoleStep.displayName = "SelectOrganizationRoleStep";

export default SelectOrganizationRoleStep;
