import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { useCallback, useEffect, useMemo } from "react";
import { type ValidationReport } from "validate-js";
import {
  type AbMediaRequestValueOfKey,
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type PublishedDatasetsHashes } from "../../models";

interface PublisherDataReportHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  publishedDatasetsHashes: PublishedDatasetsHashes;
  session?: Session;
  skip: boolean;
}

export interface PublisherDataReportHookResult {
  matchingDataset: MediaDataRoomJobHookResult<ValidationReport>;
  demographicsDataset: MediaDataRoomJobHookResult<ValidationReport>;
  segmentsDataset: MediaDataRoomJobHookResult<ValidationReport>;
}

const usePublisherDataReport = ({
  session,
  dataRoomId,
  driverAttestationHash,
  publishedDatasetsHashes,
  skip,
}: PublisherDataReportHookPayload): PublisherDataReportHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const customDatasetsHashes = useMemo(
    () =>
      publishedDatasetsHashes
        .updateDatasetsHash({
          demographicsDatasetHash:
            publishedDatasetsHashes.publisherDatasetsHashes
              .demographicsDatasetHash ?? "",
          matchingDatasetHash:
            publishedDatasetsHashes.publisherDatasetsHashes
              .matchingDatasetHash ?? "",
          segmentsDatasetHash:
            publishedDatasetsHashes.publisherDatasetsHashes
              .segmentsDatasetHash ?? "",
        })
        .updateAdvertiserDatasetHash(null),
    [publishedDatasetsHashes]
  );
  const transform = useCallback(
    async (zip: JSZip): Promise<ValidationReport> => {
      const reportFile = zip.file("validation-report.json");
      if (reportFile === null) {
        throw new Error("report.json not found in zip");
      }
      const validationReport = JSON.parse(
        await reportFile.async("string")
      ) as ValidationReport;
      return validationReport;
    },
    []
  );
  const requestCreator = useCallback(
    (
      dataRoomIdHex: string,
      scopeIdHex: string
    ): AbMediaRequestValueOfKey<
      | "getMatchingValidationReport"
      | "getSegmentsValidationReport"
      | "getDemographicsValidationReport"
    > => ({
      dataRoomIdHex,
      scopeIdHex,
    }),
    []
  );
  const matchingDatasetReportComputeJob = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "getMatchingValidationReport",
      dataRoomId,
      driverAttestationHash,
      customDatasetsHashes
    ),
    requestCreator,
    session,
    skip:
      skip ||
      !publishedDatasetsHashes?.publisherDatasetsHashes?.matchingDatasetHash,
    transform,
  });
  const segmentsDatasetReportComputeJob = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "getSegmentsValidationReport",
      dataRoomId,
      driverAttestationHash,
      customDatasetsHashes
    ),
    requestCreator,
    session,
    skip:
      skip ||
      !publishedDatasetsHashes?.publisherDatasetsHashes?.segmentsDatasetHash,
    transform,
  });
  const demographicsDatasetReportComputeJob = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "getDemographicsValidationReport",
      dataRoomId,
      driverAttestationHash,
      customDatasetsHashes
    ),
    requestCreator,
    session,
    skip:
      skip ||
      !publishedDatasetsHashes?.publisherDatasetsHashes
        ?.demographicsDatasetHash,
    transform,
  });
  useEffect(() => {
    const errors: { error: string | undefined; dataset: string }[] = [
      { dataset: "Matching", error: matchingDatasetReportComputeJob.error },
      { dataset: "Segments", error: segmentsDatasetReportComputeJob.error },
      {
        dataset: "Demographics",
        error: demographicsDatasetReportComputeJob.error,
      },
    ].filter(({ error }) => Boolean(error));
    errors.forEach(({ error, dataset }) => {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Cannot fetch ${dataset} data report`
        )
      );
    });
  }, [
    closeSnackbar,
    demographicsDatasetReportComputeJob.error,
    enqueueSnackbar,
    matchingDatasetReportComputeJob.error,
    segmentsDatasetReportComputeJob.error,
  ]);
  // Embeddings dataset is not validated on the backend side, therefore there is no report for it
  return {
    demographicsDataset: demographicsDatasetReportComputeJob,
    matchingDataset: matchingDatasetReportComputeJob,
    segmentsDataset: segmentsDatasetReportComputeJob,
  };
};

export default usePublisherDataReport;
