import {
  createErrorMessagesAndAutofixes,
  DqParticipantsEditor,
} from "@decentriq/components";
import { useAvailablePublishersQuery } from "@decentriq/graphql/dist/hooks";
import {
  faBullhorn,
  faNewspaper,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  FormLabel,
  Skeleton,
  Stack,
  Typography,
} from "@mui/joy";
import uniqBy from "lodash/uniqBy";
import { memo, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const RolesAndParticipants: React.FC = memo(() => {
  const { data: availablePublishersQueryData, loading } =
    useAvailablePublishersQuery();
  const availablePublishers = useMemo(
    () =>
      availablePublishersQueryData?.availablePublishers.nodes.filter(
        (publisher) => publisher.allowMeasurements
      ) || [],
    [availablePublishersQueryData]
  );
  const normalizedArray: { name: string; emails: string[] }[] = useMemo(() => {
    const participants = availablePublishers.map((publisher) => {
      const { publisherName, publisherParticipants } = publisher;
      return {
        emails: publisherParticipants ?? [],
        name: publisherName,
      };
    });
    return uniqBy(participants, "name");
  }, [availablePublishers]);
  const { control } = useFormContext();
  return (
    <Stack>
      <Controller
        control={control}
        name="publishers"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                <Typography
                  level="inherit"
                  startDecorator={
                    <FontAwesomeIcon fixedWidth={true} icon={faNewspaper} />
                  }
                >
                  Publisher
                </Typography>
              </FormLabel>
              {loading ? (
                <Skeleton
                  height={36}
                  sx={{ borderRadius: "sm" }}
                  variant="rectangular"
                />
              ) : (
                <Autocomplete
                  filterSelectedOptions={true}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.name === value.name;
                  }}
                  loading={loading}
                  multiple={true}
                  options={normalizedArray}
                  placeholder="No publishers selected"
                  renderTags={(tags, getTagProps) =>
                    tags.map((tag, index) => {
                      const { key: endDecoratorKey, ...endDecoratorProps } =
                        getTagProps({ index });
                      return (
                        <Chip
                          endDecorator={
                            <ChipDelete
                              key={endDecoratorKey}
                              {...endDecoratorProps}
                              sx={{ cursor: "pointer" }}
                              variant="plain"
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </ChipDelete>
                          }
                          size="lg"
                          variant="soft"
                        >
                          {tag.name}
                        </Chip>
                      );
                    })
                  }
                  sx={{ width: "100%" }}
                  {...field}
                  onChange={(event, value) => field.onChange(value)}
                />
              )}
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="advertiserUserEmails"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                <Typography
                  level="inherit"
                  startDecorator={
                    <FontAwesomeIcon fixedWidth={true} icon={faBullhorn} />
                  }
                >
                  Advertiser
                </Typography>
              </FormLabel>
              <DqParticipantsEditor
                disabled={field.disabled}
                error={error}
                onChange={(event, newValue) => field.onChange(newValue)}
                options={[]}
                placeholder={`e.g. "advertiser@advertiser.com"`}
                value={field.value}
              />
              <FormHelperText>
                {error
                  ? createErrorMessagesAndAutofixes(field, error)
                  : "Type in user email and press Enter"}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </Stack>
  );
});
RolesAndParticipants.displayName = "RolesAndParticipants";

export default RolesAndParticipants;
