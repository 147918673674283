import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { useCallback } from "react";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { DATA_TYPE_COLOR, type DataType } from "models";

interface DataNodeAddNewFieldProps {
  computeNodeNamesAndTypes: { name: string; dataType: DataType }[];
  toggle: (id: string) => void;
}

const DataNodeAddNewField: React.FC<DataNodeAddNewFieldProps> = ({
  computeNodeNamesAndTypes,
  toggle,
}) => {
  const { handleCreate } = useDataNodeActions();
  const makeUniqueName = useCallback(
    (prefix: string, data: { name: string; dataType: DataType }[]) => {
      let helper = 1;
      const isNameTaken = (element: { name: string; dataType: DataType }) =>
        element.name.includes(`${prefix} ${helper}`);
      while (data.some(isNameTaken)) {
        helper++;
      }
      return `${prefix} ${helper}`;
    },
    []
  );
  const generateName = useCallback(
    (dataType: DataType) => {
      if (dataType === "table") {
        const tables = computeNodeNamesAndTypes.filter(
          ({ dataType }) => dataType === "table"
        );
        return makeUniqueName("Table", tables);
      }
      if (dataType === "unstructured") {
        const files = computeNodeNamesAndTypes.filter(
          ({ dataType }) => dataType === "unstructured"
        );
        return makeUniqueName("File", files);
      }
      // Can't be undefined
      return "Name";
    },
    [computeNodeNamesAndTypes, makeUniqueName]
  );
  const create = useCallback(
    async (dataType: DataType) => {
      const name = generateName(dataType);
      const id = await handleCreate({
        dataType: dataType,
        name: name,
      });
      if (id) {
        toggle(id);
      }
    },
    [generateName, handleCreate, toggle]
  );
  const popupId = "data-node-add-menu";
  const handleMenuItemClick = useCallback(
    async (value: DataType) => {
      create(value);
    },
    [create]
  );
  return (
    <Dropdown>
      <MenuButton
        color="primary"
        data-testid={testIds.dataNode.dataNodeAddNewField.newDataButton}
        endDecorator={null}
        id="add_table_dropdown"
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
        variant="soft"
      >
        Add data
      </MenuButton>
      <Menu
        id={popupId}
        placement="bottom-end"
        sx={{ "--ListItemDecorator-size": "1.625rem" }}
      >
        <MenuItem onClick={() => handleMenuItemClick("unstructured")}>
          <Chip
            data-testid={testIds.dataNode.dataNodeAddNewField.dataToggleFile}
            sx={({ palette }) => ({
              backgroundColor: palette.chip[DATA_TYPE_COLOR["unstructured"]],
            })}
          >
            File
          </Chip>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("table")}>
          <Chip
            data-testid={testIds.dataNode.dataNodeAddNewField.dataToggleTable}
            sx={({ palette }) => ({
              backgroundColor: palette.chip[DATA_TYPE_COLOR["table"]],
            })}
          >
            Table
          </Chip>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default DataNodeAddNewField;
