import { DqLoader } from "@decentriq/components";
import { memo } from "react";

const Loading = memo<{ loading?: boolean }>(({ loading = true }) => {
  return loading ? (
    <DqLoader
      sx={{ flex: 1, height: "100%", justifyContent: "center", width: "100%" }}
    />
  ) : null;
});

export default Loading;
