import { DqCard, DqCardContent } from "@decentriq/components";
import { Grid, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";

const DataRoomDatalabInfo = memo(() => {
  const { dataRoomOverviewData, showDatalabInfo } = useDataRoomDrawerContext();

  const wasDataLabPublishedBefore =
    dataRoomOverviewData && "wasDataLabPublishedBefore" in dataRoomOverviewData
      ? dataRoomOverviewData.wasDataLabPublishedBefore
      : false;
  const publishedDataLab =
    dataRoomOverviewData && "publishedDataLab" in dataRoomOverviewData
      ? dataRoomOverviewData.publishedDataLab
      : null;

  if (!showDatalabInfo) {
    return null;
  }

  return (
    <DqCard>
      <DqCardContent alignItems="flex-start">
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Datalab</strong>
        </Typography>
        {publishedDataLab ? (
          <Fragment>
            <Grid container={true} xs={12}>
              <Grid xs={6}>
                <Typography level="body-sm">
                  <strong>Datalab was published before:</strong>
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography level="body-sm">
                  {wasDataLabPublishedBefore ? "yes" : "no"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} xs={12}>
              <Grid xs={6}>
                <Typography level="body-sm">
                  <strong>Name:</strong>
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography level="body-sm">{publishedDataLab.name}</Typography>
              </Grid>
            </Grid>
            <Grid container={true} xs={12}>
              <Grid xs={6}>
                <Typography level="body-sm">
                  <strong>Owner:</strong>
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography level="body-sm">
                  {publishedDataLab.owner.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} xs={12}>
              <Grid xs={6}>
                <Typography level="body-sm">
                  <strong>Owner organization:</strong>
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography level="body-sm">
                  {publishedDataLab.owner.organization?.name}
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Typography level="body-sm">-</Typography>
        )}
      </DqCardContent>
    </DqCard>
  );
});

DataRoomDatalabInfo.displayName = "DataRoomDatalabInfo";

export default DataRoomDatalabInfo;
