import { DqLoader } from "@decentriq/components";
import { usePublishedMediaDataRoomQuery } from "@decentriq/graphql/dist/hooks";
import { DataRoomKind } from "@decentriq/graphql/dist/types";
import { memo, useMemo } from "react";
import {
  type DataRoomErrorReportContextValue,
  DataRoomErrorReportProvider,
} from "contexts";
import { DraftDataRoomActions, PublishedDataRoomActions } from "features";
import {
  MediaDataRoomActions,
  MediaDataRoomWrapper,
} from "features/mediaDataRoom";
import { MediaDataRoomActions as MediaInsightsDcrActions } from "features/MediaInsightsDcr";
import { DataRoomType, DataRoomTypeNames } from "models";

interface DataRoomActionsProps {
  __typename: DataRoomTypeNames;
  id: string;
  inline?: boolean;
  actions: any;
}

const dataRoomTypenameToDataRoomType = new Map<DataRoomTypeNames, DataRoomType>(
  [
    [DataRoomTypeNames.PublishedDataRoom, DataRoomType.DataScience],
    [
      DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
      DataRoomType.LookalikeMedia,
    ],
    [DataRoomTypeNames.PublishedMediaDataRoom, DataRoomType.Media],
  ]
);

const DataRoomActions: React.FC<DataRoomActionsProps> = memo((props) => {
  const { __typename, id } = props;
  const errorReportContextValue = useMemo<DataRoomErrorReportContextValue>(
    () => ({
      dataRoomId: id,
      dataRoomType: dataRoomTypenameToDataRoomType.get(__typename),
    }),
    [id, __typename]
  );
  const { data, loading } = usePublishedMediaDataRoomQuery({
    skip: __typename !== DataRoomTypeNames.PublishedMediaInsightsDcr && !!id,
    variables: { id },
  });

  if (!id) {
    return null;
  }
  if (loading) {
    return <DqLoader />;
  }
  switch (__typename) {
    case DataRoomTypeNames.DraftDataRoom:
      return <DraftDataRoomActions {...props} />;
    case DataRoomTypeNames.PublishedDataRoom:
      return (
        <DataRoomErrorReportProvider value={errorReportContextValue}>
          <PublishedDataRoomActions {...props} />
        </DataRoomErrorReportProvider>
      );
    case DataRoomTypeNames.PublishedMediaInsightsDcr:
      return (
        <DataRoomErrorReportProvider value={errorReportContextValue}>
          {data?.publishedMediaDataRoom?.kind === DataRoomKind.AbMedia ? (
            <MediaDataRoomWrapper data={data.publishedMediaDataRoom}>
              <MediaDataRoomActions {...props} />
            </MediaDataRoomWrapper>
          ) : (
            <MediaInsightsDcrActions {...props} />
          )}
        </DataRoomErrorReportProvider>
      );
    // No longer supported
    case DataRoomTypeNames.PublishedLookalikeMediaDataRoom:
      return null;
  }
});

DataRoomActions.displayName = "DataRoomActions";

export default DataRoomActions;
