import { Modal } from "@mui/joy";
import { memo, useEffect } from "react";
import { useFormContext, useFormState } from "react-hook-form";

interface DqCreateMeasurementDcrModalProps {
  children: React.ReactElement;
  open: boolean;
  onClose?: {
    bivarianceHack(
      event: {},
      reason: "backdropClick" | "escapeKeyDown" | "closeClick"
    ): void;
  }["bivarianceHack"];
}

// NOTE: Can be exposed as generic `DqFormStateModal`
const DqCreateMeasurementDcrModal: React.FC<DqCreateMeasurementDcrModalProps> =
  memo(({ children, open, onClose }) => {
    const { reset } = useFormContext();
    const { isDirty, isSubmitting } = useFormState();
    useEffect(() => (!open ? reset() : undefined), [open, reset]);
    return (
      <Modal
        disableEscapeKeyDown={isDirty}
        onClose={(...args) => !isSubmitting && onClose?.(...args)}
        open={open}
      >
        {children}
      </Modal>
    );
  });
DqCreateMeasurementDcrModal.displayName = "DqCreateMeasurementDcrModal";

export default DqCreateMeasurementDcrModal;
