import { DqPortal as Portal } from "@decentriq/components";
import { useOrganizationLicenseQuery } from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import { faBoxArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { OrganizationArchiveDialog } from "components";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";

const OrganizationArchiveButton = memo(() => {
  const { organizationId } = useOrganizationAdminConfiguration();
  const [
    isOrganizationArchiveDialogOpen,
    {
      setTrue: openOrganizationArchiveDialog,
      setFalse: closeOrganizationArchiveDialog,
    },
  ] = useBoolean(false);
  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationState = organizationLicenseData?.organization?.state;
  const disabled =
    !organizationState ||
    [OrganizationState.Archived, OrganizationState.Passive].includes(
      organizationState
    );
  return (
    <>
      <Button
        color="danger"
        disabled={disabled}
        onClick={openOrganizationArchiveDialog}
        startDecorator={
          <FontAwesomeIcon fixedWidth={true} icon={faBoxArchive} />
        }
        sx={{ height: "fit-content" }}
      >
        Archive organization
      </Button>
      <Portal>
        <OrganizationArchiveDialog
          onCancel={closeOrganizationArchiveDialog}
          open={isOrganizationArchiveDialogOpen}
          organizationId={organizationId}
        />
      </Portal>
    </>
  );
});

OrganizationArchiveButton.displayName = "OrganizationArchiveButton";

export default memo(OrganizationArchiveButton);
