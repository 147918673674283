import { DqCard, DqCardContent } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { isNil } from "lodash";
import { memo } from "react";
import { formatNumber } from "features/mediaDataRoom/utils";
import AudienceQualityStatisticsLoading from "./AudienceQualityStatisticsLoading";

type SeedAudienceProps = {
  seedAudience: number | null | undefined;
  loading?: boolean;
};

const SeedAudience: React.FC<SeedAudienceProps> = ({
  loading,
  seedAudience,
}) => {
  return (
    <DqCard>
      <DqCardContent>
        <Typography
          gutterBottom={true}
          level="body-sm"
          textAlign="center"
          textColor="inherit"
        >
          Seed audience
        </Typography>
        {loading ? (
          <AudienceQualityStatisticsLoading />
        ) : (
          <Typography fontWeight={800}>
            {!isNil(seedAudience) ? formatNumber(seedAudience) : "-"}
          </Typography>
        )}
      </DqCardContent>
    </DqCard>
  );
};

SeedAudience.displayName = "SeedAudience";

export default memo(SeedAudience);
