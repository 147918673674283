import {
  faColumns3,
  faLock,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Tooltip, Typography } from "@mui/joy";
import { Fragment } from "react";
import { DataRoomParticipantAvatarGroup, NodeTooltip } from "components";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";

interface DataNodeTileProps {
  dataNode: DataRoomData;
  renderDataActions?: (dataNode: DataRoomData) => React.ReactNode;
}

const DataNodeTile: React.FC<DataNodeTileProps> = ({
  dataNode,
  renderDataActions,
}) => {
  const { id: dataNodeId } = dataNode;
  const { withUploading, withDataDeletion, withDatasetStatus, readOnly } =
    useDataNodeConstructorParams();
  return (
    <Fragment>
      <Stack alignItems="center" direction="row" sx={{ flex: 1, order: 2 }}>
        <NodeTooltip nodeType="data" value={dataNodeId}>
          {dataNode.name}
        </NodeTooltip>
      </Stack>
      <Stack alignItems="center" direction="row" sx={{ order: 3 }}>
        {dataNode.dataType === "table" && (
          <Typography
            fontWeight={400}
            level="body-md"
            noWrap={true}
            startDecorator={
              (dataNode as DataRoomDataTable).columns.length === 0 ? (
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faTriangleExclamation}
                  style={{
                    color: "var(--joy-palette-warning-500)",
                    marginRight: 4,
                  }}
                />
              ) : (
                <FontAwesomeIcon icon={faColumns3} />
              )
            }
          >
            {(dataNode as DataRoomDataTable).columns.length} column
            {(dataNode as DataRoomDataTable).columns.length !== 1 ? "s" : ""}
          </Typography>
        )}
        <DataRoomParticipantAvatarGroup
          participants={dataNode.participants.map(
            ({ userEmail: name, uploadedAt }) => ({
              active: !!uploadedAt && withDatasetStatus,
              name,
            })
          )}
        />
        {readOnly &&
        !withUploading &&
        !withDataDeletion &&
        !dataNode.participants.length ? (
          <Tooltip
            placement="top-start"
            title={`There are no Data Owners with permissions to provision datasets to this ${
              dataNode.dataType === "table" ? "table" : "file"
            }`}
          >
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faLock}
              style={{
                color: "var(--joy-palette-neutral-500)",
                margin: "0 .5rem",
              }}
            />
          </Tooltip>
        ) : null}
        {/* Temporary hiding this as we have only one dataset for table */}
        {/* withStatus && (
          <Typography
            style={{ lineHeight: "32px", marginLeft: 4 }}
            level="body-sm"
          >
            {dataUploadingStatus(table.participants)}
          </Typography>
        )*/}
        {(withUploading || withDataDeletion) && renderDataActions
          ? renderDataActions(dataNode)
          : null}
      </Stack>
    </Fragment>
  );
};

export default DataNodeTile;
