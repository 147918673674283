import {
  useCreateDraftDataRoomMutation,
  useDraftDataRoomDuplicateMutation,
} from "@decentriq/graphql/dist/hooks";
import set from "lodash/set";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useApiCore } from "contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { exportDataScienceDataRoom, getDcrProperties } from "utils/apicore";

interface CreateDataRoomMutationWrapperHookPayload {
  onCompleted?: Function;
  onError?: Function;
}

const useCreateDataRoomMutationWrapper = ({
  onCompleted,
  onError,
}: CreateDataRoomMutationWrapperHookPayload) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  return useCreateDraftDataRoomMutation({
    onCompleted: ({ draftDataRoom }) => {
      onCompleted?.();
      const dataRoomId = draftDataRoom?.create?.record?.id;
      if (dataRoomId) {
        navigate(`/datarooms/d/${dataRoomId}`);
      }
      enqueueSnackbar("New data clean room created");
    },
    onError: (error) => {
      onError?.();
      enqueueSnackbar("Draft data clean room could not be created.", {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });
};

const useImportDataRoomMutationWrapper = ({
  onCompleted,
  onError,
}: CreateDataRoomMutationWrapperHookPayload) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  // TODO: figure out mutation names and colocation
  return useDraftDataRoomDuplicateMutation({
    onCompleted: ({ draftDataRoom }) => {
      onCompleted?.();
      const dataRoomId = draftDataRoom?.importDataScienceDataRoom?.record?.id;
      if (dataRoomId) {
        navigate(`/datarooms/d/${dataRoomId}`);
      }
      enqueueSnackbar("New data clean room created");
    },
    onError: (error) => {
      onError?.();
      enqueueSnackbar("Draft data clean room could not be created.", {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });
};

const useCreateDataRoom = (
  currentUserEmail: string,
  mutationParams?: CreateDataRoomMutationWrapperHookPayload
) => {
  const { sessionManager } = useApiCore();
  const [createDataRoomMutation, { loading: isCreateDataRoomLoading }] =
    useCreateDataRoomMutationWrapper({
      onCompleted: mutationParams?.onCompleted,
      onError: mutationParams?.onError,
    });
  const [importDataRoomMutation, { loading: isDraftDataRoomDuplicateLoading }] =
    useImportDataRoomMutationWrapper({
      onCompleted: mutationParams?.onCompleted,
      onError: mutationParams?.onError,
    });
  const createDataRoom = useCallback(
    async (
      {
        name,
        // TODO: deprecate `description` as it is not being used
        description,
        configuration,
      }: {
        name: string;
        description?: string;
        configuration?: any;
      },
      onComplete: () => void
    ) => {
      const dataRoomId = uuidv4();
      if (configuration) {
        const sdkSession = await sessionManager.get();
        const dcrExport = exportDataScienceDataRoom(
          sdkSession.compiler,
          configuration?.dataScienceDataRoom
        );
        const dcrProperties = getDcrProperties(dcrExport.dataScienceDataRoom);
        set(
          dcrExport,
          dcrProperties.isInteractive
            ? `dataScienceDataRoom.${dcrProperties.version}.interactive.initialConfiguration.title`
            : `dataScienceDataRoom.${dcrProperties.version}.static.title`,
          name
        );
        return importDataRoomMutation({
          variables: { input: { export: dcrExport } },
        }).then(onComplete);
      } else {
        return createDataRoomMutation({
          optimisticResponse: {
            draftDataRoom: {
              create: {
                record: {
                  __typename: "DraftDataRoom",
                  computeNodesOrder: [],
                  createdAt: new Date().toISOString(),
                  dataNodesOrder: [],
                  description: description || "",
                  draftNodes: {
                    __typename: "DraftNodeCollection",
                    nodes: [],
                  },
                  editors: {
                    __typename: "DraftEditorCollection",
                    nodes: [],
                  },
                  enableDevelopment: true,
                  enableInteractivity: true,
                  id: dataRoomId,
                  logo: null,
                  owner: {
                    __typename: "User",
                    email: currentUserEmail,
                    id: uuidv4(),
                  },
                  participants: {
                    __typename: "DraftParticipantCollection",
                    nodes: [],
                  },
                  title: name,
                  userSettings: null,
                },
              },
            },
          },
          variables: {
            input: {
              description: description || "",
              draftParticipants: [currentUserEmail],
              enableDevelopment: true,
              enableInteractivity: true,
              title: name,
            },
          },
        }).then(onComplete);
      }
    },
    [
      importDataRoomMutation,
      createDataRoomMutation,
      currentUserEmail,
      sessionManager,
    ]
  );
  return {
    createDataRoom,
    isCreateDataRoomLoading:
      isCreateDataRoomLoading || isDraftDataRoomDuplicateLoading,
  };
};

export default useCreateDataRoom;
