import { DqLoader, DqTable } from "@decentriq/components";
import { useOrganizationDataRoomsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type OrganizationDataRoomFilter,
  type OrganizationDataRoomsQuery,
} from "@decentriq/graphql/dist/types";
import { faCheck, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip } from "@mui/joy";
import { format } from "date-fns";
import { isNil, omitBy } from "lodash";
import { type MRT_ColumnDef } from "material-react-table";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import {
  AdminMonthPicker,
  DataRoomDrawer,
  DataRoomDrawerVariant,
} from "components";
import { useUserRole } from "hooks";
import { dataRoomKindPresentation, type DataRoomTypeNames } from "models";
import CollaboratingOrganizationSelect from "./CollaboratingOrganizationSelect/CollaboratingOrganizationSelect";

type OrganizationDataRoomsListProps = {
  organizationId: string;
};

type OrganizationDataRoomsListItem = Omit<
  OrganizationDataRoomsQuery["organization"]["participatingDataRooms"]["nodes"][number],
  "__typename"
>;

const OrganizationDataRoomsList: React.FC<OrganizationDataRoomsListProps> = ({
  organizationId,
}) => {
  const [filter, setQueryFilter] = useState<OrganizationDataRoomFilter>({});
  const { isDecentriqAdmin } = useUserRole();
  const handleSetQueryFilter = useCallback(
    (filter: OrganizationDataRoomFilter) => {
      setQueryFilter((existingFilter) =>
        omitBy({ ...existingFilter, ...filter }, isNil)
      );
    },
    []
  );
  const [selectedDataRoom, selectDataRoom] = useState<
    | OrganizationDataRoomsQuery["organization"]["participatingDataRooms"]["nodes"][number]
    | null
  >(null);
  const { data, loading } = useOrganizationDataRoomsQuery({
    variables: { filter, organizationId },
  });
  const organizationDataRooms = useMemo(
    () => data?.organization?.participatingDataRooms?.nodes || [],
    [data]
  );
  const organizationDataRoomsColumns = useMemo<
    MRT_ColumnDef<OrganizationDataRoomsListItem>[]
  >(
    () => [
      {
        accessorKey: "title",
        header: "Name",
        id: "title",
        maxSize: 200,
        minSize: 100,
      },
      ...(isDecentriqAdmin
        ? [
            {
              // TODO: fix this error as a part of the old MDCR's query deprecation
              accessorFn: (row: OrganizationDataRoomsListItem) =>
                dataRoomKindPresentation[row.kind],
              header: "Kind",
              id: "kind",
              maxSize: 100,
            },
          ]
        : []),
      {
        Cell: ({ cell }) => {
          const createdAt = cell.getValue();
          return createdAt
            ? format(new Date(createdAt as string), "dd.MM.yyy, HH:mm")
            : "—";
        },
        accessorKey: "createdAt",
        header: "Published at",
        id: "createdAt",
        maxSize: 130,
      },
      {
        accessorFn: (row) => row.owner?.email || "—",
        accessorKey: "owner.email",
        header: "DCR Creator",
        id: "owner",
        minSize: 250,
      },
      {
        accessorFn: (row) => row.owner?.organization?.name || "—",
        header: "DCR Creator organization",
        id: "createdByOrganization",
      },
      {
        Cell: ({ cell }) =>
          cell.getValue<boolean>() ? (
            <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
          ) : null,
        Header: ({ column }) => (
          <div>
            {column.columnDef.header}
            <span
              style={{
                lineHeight: 1,
                verticalAlign: "middle",
                whiteSpace: "nowrap",
              }}
            >
              &nbsp;
              <Tooltip title="A data clean room is activated, once either data has been provisioned or a computation has been run by an external user (i.e. from an organisation that is not the DCR Creator’s).">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
          </div>
        ),
        accessorKey: "isActive",
        header: "Is Active",
        id: "isActive",
        maxSize: 110,
      },
      {
        Cell: ({ cell }) => {
          const deactivatedAt = cell.getValue();
          return deactivatedAt
            ? format(new Date(deactivatedAt as string), "dd.MM.yyy, HH:mm")
            : "—";
        },
        accessorKey: "deactivatedAt",
        header: "Deactivated at",
        id: "deactivatedAt",
      },
    ],
    [isDecentriqAdmin]
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          padding: "2.5rem",
          width: "100%",
        }}
      >
        <DqLoader size="lg" thickness={4} />
      </Box>
    );
  }

  return (
    <Fragment>
      <DqTable
        columns={organizationDataRoomsColumns}
        data={organizationDataRooms}
        enableGlobalFilter={true}
        enableSorting={true}
        enableTopToolbar={true}
        initialState={{
          showGlobalFilter: true,
          sorting: [{ desc: true, id: "createdAt" }],
        }}
        localization={{
          noRecordsToDisplay: "No data clean rooms found",
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search data clean rooms",
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => selectDataRoom(row.original),
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": { pr: 2 },
            cursor: "pointer",
          },
        })}
        muiTableHeadRowProps={{
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": {
              "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                right: "-1rem",
              },
              pr: 2,
            },
          },
        }}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          },
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Stack
              alignItems="center"
              alignSelf="center"
              direction="row"
              spacing={2}
              sx={{ ml: 1 }}
            >
              <AdminMonthPicker setQueryFilter={handleSetQueryFilter} />
              <CollaboratingOrganizationSelect
                organizationId={organizationId}
                setQueryFilter={handleSetQueryFilter}
              />
            </Stack>
          );
        }}
      />
      <DataRoomDrawer
        __typename={selectedDataRoom?.__typename as DataRoomTypeNames}
        dataRoomId={selectedDataRoom?.id}
        onClose={() => selectDataRoom(null)}
        open={Boolean(selectedDataRoom?.id)}
        variant={DataRoomDrawerVariant.Admin}
      />
    </Fragment>
  );
};

OrganizationDataRoomsList.displayName = "OrganizationDataRoomsList";

export default memo(OrganizationDataRoomsList);
