import { DqEmptyData, DqLoader } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { Box, Stack, styled } from "@mui/joy";
import { memo } from "react";
import { DeleteDisplayConfigurationButton } from "features/mediaPortalShared";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import { CommonSnackbarOrigin } from "hooks";
import PublisherDisplayConfigurationPresentation from "../PublisherDisplayConfigurationPresentation/PublisherDisplayConfigurationPresentation";
import UpdatePublisherDisplayConfigurationButton from "../UpdatePublisherDisplayConfigurationButton/UpdatePublisherDisplayConfigurationButton";

const StyledCenteringBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
});

const PublisherDisplayConfiguration = memo(() => {
  const {
    publisherDisplayConfiguration,
    isPublisherDisplayConfigurationLoading,
    deletePublisherDisplayConfiguration,
  } = usePublisherPortal();
  if (isPublisherDisplayConfigurationLoading) {
    return (
      <StyledCenteringBox>
        <DqLoader />
      </StyledCenteringBox>
    );
  }
  if (publisherDisplayConfiguration === null) {
    return (
      <DqEmptyData title="No publisher configuration available. Please create one." />
    );
  }
  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <PublisherDisplayConfigurationPresentation />
      <Stack direction="row" justifyContent="flex-end">
        <DeleteDisplayConfigurationButton
          confirmationTestId={
            testIds.publisherPortal.displayConfiguration
              .deleteDialogConfirmationButton
          }
          deleteConfiguration={deletePublisherDisplayConfiguration}
          dialogDescription="This will also delete all DCR configurations. This cannot be undone."
          dialogTitle="Are you sure you want to delete this display configuration?"
          displayConfiguration={publisherDisplayConfiguration}
          errorMessage="Cannot delete display configuration"
          snackbarOrigin={CommonSnackbarOrigin.PUBLISHER_PORTAL}
          testId={testIds.publisherPortal.displayConfiguration.deleteButton}
        />
        <UpdatePublisherDisplayConfigurationButton />
      </Stack>
    </Stack>
  );
});
PublisherDisplayConfiguration.displayName = "PublisherDisplayConfiguration";

export default PublisherDisplayConfiguration;
