import { Box } from "@mui/joy";
import { forwardRef } from "react";

interface LabelProps {
  label: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const Label = forwardRef<HTMLDivElement, LabelProps>(
  ({ label, prefix, suffix, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        {...rest}
        sx={(theme) => ({
          "&:not(:only-child)": {
            marginRight: theme.spacing(0.5),
          },
          border: "1px solid",
          borderRadius: "2px",
          color: theme.palette.text.secondary,
          cursor: "default",
          fontSize: theme.fontSize.sm,
          lineHeight: 1,
          padding: "0.111em 0.222em",
          position: "relative",
        })}
      >
        {prefix}
        {label}
        {suffix}
      </Box>
    );
  }
);

export default Label;
