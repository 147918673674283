import { type PublishMediaDataRoomInput } from "@decentriq/types";
import { type media_insights_request } from "ddc";
import * as uuid from "uuid";
import { useApiCore } from "contexts";
import {
  calculateAttestationHash,
  retrieveLatestMediaEnclaveSpecifications,
} from "wrappers/ApolloWrapper/resolvers/media";

interface PublishMediaInsightsDcrResponse {
  id: string;
  driverAttestationHash: string;
}

const usePublishMediaInsightsDcr = () => {
  const { client: apiCoreClient, sessionManager } = useApiCore();
  const publish = async (
    input: PublishMediaDataRoomInput
  ): Promise<PublishMediaInsightsDcrResponse> => {
    const enclaveSpecifications =
      await retrieveLatestMediaEnclaveSpecifications(
        apiCoreClient,
        sessionManager.includeMrsigner
      );
    const driverAttestationHash = calculateAttestationHash(
      enclaveSpecifications.driverEnclaveSpecification
    );

    const [authenticationRootCertificatePem, session] = await Promise.all([
      apiCoreClient.decentriqCaRootCertificate(),
      sessionManager.get({ driverAttestationHash }),
    ]);
    const dataRoom = session.compiler.mediaInsights.create({
      v5: {
        advertiserEmails: input.advertiserEmails,
        agencyEmails: input.agencyEmails,
        authenticationRootCertificatePem: new TextDecoder().decode(
          authenticationRootCertificatePem
        ),
        dataPartnerEmails: input.dataPartnerEmails,
        driverEnclaveSpecification:
          enclaveSpecifications.driverEnclaveSpecification,
        enableAdvertiserAudienceDownload:
          input?.enableAdvertiserAudienceDownload,
        enableDataPartner: input.dataPartnerEmails.length > 0,
        enableDebugMode: false,
        enableExclusionTargeting: input?.enableExclusionTargeting,
        enableInsights: input?.enableInsights,
        enableLookalike: input?.enableLookalike,
        enableRetargeting: input?.enableRemarketing,
        hashMatchingIdWith: input.matchingIdHashingAlgorithm,
        hideAbsoluteValuesFromInsights: input?.hideAbsoluteValuesFromInsights,
        id: uuid.v4(),
        mainAdvertiserEmail: input.mainAdvertiserEmail,
        mainPublisherEmail: input.mainPublisherEmail,
        matchingIdFormat: input.matchingIdFormat,
        modelEvaluation: {
          postScopeMerge: ["ROC_CURVE"],
          preScopeMerge: [],
        },
        name: input.name,
        observerEmails: input.observerEmails,
        publisherEmails: input.publisherEmails,
        pythonEnclaveSpecification:
          enclaveSpecifications.pythonEnclaveSpecification,
      },
    });
    const request: media_insights_request.MediaInsightsRequest = {
      publishDataRoom: {
        dataRoom,
        requirePassword: false,
        showOrganizationLogo: false,
      },
    };
    const resp = await session.sendMediaInsightsRequest(request);
    if (!("publishDataRoom" in resp)) {
      throw new Error("Expected publishDataRoom response");
    }
    return {
      driverAttestationHash,
      id: resp?.publishDataRoom.dataRoomId,
    };
  };
  return {
    publish,
  };
};

export default usePublishMediaInsightsDcr;
