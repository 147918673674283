import { z } from "zod";

// TODO: Must indicate where the duplicate email is?
export const schema = z.object({
  advertiserUserEmails: z
    .array(z.string().email("Email is invalid"))
    .min(1, "At least one email is required")
    .superRefine((items, ctx) => {
      const uniqueValues = new Map<string, number>();
      items.forEach((item, idx) => {
        const firstAppearanceIndex = uniqueValues.get(item);
        if (firstAppearanceIndex !== undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Email is not unique`,
            path: [idx],
          });
          return;
        }
        uniqueValues.set(item, idx);
      });
    }),
  dataRoomName: z
    .string({ required_error: "Name is required" })
    .min(1, "Name is required")
    .max(800, "Data room name must be no longer than 800 characters"),
  measurementConfiguration: z.object({
    attributionRule: z.enum(["last_click", "even_credit", "first_last"], {
      required_error: "Attribution Rule is required",
    }),
    clickLookbackWindow: z.number({
      required_error: "Click Lookback Window is required",
    }),
    viewLookbackWindow: z.number({
      required_error: "View Lookback Window is required",
    }),
  }),
  publishers: z
    .array(
      z.object({
        emails: z
          .array(z.string().email("Email is invalid"))
          .min(1, "At least one email is required")
          .superRefine((items, ctx) => {
            const uniqueValues = new Map<string, number>();
            items.forEach((item, idx) => {
              const firstAppearanceIndex = uniqueValues.get(item);
              if (firstAppearanceIndex !== undefined) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Email is not unique`,
                  path: [idx],
                });
                return;
              }
              uniqueValues.set(item, idx);
            });
          }),
        name: z.string({ required_error: "Publisher name is required" }),
      })
    )
    .min(1, "At least one publisher is required")
    .max(5, "At most five publishers are allowed"),
});

export type SchemaType = z.infer<typeof schema>;

export const defaultValues: SchemaType = {
  advertiserUserEmails: [],
  dataRoomName: "",
  measurementConfiguration: {
    attributionRule: "last_click",
    clickLookbackWindow: 1,
    viewLookbackWindow: 0,
  },
  publishers: [],
};
