import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
  type ModalProps,
} from "@mui/joy";
import { memo } from "react";
import DqCreateMeasurementDcrActions from "./DqCreateMeasurementDcrActions/DqCreateMeasurementDcrActions";
import DqCreateMeasurementDcrForm, {
  type DqCreateMeasurementDcrFormProps,
} from "./DqCreateMeasurementDcrForm/DqCreateMeasurementDcrForm";
import DqCreateMeasurementDcrFormControls from "./DqCreateMeasurementDcrFormControls/DqCreateMeasurementDcrFormControls";
import DqCreateMeasurementDcrFormProvider from "./DqCreateMeasurementDcrFormProvider/DqCreateMeasurementDcrFormProvider";
import DqCreateMeasurementDcrModal from "./DqCreateMeasurementDcrModal/DqCreateMeasurementDcrModal";

const FORM_ID = "create-measurement-dcr-form";

export type MeasurementDcrCreateDialogProps = {
  restartCreation: () => void;
} & Omit<ModalProps, "children" | "onSubmit"> &
  Pick<DqCreateMeasurementDcrFormProps, "onSubmit">;

const MeasurementDcrCreateDialog = memo<MeasurementDcrCreateDialogProps>(
  ({ open, onClose, onSubmit, restartCreation, ...props }) => {
    return open ? (
      <DqCreateMeasurementDcrFormProvider>
        <DqCreateMeasurementDcrModal onClose={onClose} open={open} {...props}>
          <DqCreateMeasurementDcrForm id={FORM_ID} onSubmit={onSubmit}>
            <ModalDialog>
              <DialogTitle>Create new measurement clean room</DialogTitle>
              <Divider />
              <DialogContent>
                <DqCreateMeasurementDcrFormControls />
              </DialogContent>
              <Divider />
              <DialogActions>
                <DqCreateMeasurementDcrActions
                  form={FORM_ID}
                  resetButtonProps={{
                    children: "Back",
                    onClick: (event) => restartCreation(),
                  }}
                />
              </DialogActions>
            </ModalDialog>
          </DqCreateMeasurementDcrForm>
        </DqCreateMeasurementDcrModal>
      </DqCreateMeasurementDcrFormProvider>
    ) : null;
  }
);
MeasurementDcrCreateDialog.displayName = "MeasurementDcrCreateDialog";

export default MeasurementDcrCreateDialog;
