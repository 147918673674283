import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/joy";
import { Fragment, memo } from "react";

const OrganizationLicenseTypesTooltip = memo(() => {
  return (
    <Tooltip
      title={
        <Fragment>
          License status:
          <ul style={{ margin: 0, paddingInlineStart: "24px" }}>
            <li>
              <strong>Subscribed</strong>: All features.
            </li>
            <li>
              <strong>Suspended</strong>: <strong>Passive</strong> +{" "}
              <strong>Admin Portal</strong> access.
            </li>
            <li>
              <strong>Trial</strong>: All features. No{" "}
              <strong>Admin Portal</strong> access.
            </li>
            <li>
              <strong>Passive</strong>: Only DCR participation, can’t create. No{" "}
              <strong>Admin Portal</strong>.
            </li>
            <li>
              <strong>Archived</strong>: All users deleted. For archive purposes
              only.
            </li>
          </ul>
        </Fragment>
      }
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </Tooltip>
  );
});
OrganizationLicenseTypesTooltip.displayName = "OrganizationLicenseTypesTooltip";

export default OrganizationLicenseTypesTooltip;
