import { DqCard, DqCardContent } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { memo } from "react";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";
import { DatasetsInformation } from "features/datasets";

const DataRoomDatasets = memo(() => {
  const { dataRoomOverviewData, loading } = useDataRoomDrawerContext();
  const dataRoomDatasets = (
    dataRoomOverviewData?.datasetPublications?.nodes || []
  ).map(({ dataset }) => dataset);
  return (
    <DqCard>
      <DqCardContent alignItems="flex-start">
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Datasets provisioned</strong>
        </Typography>
        <DatasetsInformation
          datasets={dataRoomDatasets}
          emptyText="No datasets provisioned to this data room yet"
          loading={loading}
        />
      </DqCardContent>
    </DqCard>
  );
});

DataRoomDatasets.displayName = "DataRoomDatasets";

export default DataRoomDatasets;
