import {
  useAdvertiserMarketFiltersQuery,
  useAvailableDataPartnersQuery,
  useUpdateAdvertiserMarketFiltersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type AvailableDataPartner,
  type AvailableDataPartnerFragment,
} from "@decentriq/graphql/dist/types";
import { useUpdateEffect } from "@decentriq/hooks";
import { MediaDataRoomOrganizationRole } from "@decentriq/types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  CreationWizardDataPartnerContextProvider,
  type CreationWizardDataPartnerContextValue,
  useCreationWizardStepper,
} from "../../contexts";
import { MediaDataRoomCreationStep } from "../../types";

const DataPartnerWrapper = memo<React.PropsWithChildren>(({ children }) => {
  const {
    organizationRole,
    activeStep,
    hasOwnDataToProvide,
    canSelectDataPartner,
  } = useCreationWizardStepper();
  const [selectedDataPartner, setSelectedDataPartner] =
    useState<AvailableDataPartnerFragment | null>(null);
  const [hasSkippedSelection, setHasSkippedSelection] =
    useState<boolean>(false);
  const hasSelectedDataPartner = Boolean(selectedDataPartner);
  const [updateAdvertiserMarketFiltersMutation] =
    useUpdateAdvertiserMarketFiltersMutation();
  const { data: advertiserMarketFiltersData } =
    useAdvertiserMarketFiltersQuery();
  const updateAdvertiserMarketFilters = useCallback(
    (marketIds: string[]) => {
      updateAdvertiserMarketFiltersMutation({
        optimisticResponse: {
          availablePublisher: {
            updateMarketFilters: marketIds,
          },
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              advertiserMarketFilters: () =>
                data?.availablePublisher.updateMarketFilters || [],
            },
          });
        },
        variables: {
          input: marketIds,
        },
      });
    },
    [updateAdvertiserMarketFiltersMutation]
  );
  const {
    data: availableDataPartnersQueryData,
    loading: isAvailableDataPartnersLoading,
  } = useAvailableDataPartnersQuery();
  const allAvailableDataPartners = useMemo<AvailableDataPartner[]>(
    () => availableDataPartnersQueryData?.availableDataPartners?.nodes || [],
    [availableDataPartnersQueryData?.availableDataPartners?.nodes]
  );
  const advertiserMarketFilters = useMemo(() => {
    const dataPartnerMarkets =
      advertiserMarketFiltersData?.publisherMarkets?.nodes || [];
    const activeFilters =
      advertiserMarketFiltersData?.advertiserMarketFilters || [];
    return dataPartnerMarkets
      .filter(({ id }) =>
        allAvailableDataPartners.some(({ marketIds }) => marketIds.includes(id))
      )
      .map((market) => ({
        ...market,
        selected: activeFilters.includes(market.id),
      }));
  }, [advertiserMarketFiltersData, allAvailableDataPartners]);
  const filteredAvailableDataPartners = useMemo<AvailableDataPartner[]>(() => {
    const selectedMarketFilters = advertiserMarketFilters.filter(
      ({ selected }) => selected
    );
    const hasAnyFilterSelected = selectedMarketFilters.length > 0;
    if (!hasAnyFilterSelected) {
      return allAvailableDataPartners;
    }
    return allAvailableDataPartners.filter(({ marketIds }) =>
      selectedMarketFilters.some(({ id }) => marketIds.includes(id))
    );
  }, [allAvailableDataPartners, advertiserMarketFilters]);
  const inviteDataPartner = useCallback(
    () => setHasSkippedSelection(true),
    [setHasSkippedSelection]
  );
  const shouldKeepSelectedDataPartner =
    [
      MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER,
      MediaDataRoomCreationStep.SELECT_DATA_PARTNER,
      MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION,
    ].includes(activeStep) &&
    organizationRole === MediaDataRoomOrganizationRole.ADVERTISER;
  useEffect(() => {
    if (
      !filteredAvailableDataPartners.length ||
      !shouldKeepSelectedDataPartner ||
      hasSkippedSelection
    ) {
      setSelectedDataPartner(null);
      return;
    }
    setSelectedDataPartner((currentSelectedDataPartner) => {
      if (
        filteredAvailableDataPartners.some(
          ({ id }) => id === currentSelectedDataPartner?.id
        )
      ) {
        return currentSelectedDataPartner;
      }
      return filteredAvailableDataPartners[0];
    });
  }, [
    setSelectedDataPartner,
    organizationRole,
    hasSkippedSelection,
    shouldKeepSelectedDataPartner,
    filteredAvailableDataPartners,
  ]);
  const isSelectionStep =
    activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER;
  useUpdateEffect(() => {
    if (isSelectionStep) {
      setHasSkippedSelection(false);
    }
  }, [isSelectionStep, setHasSkippedSelection]);
  useEffect(() => {
    setHasSkippedSelection(!canSelectDataPartner || hasOwnDataToProvide);
  }, [setHasSkippedSelection, canSelectDataPartner, hasOwnDataToProvide]);
  const contextValue = useMemo<CreationWizardDataPartnerContextValue>(
    () => ({
      advertiserMarketFilters,
      availableDataPartners: filteredAvailableDataPartners,
      hasSelectedDataPartner,
      hasSkippedSelection,
      inviteDataPartner,
      isAvailableDataPartnersLoading,
      selectDataPartner: setSelectedDataPartner,
      selectedDataPartner,
      updateAdvertiserMarketFilters,
    }),
    [
      advertiserMarketFilters,
      filteredAvailableDataPartners,
      hasSelectedDataPartner,
      hasSkippedSelection,
      inviteDataPartner,
      isAvailableDataPartnersLoading,
      selectedDataPartner,
      setSelectedDataPartner,
      updateAdvertiserMarketFilters,
    ]
  );
  return (
    <CreationWizardDataPartnerContextProvider value={contextValue}>
      {children}
    </CreationWizardDataPartnerContextProvider>
  );
});
DataPartnerWrapper.displayName = "DataPartnerWrapper";

export default DataPartnerWrapper;
