import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/joy";
import { memo } from "react";
import CreatePublisherDisplayConfigurationButton from "features/publisherPortal/components/PublisherConfigurations/components/CreatePublisherDisplayConfigurationButton/CreatePublisherDisplayConfigurationButton";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import {
  ActivationConfigurationsTable,
  CreateActivationConfigurationButton,
  PublisherDisplayConfiguration,
} from "./components";

const PublisherConfigurations = memo(() => {
  const { publisherDisplayConfiguration } = usePublisherPortal();
  return (
    <Stack
      direction="column"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="title-md">
          Display configuration
          <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
            &nbsp;
            <Tooltip title="Setting up the display configuration lets you be discovered by advertisers in the Decentriq platform. They can contact you in their platform view via email.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </span>
        </Typography>
        {publisherDisplayConfiguration === null ? (
          <CreatePublisherDisplayConfigurationButton />
        ) : null}
      </Stack>
      <Box sx={{ height: "100%", pb: 2, px: 2 }}>
        <PublisherDisplayConfiguration />
      </Box>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="title-md">
          DCR configurations
          <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
            &nbsp;
            <Tooltip title="Setting up a DCR configuration lets you define the details of a data clean room for specific advertisers. This allows these advertiser to create preconfigured data clean rooms with you.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </span>
        </Typography>
        <CreateActivationConfigurationButton />
      </Stack>
      <Box sx={{ height: "100%", pb: 2, px: 2 }}>
        <ActivationConfigurationsTable />
      </Box>
    </Stack>
  );
});
PublisherConfigurations.displayName = "PublisherConfigurations";

export default PublisherConfigurations;
