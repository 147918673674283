import { useAuth0 } from "@auth0/auth0-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { defaultValues, schema, type SchemaType } from "./model";

interface DqCreateMeasurementDcrFormProviderProps {
  children: React.ReactNode;
}

const DqCreateMeasurementDcrFormProvider: React.FC<DqCreateMeasurementDcrFormProviderProps> =
  memo(({ children }) => {
    const { user: currentUser } = useAuth0();
    const { email: currentUserEmail } = currentUser || {};
    const [disabled, setDisabled] = useState(false);
    const form = useForm<SchemaType>({
      context: { currentUserEmail },
      defaultValues: {
        ...defaultValues,
        advertiserUserEmails: currentUserEmail ? [currentUserEmail] : [],
      },
      disabled,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: async (data, context, options) =>
        await zodResolver(
          schema.extend({
            advertiserUserEmails: schema.shape.advertiserUserEmails.superRefine(
              (items, ctx) => {
                if (
                  context.currentUserEmail &&
                  !items.includes(context.currentUserEmail)
                ) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `You must also be a participant of this data clean room.`,
                  });
                  return;
                }
              }
            ),
          })
        )(data, context, {
          ...options,
          /*
            NOTE: Clearing the names to force the nesting routine
            to use the validation error keys to determine nesting,
            since field arrays are not used and the names are "simple".
          */
          names: undefined,
        }),
    });
    const { formState } = form;
    const { isSubmitting } = formState;
    useEffect(() => {
      setDisabled(isSubmitting);
    }, [isSubmitting]);
    return <FormProvider {...form}>{children}</FormProvider>;
  });
DqCreateMeasurementDcrFormProvider.displayName =
  "DqCreateMeasurementDcrFormProvider";

export default DqCreateMeasurementDcrFormProvider;
