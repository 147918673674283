import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import CreateDataPartnerDisplayConfigurationButton from "../CreateDataPartnerDisplayConfigurationButton/CreateDataPartnerDisplayConfigurationButton";
import CreateDataPartnerUsageConfigurationButton from "../CreateDataPartnerUsageConfigurationButton/CreateDataPartnerUsageConfigurationButton";
import DataPartnerDisplayConfiguration from "../DataPartnerDisplayConfiguration/DataPartnerDisplayConfiguration";
import UsageConfigurationsTable from "../UsageConfigurationsTable/UsageConfigurationsTable";

const DataPartnerConfigurations = memo(() => {
  const { displayConfiguration } = useDataPartnerPortal();
  return (
    <Stack
      direction="column"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="title-md">
          Display configuration
          <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
            &nbsp;
            <Tooltip title="Setting up the display configuration lets you be discovered by advertisers in the Decentriq platform. They can contact you in their platform view via email.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </span>
        </Typography>
        {displayConfiguration === null ? (
          <CreateDataPartnerDisplayConfigurationButton />
        ) : null}
      </Stack>
      <Box sx={{ height: "100%", pb: 2, px: 2 }}>
        <DataPartnerDisplayConfiguration />
      </Box>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="title-md">
          Data usage configurations
          <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
            &nbsp;
            <Tooltip title="Setting up a usage configuration lets you define the details of a data clean room for specific advertisers. This allows these advertiser to create preconfigured data clean rooms with you.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </span>
        </Typography>
        <CreateDataPartnerUsageConfigurationButton />
      </Stack>
      <Box sx={{ height: "100%", pb: 2, px: 2 }}>
        <UsageConfigurationsTable />
      </Box>
    </Stack>
  );
});
DataPartnerConfigurations.displayName = "DataPartnerConfigurations";

export default DataPartnerConfigurations;
