import { mediaDataRoomCollaborationTypes } from "@decentriq/models";
import { MediaDataRoomUserRole } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { faDatabase as fatDatabase } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  ModalDialog,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  ShowMore,
  type ShowMoreRef,
  type ShowMoreToggleLinesFn,
} from "@re-dev/react-truncate";
import { forwardRef, Fragment, memo, useCallback, useRef } from "react";
import {
  InviteParticipantButton,
  MatchingIdChip,
  MoreOrLessButton,
  OrganizationSheet,
  SelectDataPartnerStepMarketFilters,
  SkeletonGrid,
} from "../../components";
import {
  useCreationWizardConfiguration,
  useCreationWizardDataPartner,
  useCreationWizardStepper,
} from "../../contexts";
import { MediaDataRoomCreationStep } from "../../types";

const SelectDataPartnerStep = memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const {
      allowedCollaborationTypes: allowedFeatures,
      canProceedToConfiguration,
      contactButtonEnabled,
      contactButtonTooltip,
    } = useCreationWizardConfiguration();
    const {
      isAvailableDataPartnersLoading = false,
      availableDataPartners = [],
      selectDataPartner,
      selectedDataPartner,
      inviteDataPartner,
    } = useCreationWizardDataPartner();
    const { activeStep, setActiveStep, handleBackStep, handleNextStep } =
      useCreationWizardStepper();
    const handleInviteDataPartner = useCallback(() => {
      inviteDataPartner();
      handleNextStep();
    }, [inviteDataPartner, handleNextStep]);
    const descriptionRef = useRef<ShowMoreRef>(null);
    const toggleLines: ShowMoreToggleLinesFn = (event) => {
      descriptionRef.current?.toggleLines(event);
    };
    return (
      <ModalDialog ref={ref}>
        <DialogTitle>Create new media clean room</DialogTitle>
        <Divider />
        <DialogContent sx={{ flex: "0 0 auto" }}>
          <Stack>
            <Typography level="title-md">Select data partner</Typography>
            <SelectDataPartnerStepMarketFilters />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogContent>
          {isAvailableDataPartnersLoading ? (
            <SkeletonGrid />
          ) : availableDataPartners?.length > 0 ? (
            <Grid container={true} spacing={1}>
              <Grid
                md={4}
                sx={{
                  "& > *": { flex: 1 },
                  alignItems: "stretch",
                  display: "flex",
                  justifyContent: "stretch",
                }}
                xs={6}
              >
                <InviteParticipantButton
                  onClick={handleInviteDataPartner}
                  role={MediaDataRoomUserRole.DataPartner}
                />
              </Grid>
              {availableDataPartners.map((dataPartner) => {
                const { id, dataPartnerLogo, dataPartnerName, marketIds } =
                  dataPartner;
                const isSelected = id === selectedDataPartner?.id;
                return (
                  <Grid key={id} md={4} xs={6}>
                    <OrganizationSheet
                      icon={fatDatabase}
                      ids={marketIds}
                      logo={dataPartnerLogo}
                      name={dataPartnerName}
                    >
                      <Radio
                        checked={isSelected}
                        onChange={() => selectDataPartner(dataPartner)}
                        overlay={true}
                      />
                    </OrganizationSheet>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <InviteParticipantButton
              onClick={handleInviteDataPartner}
              role={MediaDataRoomUserRole.DataPartner}
            />
          )}
        </DialogContent>
        <Divider />
        {selectedDataPartner?.dataPartnerDescription ||
        !!selectedDataPartner ? (
          <DialogContent sx={{ flex: "0 0 auto" }}>
            <Stack>
              {selectedDataPartner?.dataPartnerDescription ? (
                <Fragment>
                  <FormLabel>Description</FormLabel>
                  <Typography component="div">
                    <ShowMore
                      less={
                        <MoreOrLessButton toggleLines={toggleLines}>
                          Collapse
                        </MoreOrLessButton>
                      }
                      lines={1}
                      more={
                        <MoreOrLessButton toggleLines={toggleLines}>
                          Expand
                        </MoreOrLessButton>
                      }
                      ref={descriptionRef}
                    >
                      {selectedDataPartner.dataPartnerDescription}
                    </ShowMore>
                  </Typography>
                </Fragment>
              ) : null}
              {selectedDataPartner ? (
                <Fragment>
                  <FormLabel>
                    <Typography
                      endDecorator={
                        <Tooltip
                          title={`Your data needs to have this matching ID. If you need to use a different matching ID, contact your ${
                            activeStep ===
                            MediaDataRoomCreationStep.SELECT_DATA_PARTNER
                              ? "data partner"
                              : "publisher"
                          }.`}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      }
                      level="inherit"
                    >
                      Matching ID
                    </Typography>
                  </FormLabel>
                  <MatchingIdChip />
                  <FormLabel>
                    <Typography
                      endDecorator={
                        <Tooltip title="If you are interested in a collaboration type which is not yet supported, please contact the data partner using the button to the right.">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      }
                      level="inherit"
                    >
                      Supported collaboration types
                    </Typography>
                  </FormLabel>
                  {allowedFeatures.length === 0 ? (
                    <Alert>
                      The collaboration types are incompatible with the
                      publisher's
                    </Alert>
                  ) : (
                    <Stack direction="row" flexWrap="wrap">
                      {mediaDataRoomCollaborationTypes.map((feature) => (
                        <Chip
                          disabled={!allowedFeatures.includes(feature)}
                          key={feature}
                          size="lg"
                        >
                          {feature}
                        </Chip>
                      ))}
                    </Stack>
                  )}
                </Fragment>
              ) : null}
            </Stack>
          </DialogContent>
        ) : null}
        <Divider />
        <DialogActions>
          <Button onClick={handleBackStep}>Back</Button>
          {!canProceedToConfiguration && contactButtonEnabled ? (
            <Tooltip title={contactButtonTooltip}>
              <div>
                <Button
                  color="primary"
                  data-testid={
                    testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                      .requestCollaborationButton
                  }
                  onClick={() => {
                    setActiveStep(
                      MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER
                    );
                  }}
                  variant="solid"
                >
                  Contact data partner
                </Button>
              </div>
            </Tooltip>
          ) : null}
          {canProceedToConfiguration && (
            <Button
              color="primary"
              data-testid={
                testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                  .submitButton
              }
              onClick={handleNextStep}
              variant="solid"
            >
              Continue
            </Button>
          )}
        </DialogActions>
      </ModalDialog>
    );
  })
);
SelectDataPartnerStep.displayName = "SelectDataPartnerStep";

export default SelectDataPartnerStep;
