import { testIds } from "@decentriq/utils";
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const NameInput: React.FC = memo(() => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="name"
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return (
          <FormControl
            disabled={field.disabled}
            error={Boolean(error)}
            required={true}
          >
            <FormLabel>Name</FormLabel>
            <Input
              data-testid={
                testIds.mediaInsightsDcr.mediaInsightsDcrCreateCollaborationStep
                  .insightDataRoomName
              }
              error={Boolean(error)}
              placeholder={`e.g. "My media clean room"`}
              {...field}
            />
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
});
NameInput.displayName = "NameInput";

export default NameInput;
