import {
  faCircle1,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Grid, ModalDialog, Typography } from "@mui/joy";
import { memo } from "react";

interface DqCreateMediaDcrProgressProps {
  publishingCalled: boolean;
  publishing: boolean;
  publishingError?: unknown;
}

const DqCreateMediaDcrProgress: React.FC<DqCreateMediaDcrProgressProps> = memo(
  ({ publishingCalled, publishing, publishingError }) => {
    return (
      <ModalDialog>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 8,
          }}
        >
          {publishing && (
            <Box sx={{ mb: 2 }}>
              <CircularProgress sx={{ "--CircularProgress-size": "64px" }} />
            </Box>
          )}
          <Box>
            <Grid
              columnSpacing={1}
              container={true}
              rowSpacing={0}
              sx={{ mb: 0.5 }}
            >
              <Grid
                alignItems="center"
                display="flex"
                justifyContent="center"
                xs="auto"
              >
                {!publishingCalled ? (
                  <FontAwesomeIcon fixedWidth={true} icon={faCircle1} />
                ) : publishing ? null : publishingError ? (
                  <FontAwesomeIcon fixedWidth={true} icon={faCircleXmark} />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faCircleCheck} />
                )}
              </Grid>
              <Grid alignItems="center" display="flex" xs={true}>
                <Typography
                  component="div"
                  sx={{
                    lineHeight: "1.5rem",
                    transition: "all 0.2s linear",
                    whiteSpace: "nowrap",
                  }}
                >
                  {publishing
                    ? "Creating media data clean room…"
                    : publishingCalled
                      ? !publishingError
                        ? "Media data clean room was created"
                        : "Can't create media data clean room"
                      : "Create media data clean room"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ModalDialog>
    );
  }
);
DqCreateMediaDcrProgress.displayName = "DqCreateMediaDcrProgress";

export default DqCreateMediaDcrProgress;
