import { sortNumericRange } from "@decentriq/utils";
import { orderBy } from "lodash";
import { type AggregationData } from "features/mediaDataRoom";

const sortAgeAggregationData = (
  data: AggregationData[],
  order?: "asc" | "desc"
): AggregationData[] => {
  const aggregationDataAges: string[] = data.map((item) => item.age!);
  const sortedAges = sortNumericRange(aggregationDataAges);
  return orderBy(
    data,
    (item: AggregationData) => sortedAges.indexOf(item.age!),
    order || "asc"
  );
};

export default sortAgeAggregationData;
