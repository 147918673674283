import { forwardRef, memo } from "react";
import DqCreateMediaDcrFormProvider from "../DqCreateMediaDcrFormProvider/DqCreateMediaDcrFormProvider";
import { useCreationWizardStepper } from "./contexts";
import {
  EditConfigurationStep,
  SelectDataPartnerStep,
  SelectDataSourceStep,
  SelectOrganizationRoleStep,
  SelectPublisherStep,
  SendRequestStep,
} from "./steps";
import { MediaDataRoomCreationStep } from "./types";

const Wizard = memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const { activeStep } = useCreationWizardStepper();
    switch (activeStep) {
      case MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE:
        return <SelectOrganizationRoleStep ref={ref} />;
      case MediaDataRoomCreationStep.SELECT_PUBLISHER:
        return <SelectPublisherStep ref={ref} />;
      case MediaDataRoomCreationStep.SELECT_DATA_SOURCE:
        return <SelectDataSourceStep ref={ref} />;
      case MediaDataRoomCreationStep.SELECT_DATA_PARTNER:
        return <SelectDataPartnerStep ref={ref} />;
      case MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION:
        return (
          <DqCreateMediaDcrFormProvider>
            <EditConfigurationStep ref={ref} />
          </DqCreateMediaDcrFormProvider>
        );
      case MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER:
        return <SendRequestStep ref={ref} />;
      case MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER:
        return <SendRequestStep ref={ref} />;
      default:
        return null;
    }
  })
);
Wizard.displayName = "Wizard";

export default Wizard;
