import { audienceRulesTreeSchema } from "@decentriq/components";
import { Button } from "@mui/joy";
import { memo, type ReactNode, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore } from "contexts";
import {
  useAdvertiserAudiences,
  useDataAttributes,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  mergeMediaDataRoomJobResults,
  useMediaDataRoomLazyJob,
} from "features/mediaDataRoom/hooks";
import {
  ALL_PUBLISHER_USERS_AUDIENCE,
  MediaDataRoomTab,
} from "features/mediaDataRoom/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import {
  AdvertiserAudienceGeneratorEmptyState,
  AdvertiserAudienceGeneratorErrorState,
  AdvertiserAudienceGeneratorLoadingState,
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "../../AdvertiserAudienceGenerator";
import {
  RulesBasedAdvertiserAudienceGeneratorConfigurationStep,
  RulesBasedAdvertiserAudienceGeneratorSummaryStep,
} from "../components";
import {
  type RulesBasedAdvertiserAudienceGeneratorContextValue,
  RulesBasedAdvertiserAudienceGeneratorProvider,
} from "../contexts/RulesBasedAdvertiserAudienceGeneratorContext";
import {
  type RulesBasedAudienceAudienceSizeFileStructure,
  type RulesBasedAudienceRulesTree,
  type RulesBasedAudienceSeedOption,
} from "../models";
import { mapAudienceRulesTreeToRulesBasedAudience } from "../utils";

const RulesBasedAdvertiserAudienceGenerator = memo(() => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { sessionManager } = useApiCore();
  const { dataRoomId, driverAttestationHash, isDeactivated } =
    useMediaDataRoom();
  const { publishedDatasetsHashes, session } = useMediaDataRoomInsightsData();
  const { currentStep } = useAdvertiserAudienceGenerator();
  const [audienceName, setAudienceName] = useState<string>("");
  const [rulesTree, setRulesTree] = useState<
    RulesBasedAudienceRulesTree | undefined
  >(undefined);
  // In order to preserve cache we need to keep ID & creation date constant per generation session
  const audienceId = useMemo(() => crypto.randomUUID(), []);
  const estimatedAudienceCreatedAt = useMemo(
    () => new Date().toISOString(),
    []
  );
  const [estimatedAudienceSize, setEstimatedAudienceSize] = useState<
    number | null
  >(null);
  const [audienceSizeEstimationKey, setAudienceSizeEstimationKey] = useState<
    string | null
  >(null);
  const [generationStep, setGenerationStep] =
    useState<
      RulesBasedAdvertiserAudienceGeneratorContextValue["generationStep"]
    >(null);
  const dataAttributes = useDataAttributes({
    dataRoomId,
    driverAttestationHash,
    publishedDatasetsHashes,
    session,
    skip: false,
  });
  const { audiences, saveAudience } = useAdvertiserAudiences();
  const seedOptions = useMemo<
    MediaDataRoomJobHookResult<RulesBasedAudienceSeedOption[]>
  >(
    () =>
      mergeMediaDataRoomJobResults(
        [audiences, dataAttributes],
        ([audiences, dataAttributes]) => [
          {
            attributes: dataAttributes ?? {},
            id: ALL_PUBLISHER_USERS_AUDIENCE.id,
            name: ALL_PUBLISHER_USERS_AUDIENCE.mutable.name,
          },
          ...(audiences?.map((audience) => ({
            attributes: dataAttributes ?? {},
            id: audience.id,
            name: audience.mutable.name,
          })) || []),
        ]
      ),
    [audiences, dataAttributes]
  );
  const transformEstimatedAudienceSize = useCallback<
    MediaDataRoomJobResultTransform<number>
  >(async (zip) => {
    const audienceSizeFile = zip.file("audience_size.json");
    if (audienceSizeFile === null) {
      throw new Error("audience_size.json not found in zip");
    }
    return (
      JSON.parse(
        await audienceSizeFile.async("string")
      ) as RulesBasedAudienceAudienceSizeFileStructure
    ).audience_size;
  }, []);
  const [estimateAudienceSizeForAdvertiser] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "estimateAudienceSizeForAdvertiser",
      dataRoomId,
      driverAttestationHash
    ).withoutCaching(),
    session,
    transform: transformEstimatedAudienceSize,
  });
  const [estimateAudienceSizeForAdvertiserLal] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "estimateAudienceSizeForAdvertiserLal",
      dataRoomId,
      driverAttestationHash
    ).withoutCaching(),
    session,
    transform: transformEstimatedAudienceSize,
  });
  const estimateAudienceSize = useCallback(async () => {
    if (!rulesTree) {
      return;
    }
    const rulesBasedAudience = mapAudienceRulesTreeToRulesBasedAudience(
      rulesTree,
      {
        createdAt: estimatedAudienceCreatedAt,
        id: audienceId,
      }
    );
    // TODO: this key should be generated in a more reliable way, for now as we dont have caching mechanism for this request we can use this approach
    const sizeEstimationKey = new Date().toISOString();
    try {
      setAudienceSizeEstimationKey(sizeEstimationKey);
      const session = await sessionManager.get({
        driverAttestationHash,
      });
      const payloadParams = session.compiler.abMedia.getParameterPayloads(
        rulesBasedAudience.id,
        [rulesBasedAudience, ...(audiences.computeResults ?? [])]
      );
      let audienceSize: number;
      if (payloadParams.lal) {
        audienceSize = await estimateAudienceSizeForAdvertiserLal({
          requestCreator: (dataRoomIdHex: string, scopeIdHex: string) => ({
            dataRoomIdHex,
            generateAudience: payloadParams.generate,
            lalAudience: payloadParams.lal!,
            scopeIdHex,
          }),
        });
      } else {
        audienceSize = await estimateAudienceSizeForAdvertiser({
          requestCreator: (dataRoomIdHex: string, scopeIdHex: string) => ({
            dataRoomIdHex,
            generateAudience: payloadParams.generate,
            scopeIdHex,
          }),
        });
      }
      setAudienceSizeEstimationKey((currentKey) => {
        if (currentKey === sizeEstimationKey) {
          setEstimatedAudienceSize(audienceSize);
          return null;
        }
        return currentKey;
      });
    } catch (error) {
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          "Failed to estimate audience size"
        )
      );
    } finally {
      setAudienceSizeEstimationKey((currentKey) => {
        if (currentKey === sizeEstimationKey) {
          return null;
        }
        return currentKey;
      });
    }
  }, [
    setAudienceSizeEstimationKey,
    setEstimatedAudienceSize,
    estimateAudienceSizeForAdvertiser,
    estimateAudienceSizeForAdvertiserLal,
    enqueueSnackbar,
    sessionManager,
    driverAttestationHash,
    rulesTree,
    estimatedAudienceCreatedAt,
    audienceId,
    audiences.computeResults,
  ]);
  const onRulesTreeChanged = useCallback(
    (rulesTree: RulesBasedAudienceRulesTree) => {
      setEstimatedAudienceSize(null);
      setAudienceSizeEstimationKey(null);
      setRulesTree(rulesTree);
    },
    [setEstimatedAudienceSize, setRulesTree]
  );
  const validateRulesTree = useCallback(() => {
    const { success: isRulesTreeValid } = audienceRulesTreeSchema.safeParse({
      ...rulesTree,
      id: audienceId,
    });
    if (!isRulesTreeValid) {
      enqueueSnackbar(
        "Please fill the all necessary attributes, conditions and values to proceed",
        { persist: true, variant: "info" }
      );
    }
    return isRulesTreeValid;
  }, [rulesTree, enqueueSnackbar, audienceId]);
  const generateAudience = useCallback(async () => {
    try {
      if (!validateRulesTree()) {
        return;
      }
      if (!audienceName.trim().length) {
        enqueueSnackbar("Please provide a name for the audience", {
          persist: true,
          variant: "info",
        });
        return;
      }
      setGenerationStep("pending");
      const rulesBasedAudience = mapAudienceRulesTreeToRulesBasedAudience(
        rulesTree,
        { id: audienceId, name: audienceName }
      );
      await saveAudience(rulesBasedAudience);
      setGenerationStep("completed");
      enqueueSnackbar("Audiences successfully updated", { variant: "success" });
      setTimeout(() => {
        const dataRoomBasePath = dataRoomPathBuilder(
          dataRoomId,
          DataRoomTypeNames.PublishedMediaInsightsDcr
        );
        navigate(`${dataRoomBasePath}/${MediaDataRoomTab.activation}`);
      }, 1200);
    } catch (error) {
      setGenerationStep(null);
      enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(error, "Failed to generate audience")
      );
    }
  }, [
    enqueueSnackbar,
    saveAudience,
    setGenerationStep,
    dataRoomId,
    audienceName,
    audienceId,
    navigate,
    rulesTree,
    validateRulesTree,
  ]);
  const contextValue =
    useMemo<RulesBasedAdvertiserAudienceGeneratorContextValue>(
      () => ({
        audienceName,
        dataAttributes: dataAttributes.computeResults ?? null,
        estimateAudienceSize,
        estimatedAudienceSize,
        generateAudience,
        generationStep,
        isConfigurationDataLoading: dataAttributes.loading || audiences.loading,
        isEstimatingAudienceSize: Boolean(audienceSizeEstimationKey),
        onRulesTreeChanged,
        rulesTree,
        seedOptions,
        setAudienceName,
        validateRulesTree,
      }),
      [
        onRulesTreeChanged,
        audienceName,
        seedOptions,
        setAudienceName,
        estimatedAudienceSize,
        dataAttributes.computeResults,
        generationStep,
        audiences.loading,
        rulesTree,
        generateAudience,
        dataAttributes.loading,
        audienceSizeEstimationKey,
        estimateAudienceSize,
        validateRulesTree,
      ]
    );
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryGeneration = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      await seedOptions.retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [isDeactivated, seedOptions]);
  if (seedOptions.error) {
    return (
      <AdvertiserAudienceGeneratorErrorState
        RetryButton={
          !isDeactivated && (
            <Button
              color="neutral"
              loading={retryInProgress}
              onClick={retryGeneration}
              variant="soft"
            >
              Retry
            </Button>
          )
        }
      />
    );
  }
  if (seedOptions.loading) {
    return <AdvertiserAudienceGeneratorLoadingState />;
  }
  if (!seedOptions.computeResults?.length) {
    return <AdvertiserAudienceGeneratorEmptyState />;
  }
  let step: ReactNode = null;
  switch (currentStep) {
    case AdvertiserAudienceGeneratorStep.CONFIGURATION:
      step = <RulesBasedAdvertiserAudienceGeneratorConfigurationStep />;
      break;
    case AdvertiserAudienceGeneratorStep.SUMMARY:
      step = <RulesBasedAdvertiserAudienceGeneratorSummaryStep />;
      break;
  }
  return (
    <RulesBasedAdvertiserAudienceGeneratorProvider value={contextValue}>
      {step}
    </RulesBasedAdvertiserAudienceGeneratorProvider>
  );
});

RulesBasedAdvertiserAudienceGenerator.displayName =
  "RulesBasedAdvertiserAudienceGenerator";

export default RulesBasedAdvertiserAudienceGenerator;
