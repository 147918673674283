import {
  useOrganizationDetailsQuery,
  useUpdateOrganizationMigrationDeadlineMutation,
} from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const OrganizationMigrationDeadline = memo(() => {
  const { organizationId } = useOrganizationAdminConfiguration();
  const [migrationDeadline, setMigrationDeadline] = useState<string>("");
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { data: organizationDetailsData } = useOrganizationDetailsQuery({
    variables: {
      organizationId,
    },
  });

  const [updateOrganizationMigrationDeadlineMutation] =
    useUpdateOrganizationMigrationDeadlineMutation({
      onCompleted: () => {
        enqueueSnackbar(
          `Organization migration deadline has been successfully updated.`
        );
      },
    });

  useEffect(() => {
    const migrationDeadline =
      organizationDetailsData?.organization?.migrationDeadline;
    if (migrationDeadline) {
      setMigrationDeadline(migrationDeadline);
    }
  }, [organizationDetailsData]);

  const updateOrganizationMigrationDeadline = useCallback(
    async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event;
      if (
        !target.value ||
        organizationDetailsData?.organization?.migrationDeadline ===
          target.value
      )
        return;
      try {
        await updateOrganizationMigrationDeadlineMutation({
          update: (cache, { data: { organization = {} } }: any) => {
            cache.modify({
              fields: {
                migrationDeadline: () => {
                  return organization?.update?.migrationDeadline;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            input: {
              id: organizationId,
              migrationDeadline: target.value,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          "Organization migration deadline could not be updated.",
          {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          }
        );
        setMigrationDeadline(
          organizationDetailsData?.organization?.migrationDeadline!
        );
      }
    },
    [
      enqueueSnackbar,
      organizationDetailsData?.organization?.migrationDeadline,
      organizationId,
      updateOrganizationMigrationDeadlineMutation,
    ]
  );

  const updateOrganizationMigrationDeadlineOnEnter = useCallback(
    (event: any) => {
      const { target, keyCode } = event || {};
      if (keyCode !== 13 || !target.value) return;
      void updateOrganizationMigrationDeadline(event);
    },
    [updateOrganizationMigrationDeadline]
  );

  return (
    <FormControl>
      <FormLabel>Migration deadline</FormLabel>
      <Input
        name="migrationDeadline"
        onBlur={updateOrganizationMigrationDeadline}
        onChange={({ target }) => setMigrationDeadline(target.value)}
        onKeyDown={updateOrganizationMigrationDeadlineOnEnter}
        placeholder="Migration deadline"
        value={migrationDeadline}
      />
    </FormControl>
  );
});

OrganizationMigrationDeadline.displayName = "OrganizationMigrationDeadline";

export default OrganizationMigrationDeadline;
