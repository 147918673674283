import { DqLoader, DqTable } from "@decentriq/components";
import { useOrganizationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type OrganizationsQuery,
  type OrganizationState,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { Box } from "@mui/joy";
import { capitalize } from "lodash";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrganizationLicenseTypesTooltip } from "features/admin/components";
import { OrganizationStateFilter } from "./components";

type Organization = OrganizationsQuery["organizations"]["nodes"][number];

const OrganizationsList: React.FC = () => {
  const { data: organizationsData, loading } = useOrganizationsQuery();
  const navigate = useNavigate();
  const [organizationStateFilter, setOrganizationStateFilter] =
    useState<OrganizationState | null>(null);
  const { organizationsList, totalCount } = useMemo<{
    organizationsList: Organization[];
    totalCount: number;
  }>(() => {
    if (!organizationsData) {
      return {
        organizationsList: [],
        totalCount: 0,
      };
    }
    const organizationsList = (
      organizationsData.organizations.nodes || []
    ).filter(
      ({ state }) =>
        !organizationStateFilter || state === organizationStateFilter
    );
    return {
      organizationsList,
      totalCount: organizationsList.length,
    };
  }, [organizationStateFilter, organizationsData]);

  const organizationsListColumns = useMemo<MRT_ColumnDef<Organization>[]>(
    () => [
      {
        Footer: <div>Total: {totalCount}</div>,
        accessorKey: "name",
        header: "Name",
        id: "name",
      },
      {
        Cell: ({ cell }) => cell.getValue<string>()?.substring(0, 6),
        accessorKey: "id",
        header: "Id",
        id: "id",
        maxSize: 100,
      },
      {
        Cell: ({ cell }) => capitalize(cell.getValue<string>()),
        Header: (
          <div>
            License
            <span
              style={{
                lineHeight: 1,
                verticalAlign: "middle",
                whiteSpace: "nowrap",
              }}
            >
              &nbsp;
              <OrganizationLicenseTypesTooltip />
            </span>
          </div>
        ),
        accessorKey: "state",
        header: "License status",
        id: "license",
      },
    ],
    [totalCount]
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2.5rem",
          width: "100%",
        }}
      >
        <DqLoader />
      </div>
    );
  }
  return (
    <DqTable
      columns={organizationsListColumns}
      data={organizationsList}
      enableGlobalFilter={true}
      enableSorting={true}
      enableTopToolbar={true}
      initialState={{
        showGlobalFilter: true,
      }}
      localization={{
        noRecordsToDisplay: "No organizations found",
      }}
      muiSearchTextFieldProps={{
        inputProps: {
          "data-testid": testIds.adminPortal.organizations.search,
        },
        placeholder: "Search organizations",
      }}
      muiTableBodyRowProps={({ row }) => ({
        "data-testid":
          testIds.adminPortal.organizations.recordHelper +
          row.original.name.toLowerCase(),
        onClick: () => navigate(`/admin/organizations/${row?.original?.id}`),
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": { pr: 2 },
          cursor: "pointer",
        },
      })}
      muiTableHeadRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": {
            "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
              right: "-1rem",
            },
            pr: 2,
          },
        },
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ alignSelf: "center" }}>
          <OrganizationStateFilter
            onChange={setOrganizationStateFilter}
            value={organizationStateFilter}
          />
        </Box>
      )}
    />
  );
};
OrganizationsList.displayName = "OrganizationsList";

export default memo(OrganizationsList);
