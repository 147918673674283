import { DqCard, DqCardContent } from "@decentriq/components";
import { MediaDataRoomUserRole } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  faDatabase as fatDatabase,
  faNewspaper as fatNewspaper,
} from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type ButtonProps, Typography } from "@mui/joy";

const InviteParticipantIcon: React.FC<{ role: MediaDataRoomUserRole }> = ({
  role,
}) => {
  switch (role) {
    case MediaDataRoomUserRole.Publisher:
      return (
        <span className="fa-layers fa-fw fa-3x">
          <FontAwesomeIcon fixedWidth={true} icon={fatNewspaper} />
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faPlus}
            transform="shrink-8 up-9 right-10"
          />
        </span>
      );
    case MediaDataRoomUserRole.DataPartner:
      return (
        <span className="fa-layers fa-fw fa-3x">
          <FontAwesomeIcon fixedWidth={true} icon={fatDatabase} />
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faPlus}
            transform="shrink-8 up-9 right-10"
          />
        </span>
      );
    default:
      return null;
  }
};

const getDataTestId = (role: MediaDataRoomUserRole) => {
  switch (role) {
    case MediaDataRoomUserRole.Publisher:
      return testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectPublisher
        .invitePublisher;
    default:
      return undefined;
  }
};

interface InviteParticipantButtonProps {
  role: MediaDataRoomUserRole;
  onClick: ButtonProps["onClick"];
}

const InviteParticipantButton: React.FC<InviteParticipantButtonProps> = ({
  role,
  onClick,
}) => (
  <DqCard data-testid={getDataTestId(role)} onClick={onClick}>
    <DqCardContent alignItems="center">
      <InviteParticipantIcon role={role} />
      <Typography level="title-md" textAlign="center">
        Invite {role}
      </Typography>
    </DqCardContent>
  </DqCard>
);

export default InviteParticipantButton;
