import { faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { AddOrganizationUserDialog } from "components";

const UsersAddButton: React.FC = () => {
  const { organizationId } = useParams();
  const [
    isAddOrganizationUserDialogOpen,
    {
      setTrue: openAddOrganizationUserDialog,
      setFalse: closeAddOrganizationUserDialog,
    },
  ] = useBoolean(false);

  return (
    <Box m={1}>
      <Button
        color="primary"
        onClick={openAddOrganizationUserDialog}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faUserPlus} />}
        variant="solid"
      >
        Add organization user
      </Button>
      <AddOrganizationUserDialog
        onCancel={closeAddOrganizationUserDialog}
        open={isAddOrganizationUserDialogOpen}
        organizationId={organizationId!}
      />
    </Box>
  );
};

UsersAddButton.displayName = "UsersAddButton";

export default memo(UsersAddButton);
