import {
  defaultSnackbarAnchorOrigin,
  defaultSnackbarComponents,
  defaultSnackbarIconVariant,
} from "@decentriq/components";
import { SnackbarProvider } from "notistack";
import { memo } from "react";

const SnackbarWrapper = memo<React.PropsWithChildren>(({ children }) => {
  return (
    <SnackbarProvider
      Components={defaultSnackbarComponents}
      anchorOrigin={defaultSnackbarAnchorOrigin}
      autoHideDuration={2000}
      iconVariant={defaultSnackbarIconVariant}
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
});
SnackbarWrapper.displayName = "SnackbarWrapper";

export default SnackbarWrapper;
