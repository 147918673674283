import { DqClickAwayListener } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faAngleDown, faFileContract } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, IconButton, Menu, MenuItem } from "@mui/joy";
import { Fragment, useState } from "react";
import { usePopupState } from "hooks";
import { bindToggle } from "hooks/usePopupState/usePopupState";

const encryptAndPublishMenuOptions: { label: string }[] = [
  { label: "Publish" },
  { label: "Publish without validation" },
];

interface EncryptAndPublishButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: (validate: boolean) => void;
}

const EncryptAndPublishButton: React.FC<EncryptAndPublishButtonProps> = ({
  onClick,
  disabled,
  loading,
}) => {
  const popupId = "encrypt-and-publish-strategy-menu";
  const popupState = usePopupState({ popupId });
  const { anchorEl, isOpen, close } = popupState;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onButtonClick = () => onClick(selectedIndex === 0);
  const onMenuItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    close();
    setSelectedIndex(index);
  };
  return (
    <Fragment>
      <ButtonGroup
        color="primary"
        role="group"
        sx={{ "--ButtonGroup-separatorSize": "0px" }}
        variant="solid"
      >
        <Button
          data-testid={testIds.draftDataRoom.encryptAndPublish.publishButton}
          disabled={disabled || loading}
          onClick={onButtonClick}
          startDecorator={<FontAwesomeIcon icon={faFileContract} />}
        >
          {encryptAndPublishMenuOptions[selectedIndex].label}
        </Button>
        <IconButton {...bindToggle(popupState)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </IconButton>
      </ButtonGroup>
      <DqClickAwayListener
        mouseEvent="mousedown"
        onClickAway={(event) => {
          if ((event.target as HTMLElement).closest("button") !== anchorEl) {
            close();
          }
        }}
      >
        <Menu
          anchorEl={anchorEl}
          id={popupId}
          onClose={close}
          open={isOpen}
          placement="bottom-end"
        >
          {encryptAndPublishMenuOptions.map(({ label }, index) => {
            return (
              <MenuItem
                key={label}
                onClick={(event) => onMenuItemClick(event, index)}
                selected={index === selectedIndex}
              >
                {label}
              </MenuItem>
            );
          })}
        </Menu>
      </DqClickAwayListener>
    </Fragment>
  );
};

export default EncryptAndPublishButton;
