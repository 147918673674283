import { CollaborationTypes, RawSupportedFeatures } from "@decentriq/types";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { type SchemaType } from "components/DqCreateMediaDcr/components/DqCreateMediaDcrFormProvider/model";

const enabledFeaturesTooltipMap: Record<RawSupportedFeatures, string | null> = {
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    "Allow advertiser and agency users to export audiences.",
  [RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS]:
    "If enabled, absolute audience sizes will be shown (overlap statistics, insights statistics, audiences tab).",
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]: null,
};

const CollaborationTypesEditor: React.FC = memo(() => {
  const { control, trigger, setValue } = useFormContext<SchemaType>();
  const enabledFeatures = useWatch({ control, name: "enabledFeatures" });
  const showAdvertiserAudienceDownload =
    enabledFeatures.some((feature) =>
      [
        CollaborationTypes.Remarketing,
        CollaborationTypes.Lookalike,
        CollaborationTypes.ExclusionTargeting,
      ].includes(feature)
    ) && !enabledFeatures.includes(CollaborationTypes.RuleBasedAudiences);
  useEffect(() => {
    if (!showAdvertiserAudienceDownload) {
      setValue("enableAdvertiserAudienceDownload", false);
    }
  }, [setValue, showAdvertiserAudienceDownload]);
  return (
    <FormControl>
      <FormLabel>Options</FormLabel>
      <Controller
        control={control}
        name="showAbsoluteAudienceSizes"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <Typography
                component="label"
                startDecorator={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event);
                      trigger("enabledFeatures");
                    }}
                  />
                }
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <span>
                  Show absolute audience sizes
                  <span
                    style={{
                      lineHeight: 1,
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                    }}
                  >
                    &nbsp;
                    <Tooltip
                      title={
                        enabledFeaturesTooltipMap[
                          RawSupportedFeatures
                            .HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS
                        ]
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </span>
                </span>
              </Typography>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      {showAdvertiserAudienceDownload ? (
        <Controller
          control={control}
          name="enableAdvertiserAudienceDownload"
          render={({ field, fieldState }) => {
            const { error } = fieldState;
            return (
              <FormControl
                disabled={field.disabled}
                error={Boolean(error)}
                required={true}
              >
                <Typography
                  component="label"
                  startDecorator={<Switch {...field} />}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  <span>
                    Allow advertiser and agency to export audiences
                    <span
                      style={{
                        lineHeight: 1,
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                      }}
                    >
                      &nbsp;
                      <Tooltip
                        title={
                          enabledFeaturesTooltipMap[
                            RawSupportedFeatures
                              .ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
                          ]
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Tooltip>
                    </span>
                  </span>
                </Typography>
              </FormControl>
            );
          }}
        />
      ) : null}
    </FormControl>
  );
});
CollaborationTypesEditor.displayName = "CollaborationTypesEditor";

export default CollaborationTypesEditor;
