import { InfoTooltip } from "@decentriq/components";
import { Alert, Box, Grid, styled, Tooltip, Typography } from "@mui/joy";
import {
  type DatasetStatisticsInterface,
  truncateStatisticValue,
} from "features/datasets";

const DatasetStatisticsRow = styled("div")(({ theme }) => ({
  "&:nth-of-type(even)": {
    background: "whitesmoke !important",
  },
  padding: theme.spacing(1),
}));
interface DatasetStatisticsProps {
  statistics: DatasetStatisticsInterface;
}

const DatasetStatistics: React.FC<DatasetStatisticsProps> = ({
  statistics,
}) => {
  if (!statistics) {
    return <Alert>Statistics are not calculated for this dataset</Alert>;
  }
  const {
    withTooltip: fillRatioWithTooltip,
    fullValue: fillRatioFullValue,
    value: fillRatioValue,
  } = truncateStatisticValue(statistics?.fillRatio || 0);
  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: 1024,
        overflowY: "auto",
      }}
    >
      <Grid container={true}>
        <Grid xs={12}>
          <DatasetStatisticsRow>
            <Typography
              level="body-sm"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "1.5rem",
              }}
            >
              Number of rows <strong>{statistics?.numberOfRows}</strong>
            </Typography>
          </DatasetStatisticsRow>
          <DatasetStatisticsRow>
            <Typography
              level="body-sm"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "1.5rem",
              }}
            >
              Number of rows with at least one empty value{" "}
              <strong>
                {statistics.numberOfRows! - statistics.numberOfNotNullableRows!}
              </strong>
            </Typography>
          </DatasetStatisticsRow>
          <DatasetStatisticsRow>
            <Typography
              level="body-sm"
              style={{
                alignItems: "flex-start",
                display: "flex",
                justifyContent: "space-between",
                marginRight: "1.5rem",
              }}
            >
              <span>
                Fill rate
                <InfoTooltip tooltip="Number of non-empty values / total number of values" />
              </span>
              {fillRatioWithTooltip ? (
                <Tooltip title={fillRatioFullValue}>
                  <strong>{fillRatioValue * 100} %</strong>
                </Tooltip>
              ) : (
                <strong>{fillRatioValue * 100} %</strong>
              )}
            </Typography>
          </DatasetStatisticsRow>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DatasetStatistics;
