import { forwardRef, memo } from "react";
import { useFormContext } from "react-hook-form";
import { type SchemaType } from "../DqCreateMeasurementDcrFormProvider/model";

export interface DqCreateMeasurementDcrFormProps
  extends Omit<React.HTMLProps<HTMLFormElement>, "onSubmit"> {
  children: React.ReactNode;
  onSubmit: (
    formValues: SchemaType,
    event?: React.BaseSyntheticEvent
  ) => Promise<void>;
}

const DqCreateMeasurementDcrForm = memo(
  forwardRef<HTMLFormElement, DqCreateMeasurementDcrFormProps>(
    ({ children, onSubmit, ...props }, ref) => {
      const { handleSubmit } = useFormContext<SchemaType>();
      return (
        <form
          autoComplete="off"
          noValidate={true}
          {...props}
          onSubmit={handleSubmit(onSubmit)}
          ref={ref}
        >
          {children}
        </form>
      );
    }
  )
);
DqCreateMeasurementDcrForm.displayName = "DqCreateMeasurementDcrForm";

export default DqCreateMeasurementDcrForm;
