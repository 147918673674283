// TODO: Fix joy migration
import { DqCard, DqCardContent, InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/joy";
import { memo, useCallback, useMemo } from "react";
import {
  MediaDataRoomCreationStep,
  useCreationWizardConfiguration,
  useCreationWizardDataPartner,
  useCreationWizardStepper,
} from "features/mediaDataRoom";
import { MatchingIdChip } from "features/mediaDataRoom/containers/MediaDataRoomCreationWizard/components";
import { OrganizationTile } from "features/mediaPortalShared";
import { mediaDataRoomCollaborationTypes } from "models";
import SelectDataPartnerStepMarketFilters from "./SelectDataPartnerStepMarketFilters";

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const SelectDataPartnerStep: React.FC = () => {
  const {
    allowedCollaborationTypes: allowedFeatures,
    canProceedToConfiguration,
    contactButtonEnabled,
    contactButtonTooltip,
  } = useCreationWizardConfiguration();
  const {
    isAvailableDataPartnersLoading = false,
    availableDataPartners = [],
    selectDataPartner,
    selectedDataPartner,
    inviteDataPartner,
  } = useCreationWizardDataPartner();
  const { setActiveStep, handleBackStep, handleNextStep } =
    useCreationWizardStepper();
  const handleInviteNewDataPartner = useCallback(() => {
    inviteDataPartner();
    handleNextStep();
  }, [inviteDataPartner, handleNextStep]);
  const addDataPartner = useMemo(
    () => (
      <Grid
        alignItems="center"
        data-testid={
          testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectPublisher
            .invitePublisher
        }
        direction="column"
        key={0}
        lg={3}
        md={4}
        xs={6}
      >
        <DqCard
          onSelect={handleInviteNewDataPartner}
          selected={false}
          sx={{ height: "100%", minHeight: 120, width: "100%" }}
        >
          <DqCardContent>
            <StyledIcon fixedWidth={true} icon={faNewspaper} size="3x" />
            <Typography
              color="initial"
              fontWeight={500}
              level="body-sm"
              textAlign="center"
            >
              + Invite data partner
            </Typography>
          </DqCardContent>
        </DqCard>
      </Grid>
    ),
    [handleInviteNewDataPartner]
  );
  let content: React.ReactNode;
  if (isAvailableDataPartnersLoading) {
    content = (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        height="200px"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  } else if (!availableDataPartners?.length) {
    content = (
      <Stack alignItems="center" height="200px" justifyContent="center">
        {addDataPartner}
      </Stack>
    );
  } else {
    content = (
      <Box
        sx={{ display: "flex", flexDirection: "column", overflowY: "hidden" }}
      >
        <Typography fontWeight="fontWeightSemiBold" level="body-md" mb={1}>
          Select data partner
        </Typography>
        <SelectDataPartnerStepMarketFilters />
        <Box
          mb={2}
          sx={{
            borderColor: "var(--joy-palette-neutral-500)",
            borderStyle: "solid",
            borderWidth: 2,
            p: 1.5,
          }}
        >
          <Grid columnSpacing={2} container={true} rowSpacing={2} xs={12}>
            {addDataPartner}
            {availableDataPartners.map((dataPartner) => {
              const { id, dataPartnerLogo, dataPartnerName, marketIds } =
                dataPartner;
              const isSelected = id === selectedDataPartner?.id;
              return (
                <Grid
                  alignItems="center"
                  direction="column"
                  key={id}
                  lg={3}
                  md={4}
                  xs={6}
                >
                  <OrganizationTile
                    logo={dataPartnerLogo}
                    marketIds={marketIds}
                    name={dataPartnerName}
                    onSelect={() => selectDataPartner(dataPartner)}
                    selected={isSelected}
                    sx={{ height: "100%", minHeight: 120, width: "100%" }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ minHeight: "215px", overflowY: "auto" }}>
          {selectedDataPartner?.dataPartnerDescription ? (
            <Box mb={2}>
              <Typography
                fontWeight={"fontWeightSemiBold"}
                level="body-md"
                mb={0.5}
              >
                Description
              </Typography>
              <Typography>
                {selectedDataPartner.dataPartnerDescription}
              </Typography>
            </Box>
          ) : null}
          {!!selectedDataPartner && (
            <>
              <MatchingIdChip />
              <Box>
                <Typography
                  fontWeight={"fontWeightSemiBold"}
                  level="body-md"
                  mb={0.5}
                >
                  Supported collaboration types
                  <InfoTooltip tooltip="If you are interested in a collaboration type which is not yet supported, please contact the data partner using the button to the right." />
                </Typography>
                <Box>
                  {allowedFeatures.length === 0 ? (
                    <Typography color="error" level="body-sm">
                      The collaboration types are incompatible with the
                      publisher's
                    </Typography>
                  ) : (
                    mediaDataRoomCollaborationTypes.map((feature) => (
                      <Chip
                        disabled={!allowedFeatures.includes(feature)}
                        key={feature}
                        sx={{ mr: 1 }}
                      >
                        {feature}
                      </Chip>
                    ))
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {content}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBackStep}>Back</Button>
        {!canProceedToConfiguration && contactButtonEnabled ? (
          <Tooltip title={contactButtonTooltip}>
            <div>
              <Button
                color="primary"
                data-testid={
                  testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                    .requestCollaborationButton
                }
                onClick={() => {
                  setActiveStep(
                    MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER
                  );
                }}
                variant="solid"
              >
                Contact data partner
              </Button>
            </div>
          </Tooltip>
        ) : null}
        {canProceedToConfiguration && (
          <Button
            color="primary"
            data-testid={
              testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard.submitButton
            }
            onClick={handleNextStep}
            variant="solid"
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </>
  );
};

SelectDataPartnerStep.displayName = "SelectDataPartnerStep";

export default memo(SelectDataPartnerStep);
