import { testIds } from "@decentriq/utils";
import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { memo } from "react";
import { SidebarMenuItem } from "features/sidebar";

const DataPartnerPortalMenuItem = () => (
  <SidebarMenuItem
    data-testid={testIds.sidebar.sidebarMenuItems.dataPartnerPortalButton}
    icon={faDatabase}
    innerMenuItems={[
      {
        label: "Configuration",
        to: "/data-partner-portal/configurations",
      },
    ]}
    label="Data Partner Portal"
    to="/data-partner-portal"
    tooltipTitle="Data Partner Portal"
  />
);

DataPartnerPortalMenuItem.displayName = "DataPartnerPortalMenuItem";

export default memo(DataPartnerPortalMenuItem);
