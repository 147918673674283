import { DqCard, DqCardContent } from "@decentriq/components";
import { type DataRoomsListQuery } from "@decentriq/graphql/dist/types";
import { faCubes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { DataRoomsBlockCardContent } from "./components";

interface DataRoomsBlockProps {
  dataRooms: DataRoomsListQuery["dataRooms"]["nodes"];
  isDataRoomsLoading: boolean;
}

const DataRoomsBlock = memo<DataRoomsBlockProps>(
  ({ dataRooms, isDataRoomsLoading }) => (
    <Stack height="100%">
      <Typography
        fontWeight={500}
        level="body-md"
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faCubes} />}
      >
        Data clean rooms ({dataRooms.length})
      </Typography>
      <Stack flex={1} maxHeight={600}>
        <DqCard>
          <DqCardContent justifyContent="flex-start">
            <DataRoomsBlockCardContent
              dataRooms={dataRooms}
              isDataRoomsLoading={isDataRoomsLoading}
            />
          </DqCardContent>
        </DqCard>
      </Stack>
    </Stack>
  )
);

DataRoomsBlock.displayName = "DataRoomsBlock";

export default DataRoomsBlock;
