import { usePrevious, useUpdateEffect } from "@decentriq/hooks";
import { MediaDataRoomOrganizationRole } from "@decentriq/types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useOrganizationPreferences } from "hooks"; // NOTE: Package marked as external in vite.config.ts
import {
  CreationWizardStepperContextProvider,
  type CreationWizardStepperContextValue,
} from "../../contexts";
import { MediaDataRoomCreationStep } from "../../types";

export interface CreationWizardStepperWrapperProps
  extends React.PropsWithChildren {
  restartCreation: () => void;
}

const CreationWizardStepperWrapper = memo<CreationWizardStepperWrapperProps>(
  ({ restartCreation, children }) => {
    const {
      hasAdvertiserFeatures,
      hasPublisherFeatures,
      hasDataPartnerFeatures,
      organizationId,
      canViewDataPartners,
      numberOfSupportedMediaRoles,
    } = useOrganizationPreferences();
    const [activeStep, setActiveStep] = useState<MediaDataRoomCreationStep>(
      MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE
    );
    const previousStep = usePrevious(activeStep);
    const [hasOwnDataToProvide, setHasOwnDataToProvide] =
      useState<boolean>(true);
    const [organizationRole, setOrganizationRole] =
      useState<MediaDataRoomOrganizationRole | null>(null);
    const hasMultipleRolesSupported = useMemo<boolean>(
      () => numberOfSupportedMediaRoles > 1,
      [numberOfSupportedMediaRoles]
    );
    const handleNextStep = useCallback(() => {
      if (
        (activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
          !canViewDataPartners) ||
        activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER
      ) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
        canViewDataPartners
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_SOURCE);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE &&
        hasOwnDataToProvide
      ) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE &&
        !hasOwnDataToProvide
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_PARTNER);
        return;
      }
    }, [activeStep, canViewDataPartners, hasOwnDataToProvide]);
    const handleBackStep = useCallback(() => {
      if (activeStep === MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE) {
        restartCreation();
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
        !hasMultipleRolesSupported
      ) {
        restartCreation();
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.SELECT_PUBLISHER &&
        hasMultipleRolesSupported
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE);
        setOrganizationRole(null);
        return;
      }
      if (activeStep === MediaDataRoomCreationStep.SELECT_DATA_PARTNER) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_SOURCE);
        return;
      }
      if (activeStep === MediaDataRoomCreationStep.SELECT_DATA_SOURCE) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        return;
      }
      if (
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_DATA_PARTNER);
        return;
      }
      if (
        activeStep ===
        MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
      ) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        return;
      }
      if (
        activeStep === MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION &&
        previousStep !== undefined
      ) {
        if (
          previousStep === MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE
        ) {
          if (hasMultipleRolesSupported) {
            setOrganizationRole(null);
            setActiveStep(previousStep);
            return;
          } else {
            restartCreation();
            return;
          }
        } else {
          setActiveStep(previousStep);
          return;
        }
      }
    }, [activeStep, hasMultipleRolesSupported, previousStep, restartCreation]);
    const canSelectDataPartner = useMemo(
      () =>
        organizationRole === MediaDataRoomOrganizationRole.ADVERTISER &&
        canViewDataPartners,
      [organizationRole, canViewDataPartners]
    );
    useEffect(() => {
      if (hasMultipleRolesSupported) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_ORGANIZATION_ROLE);
        return;
      }
      if (hasAdvertiserFeatures) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        setOrganizationRole(MediaDataRoomOrganizationRole.ADVERTISER);
        return;
      }
      if (hasDataPartnerFeatures || hasPublisherFeatures) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
        setOrganizationRole(
          hasPublisherFeatures
            ? MediaDataRoomOrganizationRole.PUBLISHER
            : MediaDataRoomOrganizationRole.DATA_PARTNER
        );
      }
    }, [
      hasMultipleRolesSupported,
      hasAdvertiserFeatures,
      hasPublisherFeatures,
      hasDataPartnerFeatures,
      organizationId,
    ]);
    useUpdateEffect(() => {
      if (organizationRole === MediaDataRoomOrganizationRole.ADVERTISER) {
        setActiveStep(MediaDataRoomCreationStep.SELECT_PUBLISHER);
        return;
      }
      if (
        organizationRole === MediaDataRoomOrganizationRole.PUBLISHER ||
        organizationRole === MediaDataRoomOrganizationRole.DATA_PARTNER
      ) {
        setActiveStep(MediaDataRoomCreationStep.COLLABORATION_CONFIGURATION);
      }
    }, [organizationRole]);
    const contextValue: CreationWizardStepperContextValue = useMemo(
      () => ({
        activeStep,
        canSelectDataPartner,
        handleBackStep,
        handleNextStep,
        hasOwnDataToProvide,
        organizationRole,
        setActiveStep,
        setHasOwnDataToProvide,
        setOrganizationRole,
      }),
      [
        activeStep,
        canSelectDataPartner,
        handleBackStep,
        handleNextStep,
        hasOwnDataToProvide,
        organizationRole,
        setActiveStep,
        setHasOwnDataToProvide,
        setOrganizationRole,
      ]
    );
    return (
      <CreationWizardStepperContextProvider value={contextValue}>
        {children}
      </CreationWizardStepperContextProvider>
    );
  }
);
CreationWizardStepperWrapper.displayName = "CreationWizardStepperWrapper";

export default CreationWizardStepperWrapper;
