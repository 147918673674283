import { DataSourceType, DataTargetType } from "@decentriq/graphql/dist/types";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormLabel,
  Option,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Fragment, memo } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";
import { MicrosoftDspMember } from "models";
import ConnectorStateSelect from "./ConnectorStateSelect";

const OrganizationConnectorStates = memo(() => {
  const {
    enableConnectorStates,
    microsoftDspMember,
    dataConnectors: {
      s3ExportState,
      metaExportState,
      googleDv360ExportState,
      tradedeskExportState,
      azureExportState,
      googleCloudStorageExportState,
      googleAdManagerExportState,
      permutiveExportState,
      sportradarExportState,
      adformExportState,
      microsoftDspExportState,
      splickyExportState,
      googleAdsExportState,
      snapchatExportState,
      tiktokExportState,
      s3ImportState,
      snowflakeImportState,
      salesforceImportState,
      azureImportState,
      googleCloudStorageImportState,
      permutiveImportState,
      databricksImportState,
    },
    updateConnectorState,
    updateMicrosoftDspMember,
  } = useOrganizationAdminConfiguration();

  return (
    <Fragment>
      <Stack>
        <Typography level="title-md">Data export connectors</Typography>
        <ConnectorStateSelect
          connectionType={DataTargetType.S3}
          disabled={!enableConnectorStates}
          name="s3ExportState"
          onChange={updateConnectorState}
          state={s3ExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Azure}
          disabled={!enableConnectorStates}
          name="azureExportState"
          onChange={updateConnectorState}
          state={azureExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.GoogleCloudStorage}
          disabled={!enableConnectorStates}
          name="googleCloudStorageExportState"
          onChange={updateConnectorState}
          state={googleCloudStorageExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Adform}
          disabled={!enableConnectorStates}
          name="adformExportState"
          onChange={updateConnectorState}
          state={adformExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.GoogleAdManager}
          disabled={!enableConnectorStates}
          name="googleAdManagerExportState"
          onChange={updateConnectorState}
          state={googleAdManagerExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.GoogleAds}
          disableEnableOption={true}
          disableEnableOptionMessage="The Google Ads connector is not implemented yet"
          disabled={!enableConnectorStates}
          name="googleAdsExportState"
          onChange={updateConnectorState}
          state={googleAdsExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.GoogleDv_360}
          disabled={!enableConnectorStates}
          name="googleDv360ExportState"
          onChange={updateConnectorState}
          state={googleDv360ExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Meta}
          disabled={!enableConnectorStates}
          name="metaExportState"
          onChange={updateConnectorState}
          state={metaExportState}
        />
        <Stack
          direction="row"
          sx={{
            "& > *": {
              width: "50%",
            },
          }}
        >
          <ConnectorStateSelect
            connectionType={DataTargetType.MicrosoftDsp}
            disableEnableOption={microsoftDspMember === MicrosoftDspMember.None}
            disableEnableOptionMessage={
              microsoftDspMember === MicrosoftDspMember.None
                ? "The Microsoft DSP member needs to be selected first"
                : undefined
            }
            disabled={!enableConnectorStates}
            name="microsoftDspExportState"
            onChange={updateConnectorState}
            state={microsoftDspExportState}
          />
          <FormControl>
            <Stack>
              <FormLabel>
                Microsoft DSP seat
                <Tooltip title="This selects the account created for Decentriq by the Microsoft DSP seat owner. The Microsoft DSP connector can only be used if this value is set. This value determines where audiences are pushed to if a user of this organisation uses the Microsoft DSP connector. Only set it if you are certain that all audiences of this organisation should go into this seat.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </FormLabel>
              <Select
                onChange={(_, value) =>
                  updateMicrosoftDspMember(value as MicrosoftDspMember)
                }
                value={microsoftDspMember || MicrosoftDspMember.None}
              >
                {Object.values(MicrosoftDspMember).map((members) => (
                  <Option key={members} value={members}>
                    {members}
                  </Option>
                ))}
              </Select>
            </Stack>
          </FormControl>
        </Stack>
        <ConnectorStateSelect
          connectionType={DataTargetType.Permutive}
          disabled={!enableConnectorStates}
          name="permutiveExportState"
          onChange={updateConnectorState}
          state={permutiveExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Snapchat}
          disableEnableOption={true}
          disableEnableOptionMessage="The Snapchat connector is not implemented yet"
          disabled={!enableConnectorStates}
          name="snapchatExportState"
          onChange={updateConnectorState}
          state={snapchatExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Splicky}
          disabled={!enableConnectorStates}
          name="splickyExportState"
          onChange={updateConnectorState}
          state={splickyExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Sportradar}
          disabled={!enableConnectorStates}
          name="sportradarExportState"
          onChange={updateConnectorState}
          state={sportradarExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.Tiktok}
          disableEnableOption={true}
          disableEnableOptionMessage="The TikTok connector is not implemented yet"
          disabled={!enableConnectorStates}
          name="tiktokExportState"
          onChange={updateConnectorState}
          state={tiktokExportState}
        />
        <ConnectorStateSelect
          connectionType={DataTargetType.TradeDesk}
          disabled={!enableConnectorStates}
          name="tradedeskExportState"
          onChange={updateConnectorState}
          state={tradedeskExportState}
        />
      </Stack>
      <Stack sx={{ mb: 2 }}>
        <Typography level="title-md">Data import connectors</Typography>
        <ConnectorStateSelect
          connectionType={DataSourceType.S3}
          disabled={!enableConnectorStates}
          name="s3ImportState"
          onChange={updateConnectorState}
          state={s3ImportState}
        />
        <ConnectorStateSelect
          connectionType={DataSourceType.Azure}
          disabled={!enableConnectorStates}
          name="azureImportState"
          onChange={updateConnectorState}
          state={azureImportState}
        />
        <ConnectorStateSelect
          connectionType={DataSourceType.GoogleCloudStorage}
          disabled={!enableConnectorStates}
          name="googleCloudStorageImportState"
          onChange={updateConnectorState}
          state={googleCloudStorageImportState}
        />
        <ConnectorStateSelect
          connectionType={DataSourceType.Databricks}
          disableEnableOption={true}
          disableEnableOptionMessage="The Databricks connector is not implemented yet"
          disabled={!enableConnectorStates}
          name="databricksImportState"
          onChange={updateConnectorState}
          state={databricksImportState}
        />
        <ConnectorStateSelect
          connectionType={DataSourceType.Permutive}
          disabled={!enableConnectorStates}
          name="permutiveImportState"
          onChange={updateConnectorState}
          state={permutiveImportState}
        />
        <ConnectorStateSelect
          connectionType={DataSourceType.Salesforce}
          disabled={!enableConnectorStates}
          name="salesforceImportState"
          onChange={updateConnectorState}
          state={salesforceImportState}
        />
        <ConnectorStateSelect
          connectionType={DataSourceType.Snowflake}
          disabled={!enableConnectorStates}
          name="snowflakeImportState"
          onChange={updateConnectorState}
          state={snowflakeImportState}
        />
      </Stack>
    </Fragment>
  );
});
OrganizationConnectorStates.displayName = "OrganizationFeaturesEditor";

export default OrganizationConnectorStates;
