import { DqLoader } from "@decentriq/components";
import { Stack } from "@mui/joy";
import { memo } from "react";
import { useDataRoomDrawerContext } from "../../contexts";
import {
  DataRoomCollaboratingOrganizations,
  DataRoomCreatedBy,
  DataRoomDatalabInfo,
  DataRoomDatasets,
  DataRoomDateInfo,
  DataRoomId,
  DataRoomParticipants,
  DataRoomStatus,
} from "./components";

const DataRoomInformation = memo(() => {
  const { loading } = useDataRoomDrawerContext();

  if (loading) {
    return (
      <DqLoader
        size="md"
        sx={{ height: "100%", justifyContent: "center", width: "100%" }}
        thickness={2}
      />
    );
  }

  return (
    <Stack>
      <DataRoomCreatedBy />
      <DataRoomDateInfo />
      <DataRoomStatus />
      <DataRoomId />
      <DataRoomCollaboratingOrganizations />
      <DataRoomParticipants />
      <DataRoomDatasets />
      <DataRoomDatalabInfo />
    </Stack>
  );
});
DataRoomInformation.displayName = "DataRoomInformation";

export default DataRoomInformation;
