import { Chip } from "@mui/joy";
import { memo } from "react";

interface AffinityRatioChipProps {
  value: number;
}

const AffinityRatioChip = memo<AffinityRatioChipProps>(({ value }) => {
  return (
    <Chip
      sx={({ palette }) => ({
        "& .MuiChip-label": {
          color: palette.primary.plainColor,
          padding: "0 8px",
        },
        // For values exceeding 2, the background should remain the same as for 2.0 value
        // For values below 0.5, the background should remain the same as for 0.5 value
        backgroundColor:
          palette.affinityRatio[
            (value > 2
              ? "2.0"
              : value < 0.5
                ? "0.5"
                : value.toFixed(1)) as keyof typeof palette.affinityRatio
          ],
        fontSize: "14px",
      })}
    >
      {`${value.toFixed(1)}x`}
    </Chip>
  );
});
AffinityRatioChip.displayName = "AffinityRatioChip";

export default AffinityRatioChip;
