import {
  useEnclaveWorkersQuery,
  useOrganizationEnclaveWorkersQuery,
  useOrganizationFeaturesQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationEnclaveWorkersMutation,
  useUpdateOrganizationFlagsMutation,
  useUpdateOrganizationMicrosoftDspMemberMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  FeatureVisibilityState,
  OrganizationState,
  type UpdateOrganizationFlagsInput,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { createContext, memo, useCallback, useMemo } from "react";
import {
  CommonSnackbarOrigin,
  useGeneralSnackbar,
  useSafeContext,
  useUserRole,
} from "hooks";
import { MicrosoftDspMember } from "models";
import { getEffectiveErrorMessage } from "utils";

interface OrganizationAdminConfigurationContextValue {
  organizationId: string;
  // Feature flags
  hasAdvertiserFeatures: boolean;
  hasAnalyticsFeatures: boolean;
  hasPublisherFeatures: boolean;
  hasDataPartnerFeatures: boolean;
  useLegacyDataLabs: boolean;
  skipMdcrAudiencesResettingOnDataLabChange: boolean;
  canViewDataPartners: boolean;
  canViewMeasurements: boolean;
  showMigrationPrompt: boolean;
  allowExcludingSeedAudience: boolean;
  enableAudienceBuilder: boolean;
  microsoftDspMember: MicrosoftDspMember;

  dataConnectors: {
    // Data connectors - exports
    s3ExportState: FeatureVisibilityState;
    metaExportState: FeatureVisibilityState;
    googleDv360ExportState: FeatureVisibilityState;
    tradedeskExportState: FeatureVisibilityState;
    azureExportState: FeatureVisibilityState;
    googleCloudStorageExportState: FeatureVisibilityState;
    googleAdManagerExportState: FeatureVisibilityState;
    permutiveExportState: FeatureVisibilityState;
    sportradarExportState: FeatureVisibilityState;
    adformExportState: FeatureVisibilityState;
    microsoftDspExportState: FeatureVisibilityState;
    splickyExportState: FeatureVisibilityState;
    googleAdsExportState: FeatureVisibilityState;
    snapchatExportState: FeatureVisibilityState;
    tiktokExportState: FeatureVisibilityState;
    // Data connectors - imports
    s3ImportState: FeatureVisibilityState;
    snowflakeImportState: FeatureVisibilityState;
    salesforceImportState: FeatureVisibilityState;
    azureImportState: FeatureVisibilityState;
    googleCloudStorageImportState: FeatureVisibilityState;
    permutiveImportState: FeatureVisibilityState;
    databricksImportState: FeatureVisibilityState;
  };

  // Editor state
  isEditorDisabled: boolean;
  isPublisherCheckboxEnabled: boolean;
  isSuperAdmin: boolean;
  showArchiveButton: boolean;
  showComputeTimeoutEditor: boolean;
  showOrganizationDetails: boolean;
  showMediaScopeEditor: boolean;
  showMigrationDeadlineEditor: boolean;
  showFeaturesEditor: boolean;
  showConnectorStates: boolean;
  enableConnectorStates: boolean;
  enableComputeTimeoutEditor: boolean;
  enableDomainEditor: boolean;
  enableEnclaveWorkersEditor: boolean;
  enableOrganizationLicenseEditor: boolean;
  enableOrganizationLogoEditor: boolean;
  enableMediaScopeEditor: boolean;
  enableOrganizationNameEditor: boolean;
  // Update function
  updateOrganizationFlags: (event: React.ChangeEvent<HTMLInputElement>) => void;

  // Enclave workers
  enclaveWorkers: Array<{ id: string; shortName: WorkerTypeShortName }>;
  availableEnclaveWorkers: Array<{
    id: string;
    shortName: WorkerTypeShortName;
  }>;
  isPythonComputationTypeEnabled: boolean;
  updateOrganizationEnclaveWorkers: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  updateMicrosoftDspMember: (microsoftDspMember: MicrosoftDspMember) => void;
  updateConnectorState: (
    connectorName: keyof UpdateOrganizationFlagsInput,
    state: FeatureVisibilityState
  ) => void;
  isEnclaveWorkersEditorDisabled: boolean;
}

const OrganizationAdminConfigurationContext =
  createContext<OrganizationAdminConfigurationContextValue | null>(null);

OrganizationAdminConfigurationContext.displayName =
  "OrganizationAdminConfigurationContext";

interface OrganizationAdminConfigurationWrapperProps {
  children: React.ReactNode;
  organizationId: string;
}

export const OrganizationAdminConfigurationWrapper =
  memo<OrganizationAdminConfigurationWrapperProps>(
    ({ children, organizationId }) => {
      const { enqueueSnackbar } = useGeneralSnackbar({
        origin: CommonSnackbarOrigin.ADMIN,
      });
      const { isSuperAdmin, isDecentriqAdmin, isOrganizationAdmin } =
        useUserRole();

      const { data: organizationFeaturesData } = useOrganizationFeaturesQuery({
        variables: { organizationId },
      });

      const { data: organizationLicenseData } = useOrganizationLicenseQuery({
        variables: { organizationId },
      });

      const organizationLicense = organizationLicenseData?.organization?.state;

      const [updateOrganizationFlagsMutation] =
        useUpdateOrganizationFlagsMutation();

      const updateOrganizationFlags = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) =>
          void updateOrganizationFlagsMutation({
            onError: (error) => {
              enqueueSnackbar(
                "Available feature settings could not be updated.",
                {
                  context: getEffectiveErrorMessage(error),
                  persist: true,
                  variant: "error",
                }
              );
            },
            variables: {
              input: {
                [target.name as keyof UpdateOrganizationFlagsInput]:
                  target.checked,
              },
              organizationId,
            },
          }),
        [updateOrganizationFlagsMutation, organizationId, enqueueSnackbar]
      );

      const isEditorDisabled =
        !organizationLicense ||
        OrganizationState.Archived === organizationLicense;

      const isPublisherCheckboxEnabled =
        organizationLicense === OrganizationState.Passive && isSuperAdmin;

      // Enclave workers queries
      const { data: enclaveWorkersData } = useEnclaveWorkersQuery({
        skip: !isSuperAdmin,
      });
      const { data: organizationEnclaveWorkersData } =
        useOrganizationEnclaveWorkersQuery({
          variables: { organizationId },
        });
      const [updateOrganizationEnclaveWorkersMutation] =
        useUpdateOrganizationEnclaveWorkersMutation();

      const enclaveWorkers = useMemo(
        () => enclaveWorkersData?.workerTypes?.nodes || [],
        [enclaveWorkersData]
      );

      const availableEnclaveWorkers = useMemo(
        () =>
          organizationEnclaveWorkersData?.organization?.workerTypes?.nodes ||
          [],
        [organizationEnclaveWorkersData]
      );

      const updateOrganizationEnclaveWorkers = useCallback(
        async ({
          target: { value: workerId = "" },
        }: React.ChangeEvent<HTMLInputElement>) => {
          let newWorkersArray = [];
          if (availableEnclaveWorkers.some(({ id }) => id === workerId)) {
            newWorkersArray = availableEnclaveWorkers.reduce<string[]>(
              (workersArray, { id }) =>
                id === workerId ? workersArray : [id, ...workersArray],
              []
            );
          } else {
            const availableEnclaveWorkersIds = availableEnclaveWorkers.map(
              ({ id }) => id
            );
            newWorkersArray = [...availableEnclaveWorkersIds, workerId];
          }

          try {
            await updateOrganizationEnclaveWorkersMutation({
              variables: {
                input: {
                  id: organizationId,
                  workerTypes: newWorkersArray,
                },
              },
            });
          } catch (error) {
            enqueueSnackbar(
              "Available computations settings could not be updated.",
              {
                context: getEffectiveErrorMessage(error),
                persist: true,
                variant: "error",
              }
            );
          }
        },
        [
          enqueueSnackbar,
          availableEnclaveWorkers,
          organizationId,
          updateOrganizationEnclaveWorkersMutation,
        ]
      );

      const [updateOrganizationMicrosoftDspMemberMutation] =
        useUpdateOrganizationMicrosoftDspMemberMutation();

      const updateMicrosoftDspMember = useCallback(
        (microsoftDspMember: MicrosoftDspMember) =>
          void updateOrganizationMicrosoftDspMemberMutation({
            onError: (error) => {
              enqueueSnackbar("Microsoft DSP seat could not be updated.", {
                context: getEffectiveErrorMessage(error),
                persist: true,
                variant: "error",
              });
            },
            variables: {
              input: {
                id: organizationId,
                microsoftDspMember,
              },
            },
          }),
        [
          updateOrganizationMicrosoftDspMemberMutation,
          organizationId,
          enqueueSnackbar,
        ]
      );

      const updateConnectorState = useCallback(
        (
          connectorName: keyof UpdateOrganizationFlagsInput,
          state: FeatureVisibilityState
        ) =>
          void updateOrganizationFlagsMutation({
            onError: (error) => {
              enqueueSnackbar(
                "Available feature settings could not be updated.",
                {
                  context: getEffectiveErrorMessage(error),
                  persist: true,
                  variant: "error",
                }
              );
            },
            variables: {
              // TODO: Investigate why the type is not inferred correctly
              input: {
                [connectorName]: state,
              },
              organizationId,
            },
          }),
        [updateOrganizationFlagsMutation, organizationId, enqueueSnackbar]
      );

      const isEnclaveWorkersEditorDisabled = [
        OrganizationState.Passive,
        OrganizationState.Suspended,
      ].includes(organizationLicense!);

      const contextValue =
        useMemo<OrganizationAdminConfigurationContextValue>(() => {
          const dataConnectors = {
            adformExportState:
              organizationFeaturesData?.organization?.adformExportState ??
              FeatureVisibilityState.Hidden,
            azureExportState:
              organizationFeaturesData?.organization?.azureExportState ??
              FeatureVisibilityState.Hidden,
            azureImportState:
              organizationFeaturesData?.organization?.azureImportState ??
              FeatureVisibilityState.Hidden,
            databricksImportState:
              organizationFeaturesData?.organization?.databricksImportState ??
              FeatureVisibilityState.Hidden,
            googleAdManagerExportState:
              organizationFeaturesData?.organization
                ?.googleAdManagerExportState ?? FeatureVisibilityState.Hidden,
            googleAdsExportState:
              organizationFeaturesData?.organization?.googleAdsExportState ??
              FeatureVisibilityState.Hidden,
            googleCloudStorageExportState:
              organizationFeaturesData?.organization
                ?.googleCloudStorageExportState ??
              FeatureVisibilityState.Hidden,
            googleCloudStorageImportState:
              organizationFeaturesData?.organization
                ?.googleCloudStorageImportState ??
              FeatureVisibilityState.Hidden,
            googleDv360ExportState:
              organizationFeaturesData?.organization?.googleDv360ExportState ??
              FeatureVisibilityState.Hidden,
            metaExportState:
              organizationFeaturesData?.organization?.metaExportState ??
              FeatureVisibilityState.Hidden,
            microsoftDspExportState:
              organizationFeaturesData?.organization?.microsoftDspExportState ??
              FeatureVisibilityState.Hidden,
            permutiveExportState:
              organizationFeaturesData?.organization?.permutiveExportState ??
              FeatureVisibilityState.Hidden,
            permutiveImportState:
              organizationFeaturesData?.organization?.permutiveImportState ??
              FeatureVisibilityState.Hidden,
            s3ExportState:
              organizationFeaturesData?.organization?.s3ExportState ??
              FeatureVisibilityState.Hidden,
            s3ImportState:
              organizationFeaturesData?.organization?.s3ImportState ??
              FeatureVisibilityState.Hidden,
            salesforceImportState:
              organizationFeaturesData?.organization?.salesforceImportState ??
              FeatureVisibilityState.Hidden,
            snapchatExportState:
              organizationFeaturesData?.organization?.snapchatExportState ??
              FeatureVisibilityState.Hidden,
            snowflakeImportState:
              organizationFeaturesData?.organization?.snowflakeImportState ??
              FeatureVisibilityState.Hidden,
            splickyExportState:
              organizationFeaturesData?.organization?.splickyExportState ??
              FeatureVisibilityState.Hidden,
            sportradarExportState:
              organizationFeaturesData?.organization?.sportradarExportState ??
              FeatureVisibilityState.Hidden,
            tiktokExportState:
              organizationFeaturesData?.organization?.tiktokExportState ??
              FeatureVisibilityState.Hidden,
            tradedeskExportState:
              organizationFeaturesData?.organization?.tradedeskExportState ??
              FeatureVisibilityState.Hidden,
          };
          return {
            allowExcludingSeedAudience:
              organizationFeaturesData?.organization
                ?.allowExcludingSeedAudience ?? false,
            availableEnclaveWorkers,
            canViewDataPartners:
              organizationFeaturesData?.organization?.canViewDataPartners ??
              false,
            canViewMeasurements:
              organizationFeaturesData?.organization?.canViewMeasurements ??
              false,
            dataConnectors,
            enableAudienceBuilder:
              organizationFeaturesData?.organization?.enableAudienceBuilder ??
              false,
            enableComputeTimeoutEditor: isSuperAdmin,
            enableConnectorStates: isSuperAdmin,
            enableDomainEditor: isSuperAdmin,
            enableEnclaveWorkersEditor: isSuperAdmin,
            enableMediaScopeEditor: isSuperAdmin,
            enableOrganizationLicenseEditor: isSuperAdmin,
            enableOrganizationLogoEditor: isSuperAdmin || isOrganizationAdmin,
            enableOrganizationNameEditor: isSuperAdmin || isOrganizationAdmin,
            enclaveWorkers,
            hasAdvertiserFeatures:
              organizationFeaturesData?.organization?.hasAdvertiserFeatures ??
              false,
            hasAnalyticsFeatures:
              organizationFeaturesData?.organization?.hasAnalyticsFeatures ??
              false,
            hasDataPartnerFeatures:
              organizationFeaturesData?.organization?.hasDataPartnerFeatures ??
              false,
            hasPublisherFeatures:
              organizationFeaturesData?.organization?.hasPublisherFeatures ??
              false,
            isEditorDisabled,
            isEnclaveWorkersEditorDisabled,
            isPublisherCheckboxEnabled,
            isPythonComputationTypeEnabled: availableEnclaveWorkers.some(
              ({ shortName }) => shortName === WorkerTypeShortName.Python
            ),
            isSuperAdmin,
            microsoftDspMember:
              (organizationFeaturesData?.organization
                ?.microsoftDspMember as MicrosoftDspMember) ??
              MicrosoftDspMember.None,
            organizationId,
            showArchiveButton: isSuperAdmin,
            showComputeTimeoutEditor: isDecentriqAdmin,
            showConnectorStates: isDecentriqAdmin,
            showFeaturesEditor: isSuperAdmin,
            showMediaScopeEditor: isDecentriqAdmin,
            showMigrationDeadlineEditor: isDecentriqAdmin,
            showMigrationPrompt:
              organizationFeaturesData?.organization?.showMigrationPrompt ??
              false,
            showOrganizationDetails: isDecentriqAdmin,
            skipMdcrAudiencesResettingOnDataLabChange:
              organizationFeaturesData?.organization
                ?.skipMdcrAudiencesResettingOnDataLabChange ?? false,
            updateConnectorState,
            updateMicrosoftDspMember,
            updateOrganizationEnclaveWorkers,
            updateOrganizationFlags,
            useLegacyDataLabs:
              organizationFeaturesData?.organization?.useLegacyDataLabs ??
              false,
          };
        }, [
          organizationFeaturesData,
          availableEnclaveWorkers,
          isSuperAdmin,
          isOrganizationAdmin,
          enclaveWorkers,
          isEditorDisabled,
          isEnclaveWorkersEditorDisabled,
          isPublisherCheckboxEnabled,
          organizationId,
          isDecentriqAdmin,
          updateConnectorState,
          updateMicrosoftDspMember,
          updateOrganizationEnclaveWorkers,
          updateOrganizationFlags,
        ]);

      return (
        <OrganizationAdminConfigurationContext.Provider value={contextValue}>
          {children}
        </OrganizationAdminConfigurationContext.Provider>
      );
    }
  );

export const useOrganizationAdminConfiguration = () =>
  useSafeContext(OrganizationAdminConfigurationContext);
