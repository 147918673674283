import { DqLoader } from "@decentriq/components";
import { Stack } from "@mui/joy";
import React, { Fragment, memo } from "react";
import { Navigate } from "react-router-dom";
import {
  DataLabErrorReport,
  DataLabNodes,
  DataLabQualityStatistics,
  DataLabToolbar,
} from "features/dataLabs";
import { DataLabWrapper, useDataLabContext } from "features/dataLabs/contexts";

interface DataLabProps {
  id: string;
}

const DataLab: React.FC<DataLabProps> = ({ id }) => {
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  if (loading) {
    return (
      <DqLoader
        sx={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      />
    );
  }
  if (data) {
    return (
      <Fragment>
        <DataLabToolbar id={id} />
        <Stack direction="column" sx={{ flex: 1, overflow: "auto", p: 2 }}>
          <DataLabNodes />
          <DataLabQualityStatistics />
        </Stack>
      </Fragment>
    );
  }
  return <Navigate replace={true} to="/publisherportal/datalabs" />;
};

const DataLabPageWrapper: React.FC<DataLabProps> = memo(({ id }) => {
  return (
    <DataLabErrorReport id={id}>
      <DataLabWrapper id={id}>
        <DataLab id={id} />
      </DataLabWrapper>
    </DataLabErrorReport>
  );
});

export default DataLabPageWrapper;
