// TODO: Fix joy migration & refactor this file
import { Box, LinearProgress, Tooltip, Typography } from "@mui/joy";
import { Fragment, memo } from "react";

const idToNameAndMaybeTooltip = (id: string) => {
  const compoundKeyRegex = /Compound Key (?<number>\d+) \((?<columns>.*)\)/;
  const foundCompoundKey = id.match(compoundKeyRegex);
  if (foundCompoundKey) {
    const columns = foundCompoundKey.groups?.columns
      ?.split(",")
      ?.map((c) => c.trim());
    if (Array.isArray(columns)) {
      if (columns.length > 1) {
        return (
          <Tooltip placement="top" title={columns.join(", ")}>
            <span style={{ textDecoration: "underline" }}>
              {["Compound Key", foundCompoundKey.groups?.number]
                .filter(Boolean)
                .join(" ")}
            </span>
          </Tooltip>
        );
      } else {
        return columns[0];
      }
    } else {
      return id;
    }
  }
  const groupRegex = /Group (?<number>\d+) \((?<columns>.*)\)/;
  const foundGroup = id.match(groupRegex);
  if (foundGroup) {
    const columns = foundGroup.groups?.columns
      ?.split(",")
      ?.map((c) => c.trim());
    return (
      <Tooltip
        placement="top"
        title={Array.isArray(columns) ? columns.join(", ") : ""}
      >
        <span style={{ textDecoration: "underline" }}>
          {["Group", foundGroup.groups?.number].filter(Boolean).join(" ")}
        </span>
      </Tooltip>
    );
  }
  return id;
};

export interface ResultStatisticsBlockProps {
  color: string;
  title: string;
  rows?: number;
  rowsLabel?: string;
  rowsWithoutEmptyValuesByGroup?: Array<{ id: string; value: number }>;
  uniqueValues?: Array<{ id: string; value: number }>;
  approxRowsMatchedByGroup?: Array<{ id: string; value: number }>;
  denominator?: number;
}

const ResultStatisticsBlock = memo<ResultStatisticsBlockProps>(
  ({
    color,
    title,
    rows,
    rowsLabel = "Rows",
    rowsWithoutEmptyValuesByGroup,
    uniqueValues,
    approxRowsMatchedByGroup,
    denominator,
  }) => {
    if (!denominator) {
      const numbers = [
        ...[
          rowsWithoutEmptyValuesByGroup,
          uniqueValues,
          approxRowsMatchedByGroup,
        ].reduce((memo, statistics) => {
          return [
            ...memo,
            ...((statistics &&
              statistics?.length > 1 &&
              statistics?.map(({ value }: { value: number }) => value)) ||
              []),
          ];
        }, [] as number[]),
        rows || 0,
      ];
      denominator = Math.max(...numbers);
    }
    return (
      <Box sx={{ backgroundColor: `var(--joy-palette-${color}-50)`, p: 2 }}>
        <Typography
          level="title-md"
          mb={1}
          sx={{ color: `var(--joy-palette-${color}-700)` }}
        >
          <strong>{title}</strong>
        </Typography>
        <Box sx={{ mb: 1, position: "relative" }}>
          <Box sx={{ position: "absolute", zIndex: 1 }}>
            <Typography
              level="body-sm"
              style={{
                color: "white",
                lineHeight: "1.5rem",
                padding: "0 0.25rem",
                textShadow: "0 0 2px rgba(0,0,0,0.5)",
              }}
            >
              <strong>{rows || 0}</strong> — {rowsLabel}
            </Typography>
          </Box>
          <Box>
            <LinearProgress
              determinate={true}
              sx={{
                color: `var(--joy-palette-${color}-700)`,
                height: "1.5rem",
              }}
              value={denominator ? ((rows || 0) / denominator) * 100 : 0}
            />
          </Box>
        </Box>
        {approxRowsMatchedByGroup && approxRowsMatchedByGroup?.length > 1
          ? approxRowsMatchedByGroup?.map(({ id, value }, index) => (
              <Box key={index} sx={{ mb: 1, position: "relative" }}>
                <Box sx={{ position: "absolute", zIndex: 1 }}>
                  <Typography
                    level="body-sm"
                    style={{
                      color: "white",
                      lineHeight: "1.5rem",
                      padding: "0 0.25rem",
                      textShadow: "0 0 2px rgba(0,0,0,0.5)",
                    }}
                  >
                    <strong>{value}</strong> — Approx. matched rows in '
                    {idToNameAndMaybeTooltip(id)}'
                  </Typography>
                </Box>
                <Box>
                  <LinearProgress
                    determinate={true}
                    sx={{
                      color: `var(--joy-palette-${color}-500)`,
                      height: "1.5rem",
                    }}
                    value={denominator ? ((value || 0) / denominator) * 100 : 0}
                  />
                </Box>
              </Box>
            ))
          : null}
        {rowsWithoutEmptyValuesByGroup?.map(({ id, value }, index) => (
          <Box key={index} sx={{ mb: 1, position: "relative" }}>
            <Box sx={{ position: "absolute", zIndex: 1 }}>
              <Typography
                level="body-sm"
                style={{
                  color: "white",
                  lineHeight: "1.5rem",
                  padding: "0 0.25rem",
                  textShadow: "0 0 2px rgba(0,0,0,0.5)",
                }}
              >
                <strong>{value}</strong> — Rows without empty values in '
                {idToNameAndMaybeTooltip(id)}'
              </Typography>
            </Box>
            <Box>
              <LinearProgress
                determinate={true}
                sx={{
                  color: `var(--joy-palette-${color}-500)`,
                  height: "1.5rem",
                }}
                value={denominator ? ((value || 0) / denominator) * 100 : 0}
              />
            </Box>
          </Box>
        ))}
        {uniqueValues ? (
          <Fragment>
            {uniqueValues?.map(({ id, value }, index) => (
              <Box key={index} sx={{ mb: 1, position: "relative" }}>
                <Box sx={{ position: "absolute", zIndex: 1 }}>
                  <Typography
                    level="body-sm"
                    style={{
                      color: "white",
                      lineHeight: "1.5rem",
                      padding: "0 0.25rem",
                      textShadow: "0 0 2px rgba(0,0,0,0.5)",
                    }}
                  >
                    <strong>{value}</strong> — Unique values in '
                    {idToNameAndMaybeTooltip(id)}'
                  </Typography>
                </Box>
                <Box>
                  <LinearProgress
                    determinate={true}
                    sx={{
                      color: `var(--joy-palette-${color}-300)`,
                      height: "1.5rem",
                    }}
                    value={denominator ? ((value || 0) / denominator) * 100 : 0}
                  />
                </Box>
              </Box>
            ))}
          </Fragment>
        ) : null}
      </Box>
    );
  }
);
ResultStatisticsBlock.displayName = "ResultStatisticsBlock";

export default ResultStatisticsBlock;
