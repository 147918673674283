import { Box, Tooltip } from "@mui/joy";
import { memo } from "react";
import { Label } from "components";
import { MediaDataRoomActions } from "features/mediaDataRoom";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";

interface MediaDataRoomMainbarProps {
  id: string;
}

const MediaDataRoomMainbar: React.FC<MediaDataRoomMainbarProps> = memo(
  ({ id }) => {
    const { definition, isDeactivated, isStopped } = useMediaDataRoom();
    const { name } = definition ?? {};
    return (
      <Box>
        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "column",
            gridTemplateColumns: "minmax(0, 1fr) auto",
            justifyContent: "space-between",
            minHeight: "32px !important",
            mx: 1.25,
            my: 2,
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexDirection: "row",
              maxWidth: "100%",
              mr: 0.5,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
                maxWidth: "100%",
                p: 0.5,
              }}
            >
              {name}
              <Box sx={{ ml: 0.5, mt: "2px" }}>
                {isStopped ? (
                  <Tooltip title="This data clean room is in STOPPED state.">
                    <Label label="STOPPED" />
                  </Tooltip>
                ) : isDeactivated ? (
                  <Tooltip title="This data clean room is in DEACTIVATED state.">
                    <Label label="DEACTIVATED" />
                  </Tooltip>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <MediaDataRoomActions
                id={id}
                inline={false}
                withPerformanceReport={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default MediaDataRoomMainbar;
