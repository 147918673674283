import { DqTable } from "@decentriq/components";
import { faDownload, faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/joy";
import { format, isDate } from "date-fns";
import { saveAs } from "file-saver";
import { type MRT_ColumnDef } from "material-react-table";
import { parse as parseCsv } from "papaparse";
import { memo, useCallback, useMemo } from "react";

enum AuditLogRecordType {
  StopDataRoomRequest = "StopDataRoomRequest",
  RetrieveAuditLogRequest = "RetrieveAuditLogRequest",
  ReturningQueryResults = "ReturningQueryResults",
  SqlQueryRequest = "SqlQueryRequest",
  CreateDataRoomRequest = "CreateDataRoomRequest",
  PublishDatasetToDataRoomRequest = "PublishDatasetToDataRoomRequest",
}

const auditLogRecordPriority: AuditLogRecordType[] = [
  AuditLogRecordType.CreateDataRoomRequest,
  AuditLogRecordType.PublishDatasetToDataRoomRequest,
  AuditLogRecordType.SqlQueryRequest,
  AuditLogRecordType.ReturningQueryResults,
  AuditLogRecordType.StopDataRoomRequest,
  AuditLogRecordType.RetrieveAuditLogRequest,
];

interface DataRoomLogsPreviewProps {
  logs?: string;
  asTable?: boolean;
  onFetchLogs?: () => void;
  isLoading?: boolean;
}

const DataRoomLogsPreview: React.FC<DataRoomLogsPreviewProps> = memo(
  ({ logs, asTable = false, onFetchLogs, isLoading }) => {
    const handleDownload = useCallback((logs?: string) => {
      // TODO: remove sorting when that will be implemented in enclave
      const logsData = (logs?.split("\n") || []).reverse();
      const file = new File([logsData.join("\n")], `Audit.csv`, {
        type: "application/octet-stream;charset=utf-8",
      });
      saveAs(file);
    }, []);
    const hasLogs = logs && logs.trim().length;
    const logsData = useMemo<{ data: string[][]; rowLength: number }>(() => {
      if (asTable && logs) {
        const { data, errors } = parseCsv<string[]>(logs);
        if (!errors.length) {
          return {
            data: data
              .filter((r) => r.some((e) => e))
              .map((r) => {
                const timestamp = r[0];
                const currentTimestamp = Date.now().toString();
                const date = new Date(
                  Number(timestamp) *
                    (currentTimestamp.length === timestamp.length ? 1 : 1000)
                );
                if (isDate(date)) {
                  r[0] = date.getTime().toString();
                } else {
                  r[0] = "";
                }
                return r;
              })
              // TODO: remove sorting when that will be implemented in enclave
              .sort((a, b) => {
                const diff = Number(b[0]) - Number(a[0]);
                if (diff === 0) {
                  const aType = a[3];
                  const bType = b[3];
                  return (
                    auditLogRecordPriority.indexOf(
                      bType as AuditLogRecordType
                    ) -
                    auditLogRecordPriority.indexOf(aType as AuditLogRecordType)
                  );
                }
                return diff;
              }),
            rowLength: 3,
          };
        }
        return { data: [], rowLength: 0 };
      }
      return { data: [], rowLength: 0 };
    }, [logs, asTable]);
    const data = logsData.data as [string, string, string][];
    const columns: MRT_ColumnDef<[string, string, string]>[] = [
      {
        Cell: ({ renderedCellValue: value }) =>
          value
            ? format(new Date(Number(value)), "HH:mm:ss dd-MM-yyyy")
            : "Invalid date",
        accessorFn: (row) => row[0],
        grow: false,
        header: "Event date",
        id: "timestamp",
        size: 170,
      },
      {
        accessorFn: (row) => row[1],
        grow: false,
        header: "Performed by",
        id: "user",
        size: 300,
      },
      {
        accessorFn: (row) => row[2],
        header: "Description",
        id: "event",
      },
    ];
    return (
      <Stack>
        <Typography level="body-md">
          The audit log tracks all operations by any participant in this data
          clean room.
        </Typography>
        <Stack direction="row">
          <Button
            disabled={isLoading}
            loading={isLoading}
            loadingPosition="start"
            onClick={onFetchLogs}
            startDecorator={<FontAwesomeIcon icon={faSync} />}
          >
            Refresh
          </Button>
          {hasLogs ? (
            <Button
              disabled={!hasLogs}
              onClick={() => handleDownload(logs)}
              startDecorator={<FontAwesomeIcon icon={faDownload} />}
            >
              Download
            </Button>
          ) : null}
        </Stack>
        {asTable ? (
          <DqTable
            columns={columns}
            data={data}
            muiTablePaperProps={{ sx: { display: "flex" } }}
          />
        ) : (
          <div style={{ flex: "1", overflowY: "scroll" }}>
            <textarea
              defaultValue={logs}
              readOnly={true}
              style={{
                maxWidth: "100%",
                minHeight: "5rem",
                minWidth: "100%",
              }}
            />
          </div>
        )}
      </Stack>
    );
  }
);
DataRoomLogsPreview.displayName = "DataRoomLogsPreview";

export default DataRoomLogsPreview;
