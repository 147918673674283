import { useOrganizationFeaturesQuery } from "@decentriq/graphql/dist/hooks";
import {
  DataSourceType,
  DataTargetType,
  FeatureVisibilityState,
} from "@decentriq/graphql/dist/types";
import { useMemo } from "react";

interface OrganizationFeaturesHookPayload {
  organizationId: string | null;
  skip?: boolean;
}

type ImportExportConnectorsFeatureState =
  | {
      isDisabled: true;
      isEnabled: false;
      isHidden: false;
    }
  | {
      isDisabled: false;
      isEnabled: true;
      isHidden: false;
    }
  | {
      isDisabled: false;
      isEnabled: false;
      isHidden: true;
    };

interface OrganizationFeaturesHookResult {
  importConnectors: Record<DataSourceType, ImportExportConnectorsFeatureState>;
  exportConnectors: Record<DataTargetType, ImportExportConnectorsFeatureState>;
  loading: boolean;
}

const flattenFeatureVisibilityState = (
  state: FeatureVisibilityState | undefined
): ImportExportConnectorsFeatureState =>
  ({
    isDisabled: !state || state === FeatureVisibilityState.Disabled,
    isEnabled: state === FeatureVisibilityState.Enabled,
    isHidden: state === FeatureVisibilityState.Hidden,
  }) as ImportExportConnectorsFeatureState;

const useOrganizationFeatures = ({
  organizationId,
  skip,
}: OrganizationFeaturesHookPayload): OrganizationFeaturesHookResult => {
  const { data, loading } = useOrganizationFeaturesQuery({
    skip: skip || !organizationId,
    variables: {
      organizationId: organizationId!,
    },
  });
  return useMemo(() => {
    const organizationFeatures = data?.organization;
    return {
      exportConnectors: {
        [DataTargetType.Adform]: flattenFeatureVisibilityState(
          organizationFeatures?.adformExportState
        ),
        [DataTargetType.Azure]: flattenFeatureVisibilityState(
          organizationFeatures?.azureExportState
        ),
        [DataTargetType.GoogleAdManager]: flattenFeatureVisibilityState(
          organizationFeatures?.googleAdManagerExportState
        ),
        [DataTargetType.GoogleAds]: flattenFeatureVisibilityState(
          organizationFeatures?.googleAdsExportState
        ),
        [DataTargetType.GoogleCloudStorage]: flattenFeatureVisibilityState(
          organizationFeatures?.googleCloudStorageExportState
        ),
        [DataTargetType.GoogleDv_360]: flattenFeatureVisibilityState(
          organizationFeatures?.googleDv360ExportState
        ),
        [DataTargetType.Meta]: flattenFeatureVisibilityState(
          organizationFeatures?.metaExportState
        ),
        [DataTargetType.MicrosoftDsp]: flattenFeatureVisibilityState(
          organizationFeatures?.microsoftDspExportState
        ),
        [DataTargetType.Permutive]: flattenFeatureVisibilityState(
          organizationFeatures?.permutiveExportState
        ),
        [DataTargetType.S3]: flattenFeatureVisibilityState(
          organizationFeatures?.s3ExportState
        ),
        [DataTargetType.Snapchat]: flattenFeatureVisibilityState(
          organizationFeatures?.snapchatExportState
        ),
        [DataTargetType.Splicky]: flattenFeatureVisibilityState(
          organizationFeatures?.splickyExportState
        ),
        [DataTargetType.Sportradar]: flattenFeatureVisibilityState(
          organizationFeatures?.sportradarExportState
        ),
        [DataTargetType.Tiktok]: flattenFeatureVisibilityState(
          organizationFeatures?.tiktokExportState
        ),
        [DataTargetType.TradeDesk]: flattenFeatureVisibilityState(
          organizationFeatures?.tradedeskExportState
        ),
      },
      importConnectors: {
        [DataSourceType.Azure]: flattenFeatureVisibilityState(
          organizationFeatures?.azureImportState
        ),
        [DataSourceType.Compute]: flattenFeatureVisibilityState(
          organizationFeatures?.computeImportState
        ),
        [DataSourceType.Databricks]: flattenFeatureVisibilityState(
          organizationFeatures?.databricksImportState
        ),
        [DataSourceType.GoogleCloudStorage]: flattenFeatureVisibilityState(
          organizationFeatures?.googleCloudStorageImportState
        ),
        [DataSourceType.Local]: flattenFeatureVisibilityState(
          organizationFeatures?.localImportState
        ),
        [DataSourceType.Permutive]: flattenFeatureVisibilityState(
          organizationFeatures?.permutiveImportState
        ),
        [DataSourceType.S3]: flattenFeatureVisibilityState(
          organizationFeatures?.s3ImportState
        ),
        [DataSourceType.Salesforce]: flattenFeatureVisibilityState(
          organizationFeatures?.salesforceImportState
        ),
        [DataSourceType.Snowflake]: flattenFeatureVisibilityState(
          organizationFeatures?.snowflakeImportState
        ),
      },
      loading,
    };
  }, [data?.organization, loading]);
};

export default useOrganizationFeatures;
