import { DqEmptyData, DqTable } from "@decentriq/components";
import {
  type DataPartnerUsageConfigurationFragment,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/joy";
import { type TableRowProps } from "@mui/material";
import { type MRT_ColumnDef } from "material-react-table";
import { Fragment, memo, useMemo } from "react";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { CollaborationTypes } from "models";
import DeleteUsageConfigurationButton from "../DeleteUsageConfigurationButton/DeleteUsageConfigurationButton";

const UsageConfigurationsTable = memo(() => {
  const { usageConfigurations, displayConfiguration } = useDataPartnerPortal();
  const hasDisplayConfiguration = Boolean(displayConfiguration);
  const columns: MRT_ColumnDef<DataPartnerUsageConfigurationFragment>[] =
    useMemo(() => {
      return [
        {
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="User defined name for the configuration. Not publicly visible, only within your organization.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "name",
          header: "Configuration name",
          id: "name",
          maxSize: 150,
        },
        {
          Cell: ({ cell, row }) => {
            const advertiserDomains = cell.getValue<string[]>() || [];
            const { matchAnyDomain, id } = row?.original || {};
            return matchAnyDomain
              ? "Match any domain"
              : advertiserDomains.map((domain, index) => (
                  <Fragment key={`${id}-match-${index}`}>
                    {index > 0 && <br />}
                    <span>{domain}</span>
                  </Fragment>
                ));
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="Advertisers registered to these domains will be able to create clean rooms using this config. If there is both a domain and global config, the domain config has precedence.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "advertiserDomains",
          header: "Advertiser",
          id: "advertiserDomains",
          maxSize: 150,
        },
        {
          Cell: ({ row }) => {
            const {
              allowInsights,
              allowLookalike,
              allowRetargeting,
              allowRuleBasedAudiences,
              allowExclusionTargeting,
            } = row?.original || {};
            const collaborationTypes = [
              allowInsights ? CollaborationTypes.Insights : undefined,
              allowRetargeting ? CollaborationTypes.Remarketing : undefined,
              allowLookalike ? CollaborationTypes.Lookalike : undefined,
              allowRuleBasedAudiences
                ? CollaborationTypes.RuleBasedAudiences
                : undefined,
              allowExclusionTargeting
                ? CollaborationTypes.ExclusionTargeting
                : undefined,
            ].filter(Boolean);
            const value = collaborationTypes.join(", ");
            return (
              <Tooltip title={value}>
                <span style={{ verticalAlign: "middle" }}>
                  {collaborationTypes.join(", ")}
                </span>
              </Tooltip>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="These are the DCR collaboration types that are available under this configuration. Other collaboration types will be greyed out in the data partner discovery menu and unavailable in the clean room creation dialog.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "allowInsights",
          header: "Collaboration types",
          id: "dcrFeatures",
        },
        {
          Cell: ({ row }) => {
            const { matchingIdFormat, matchingIdHashingAlgorithm } =
              row?.original || {};
            return matchingIdTypeAndHashingAlgorithmPresentation({
              matchingIdFormat,
              matchingIdHashingAlgorithm:
                matchingIdHashingAlgorithm as TableColumnHashingAlgorithm,
            });
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="The type of matching ID. The data you will upload into DCRs created by advertisers under this configuration must include this type of identifier.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "matchingIdFormat",
          header: "Matching ID",
          id: "matchingId",
        },
        {
          Cell: ({ cell, row }) => {
            const participants = cell.getValue<string[]>() || [];
            const { id } = row?.original || {};
            return (
              <div>
                {participants.map((participant, index) => (
                  <Fragment key={`${id}-participants-${index}`}>
                    {index > 0 && <br />}
                    <span>{participant}</span>
                  </Fragment>
                ))}
              </div>
            );
          },
          Header: ({ column }) => (
            <div>
              {column.columnDef.header}
              <span style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                &nbsp;
                <Tooltip title="These are the users from your organization that will be invited to newly created clean rooms using this configuration. These users will have permission to upload your data into the DCR.">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </span>
            </div>
          ),
          accessorKey: "participants",
          header: "Accounts",
          id: "participants",
        },
      ];
    }, []);

  if (usageConfigurations.length === 0) {
    return (
      <DqEmptyData
        title={`No usage configurations found. ${hasDisplayConfiguration ? "Please create one." : "Please create first a display configuration and then a usage configuration."}`}
      />
    );
  }
  return (
    <DqTable
      columns={columns}
      data={usageConfigurations}
      enableRowActions={true}
      muiTableBodyRowProps={({ row }) =>
        ({
          "data-testid":
            testIds.dataPartnerPortal.usageConfigurations.recordHelper +
            row.original.name.toLowerCase(),
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": { pr: 2 },
          },
        }) as TableRowProps
      }
      muiTableHeadRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": {
            "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
              right: "-1rem",
            },
            pr: 2,
          },
        },
      }}
      muiTableProps={{
        style: {
          "--col-mrt_row_actions-size": 36 + 16,
          "--header-mrt_row_actions-size": 36 + 16,
        } as React.CSSProperties,
      }}
      renderRowActions={({
        row: {
          original: { id, name },
        },
      }) => <DeleteUsageConfigurationButton id={id} name={name} />}
    />
  );
});
UsageConfigurationsTable.displayName = "UsageConfigurationsTable";

export default UsageConfigurationsTable;
