import { Stack, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo } from "react";
import {
  DataPartnerDisplayConfigurationTable,
  PublisherDisplayConfigurationTable,
} from "../components";

const PublisherPortalOverviewTabs = memo(() => {
  return (
    <Stack flex={1} padding={2}>
      <Tabs sx={{ flex: 1 }}>
        <TabList>
          <Tab value={0}>Publisher Display Configurations</Tab>
          <Tab value={1}>Data Partner Display Configurations</Tab>
        </TabList>
        <TabPanel sx={{ flex: 1 }} value={0}>
          <PublisherDisplayConfigurationTable />
        </TabPanel>
        <TabPanel sx={{ flex: 1 }} value={1}>
          <DataPartnerDisplayConfigurationTable />
        </TabPanel>
      </Tabs>
    </Stack>
  );
});

PublisherPortalOverviewTabs.displayName = "PublisherPortalOverviewTabs";

export default PublisherPortalOverviewTabs;
