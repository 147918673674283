import { Alert, Box, Link, styled, Typography } from "@mui/joy";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useApiCore } from "contexts";
import { useDocsLink } from "hooks";
import AccessTokensConstructor from "./AccessTokensConstructor/AccessTokensConstructor";

const DocsLink = styled("a")({
  alignItems: "baseline",
  color: "inherit",
});

const AccessTokens: React.FC = () => {
  const { isMigrated } = useApiCore();
  const docsBaseLink = useDocsLink();
  const content = useMemo(() => {
    if (isMigrated) {
      return (
        <>
          <Typography level="title-md" marginBottom={1}>
            API tokens (old)
          </Typography>
          <Alert color="warning" sx={{ mb: 2 }}>
            <Typography color="warning" level="body-sm">
              The tokens listed below were generated using the previous
              authentication system. Please be aware that this page will soon be
              deprecated.{" "}
              <Link
                color="warning"
                component={RouterLink}
                to="/tokens"
                underline="always"
              >
                Check here the new tokens
              </Link>
              .
            </Typography>
          </Alert>
        </>
      );
    } else {
      return (
        <>
          <Typography level="title-md" marginBottom={1}>
            Access tokens
          </Typography>
          <Typography level="body-md" marginBottom={0.5}>
            The access tokens created on this page can be used in our SDKs to
            authenticate with the Decentriq Platform.
          </Typography>
          <Typography level="body-md" marginBottom={2}>
            For more details, please refer to the{" "}
            <DocsLink
              href={`${docsBaseLink}/sdk/getting-started`}
              rel="noreferrer"
              target="_blank"
            >
              Python SDK documentation
            </DocsLink>
            .
          </Typography>
        </>
      );
    }
  }, [docsBaseLink, isMigrated]);
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      {content}
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
