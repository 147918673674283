import { OrganizationState } from "@decentriq/graphql/dist/types";
import { Option, Select } from "@mui/joy";
import { capitalize } from "lodash";
import { memo, useCallback } from "react";

interface OrganizationStateFilterProps {
  onChange: (value: OrganizationState | null) => void;
  value: OrganizationState | null;
}

const OrganizationStateFilter = memo<OrganizationStateFilterProps>(
  ({ onChange, value }) => {
    const handleOrganizationStateChange = useCallback(
      (
        event: React.SyntheticEvent<Element, Event> | null,
        value: OrganizationState | null
      ) => {
        onChange(value);
      },
      [onChange]
    );
    return (
      <Select onChange={handleOrganizationStateChange} value={value}>
        {Object.values(OrganizationState).map((state) => {
          return (
            <Option key={state} value={state}>
              {`${capitalize(state)} organizations`}
            </Option>
          );
        })}
        <Option value={null}>All</Option>
      </Select>
    );
  }
);
OrganizationStateFilter.displayName = "OrganizationStateFilter";

export default OrganizationStateFilter;
