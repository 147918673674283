import { DqLoader } from "@decentriq/components";
import { Stack } from "@mui/joy";
import { memo, useMemo } from "react";
import { Navigate, useResolvedPath, useRoutes } from "react-router-dom";
import { MediaDataRoomActivation } from "features/mediaDataRoom/components";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import {
  MediaDataRoomActivationTab,
  MediaDataRoomTab,
  mediaDataRoomTabs,
} from "features/mediaDataRoom/models";
import {
  AdvertiserAudienceGenerator,
  AdvertiserAudienceGeneratorWrapper,
} from "../AdvertiserAudienceGenerator";
import { LookalikeAdvertiserAudienceGenerator } from "../LookalikeAdvertiserAudienceGenerator";
import { RulesBasedAdvertiserAudienceGenerator } from "../RulesBasedAdvertiserAudienceGenerator";
import MediaDataRoomTabs from "./MediaDataRoomTabs";

const MediaDataRoomActiveTab = memo(() => {
  const { isAgency, isAdvertiser, loading } = useMediaDataRoom();
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const tabs = useMemo(
    () =>
      !loading
        ? [
            {
              element: (
                <Navigate
                  replace={true}
                  to={`${url}/${MediaDataRoomTab.data}`}
                />
              ),
              path: "/",
            },
            ...mediaDataRoomTabs.map((tab) => ({
              element: <MediaDataRoomTabs activeTab={tab} baseUrl={url} />,
              path: tab,
            })),
            {
              children: [
                ...(isAdvertiser || isAgency
                  ? [
                      {
                        element: (
                          <AdvertiserAudienceGeneratorWrapper>
                            <AdvertiserAudienceGenerator
                              LookalikeAudienceGenerator={
                                LookalikeAdvertiserAudienceGenerator
                              }
                              RulesBasedAudienceGenerator={
                                RulesBasedAdvertiserAudienceGenerator
                              }
                            />
                          </AdvertiserAudienceGeneratorWrapper>
                        ),
                        path: MediaDataRoomActivationTab.creation,
                      },
                    ]
                  : []),
                {
                  element: <MediaDataRoomActivation />,
                  path: "",
                },
              ],
              element: (
                <MediaDataRoomTabs
                  activeTab={MediaDataRoomTab.activation}
                  baseUrl={url}
                />
              ),
              path: MediaDataRoomTab.activation,
            },
            {
              element: (
                <Navigate
                  replace={true}
                  to={`${url}/${MediaDataRoomTab.data}`}
                />
              ),
              path: "*",
            },
          ]
        : [],
    [isAdvertiser, isAgency, loading, url]
  );
  const activeTab = useRoutes(tabs);
  if (loading) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader />
      </Stack>
    );
  }

  return activeTab;
});
MediaDataRoomActiveTab.displayName = "MediaDataRoomActiveTab";

export default MediaDataRoomActiveTab;
