import {
  useOrganizationNameQuery,
  useUpdateOrganizationNameMutation,
} from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const OrganizationNameEditor = memo(() => {
  const { organizationId, enableOrganizationNameEditor } =
    useOrganizationAdminConfiguration();
  const [organizationName, setOrganizationName] = useState<string>("");
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const [updateOrganizationNameMutation] = useUpdateOrganizationNameMutation({
    onCompleted: () => {
      enqueueSnackbar(`Organization name has been successfully updated.`);
    },
  });
  const { data: organizationNameData } = useOrganizationNameQuery({
    variables: { organizationId },
  });

  // Set default value
  useEffect(() => {
    const name = organizationNameData?.organization?.name;
    if (name) {
      setOrganizationName(name);
    }
  }, [organizationNameData]);

  const updateOrganizationName = useCallback(
    async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { target } = event;
      if (
        !target.value ||
        organizationNameData?.organization?.name === target.value
      )
        return;
      try {
        await updateOrganizationNameMutation({
          update: (cache, { data: { organization = {} } }: any) => {
            cache.modify({
              fields: {
                name: () => {
                  return organization?.update?.name;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            input: {
              id: organizationId,
              name: target.value,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Organization name could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [
      organizationNameData?.organization?.name,
      updateOrganizationNameMutation,
      organizationId,
      enqueueSnackbar,
    ]
  );

  const updateOrganizationNameOnEnter = useCallback(
    (event: any) => {
      const { target, keyCode } = event || {};
      if (keyCode !== 13 || !target.value) return;
      void updateOrganizationName(event);
    },
    [updateOrganizationName]
  );

  return (
    <FormControl>
      <FormLabel>Name</FormLabel>
      <Input
        disabled={enableOrganizationNameEditor}
        name="name"
        onBlur={updateOrganizationName}
        onChange={({ target }) => setOrganizationName(target.value)}
        onKeyDown={updateOrganizationNameOnEnter}
        placeholder="Name"
        value={organizationName}
      />
    </FormControl>
  );
});

OrganizationNameEditor.displayName = "OrganizationNameEditor";

export default OrganizationNameEditor;
