import { DqEmptyData } from "@decentriq/components";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { Button, Grid } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  OverlapStatistics,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";

const MediaDataroomDataOverlap = memo(() => {
  const form = useForm<{ audienceType: string }>({
    defaultValues: { audienceType: "" },
    mode: "onChange",
  });

  const {
    overlapStatistics: { error: overlapStatisticsError, retry },
  } = useMediaDataRoomInsightsData();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryOverlapStatistics = useCallback(async () => {
    setRetryInProgress(true);
    try {
      await retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [retry]);
  if (overlapStatisticsError) {
    return (
      <DqEmptyData
        icon={faTriangleExclamation}
        title="Overlap could not be retrieved."
      >
        <Button
          color="neutral"
          loading={retryInProgress}
          onClick={retryOverlapStatistics}
          variant="soft"
        >
          Retry
        </Button>
      </DqEmptyData>
    );
  }
  return (
    <Grid container={true} gap={1} md={7} mt={2} xs={12}>
      <FormProvider {...form}>
        <Grid md={7} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        <Grid xs={12}>
          <OverlapStatistics />
        </Grid>
      </FormProvider>
    </Grid>
  );
});

MediaDataroomDataOverlap.displayName = "MediaDataroomDataOverlap";

export default MediaDataroomDataOverlap;
