import { Stack } from "@mui/joy";
import { memo } from "react";
import { useFormContext } from "react-hook-form";
import AttributionItem from "./AttributionItem";

const lookBackWindow = [
  { label: "1 Day", value: 1 },
  { label: "7 Days", value: 7 },
  { label: "28 Days", value: 28 },
  { label: "91 Days", value: 91 },
];
const lookBackWindowWithNone = [
  { label: "None", value: 0 },
  { label: "1 Day", value: 1 },
  { label: "7 Days", value: 7 },
  { label: "28 Days", value: 28 },
  { label: "91 Days", value: 91 },
];

const attributions = [
  { label: "Last Click + Viewthrough", value: "last_click" },
  { label: "Even Credit", value: "even_credit" },
  { label: "First and Last", value: "first_last" },
];

const AttributionSelector: React.FC = memo(() => {
  const { watch } = useFormContext();
  const clickLookbackWindow = watch(
    "measurementConfiguration.clickLookbackWindow"
  );
  return (
    <Stack>
      <AttributionItem
        attributionLabel="Click Lookback Window"
        fieldName="measurementConfiguration.clickLookbackWindow"
        options={lookBackWindow}
      />
      <AttributionItem
        attributionLabel="View Lookback Window"
        fieldName="measurementConfiguration.viewLookbackWindow"
        options={lookBackWindowWithNone.map(({ label, value }) => {
          return {
            disabled: !!clickLookbackWindow && clickLookbackWindow < value,
            label,
            value,
          };
        })}
      />
      <AttributionItem
        attributionLabel="Attribution Rule"
        fieldName="measurementConfiguration.attributionRule"
        options={attributions}
      />
    </Stack>
  );
});
AttributionSelector.displayName = "AttributionSelector";

export default AttributionSelector;
