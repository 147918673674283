import { useDataRoomsListQuery } from "@decentriq/graphql/dist/hooks";
import { Grid, Stack } from "@mui/joy";
import { useLocalStorageState } from "ahooks";
import { memo } from "react";
import { UserSupportMenu } from "components";
import {
  DataRoomsBlock,
  EndUserTermsBlock,
  LearnMoreBlock,
  OtherPagesBlock,
  WelcomeBlock,
} from "../components";

const HomeScreen = memo(() => {
  // Ideally this logic should live in EndUserTermsBlock to keep single responsibility,
  // but the flag influences Grid params, therefore, it is located here
  const [isEndUserTermsBlockVisible, setIsEndUserTermsBlockVisible] =
    useLocalStorageState<boolean | undefined>("dq:isEndUserTermsBlockVisible", {
      defaultValue: true,
    });

  const {
    data: { dataRooms: { nodes: dataRoomsList = [] } = {} } = {},
    loading: isDataRoomsLoading,
  } = useDataRoomsListQuery({ fetchPolicy: "network-only" });

  return (
    <Stack alignItems="center" height="100%" maxWidth="lg" rowGap={2}>
      <Grid columnSpacing={2} container={true} rowSpacing={1} xs={12}>
        <Grid md={isEndUserTermsBlockVisible ? 7 : 12} xs={12}>
          <WelcomeBlock dataRoomsCount={dataRoomsList.length} />
        </Grid>
        {isEndUserTermsBlockVisible && (
          <Grid md={5} xs={12}>
            <EndUserTermsBlock
              onHide={() => setIsEndUserTermsBlockVisible(false)}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        columnSpacing={2}
        container={true}
        rowSpacing={{ md: 0, xs: 2 }}
        xs={12}
      >
        <Grid md={7} xs={12}>
          <DataRoomsBlock
            dataRooms={dataRoomsList}
            isDataRoomsLoading={isDataRoomsLoading}
          />
        </Grid>
        <Grid md={5} xs={12}>
          <OtherPagesBlock />
        </Grid>
      </Grid>
      <Grid columnSpacing={2} container={true} xs={12}>
        <LearnMoreBlock />
      </Grid>
      <UserSupportMenu />
    </Stack>
  );
});

HomeScreen.displayName = "HomeScreen";

export default HomeScreen;
