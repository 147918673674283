import { InfoTooltip } from "@decentriq/components";
import {
  useOrganizationComputeTimeoutQuery,
  useUpdateOrganizationComputeTimeoutMutation,
} from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { useDebounceFn } from "ahooks";
import { memo, useCallback, useEffect, useState } from "react";
import { useOrganizationAdminConfiguration } from "features/admin/contexts";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const OrganizationComputeTimeoutEditor = memo(() => {
  const { organizationId, enableComputeTimeoutEditor } =
    useOrganizationAdminConfiguration();
  const [computeTimeout, setComputeTimeout] = useState<number>(0);
  const { data: organizationComputeTimeoutData } =
    useOrganizationComputeTimeoutQuery({
      variables: { organizationId },
    });

  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const [updateOrganizationComputeTimeoutMutation] =
    useUpdateOrganizationComputeTimeoutMutation();

  const updateOrganizationComputeTimeout = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      if (isNaN(value)) {
        enqueueSnackbar("Invalid compute timeout", {
          context: getEffectiveErrorMessage("Invalid compute timeout"),
          variant: "error",
        });
      }

      void updateOrganizationComputeTimeoutMutation({
        variables: {
          input: {
            containerWorkerMaxRuntimeSeconds: Math.round(value * 3600),
            organizationId,
          },
        },
      });
    },
    [enqueueSnackbar, organizationId, updateOrganizationComputeTimeoutMutation]
  );

  const { run: updateOrganizationComputeTimeoutDebounced } = useDebounceFn(
    updateOrganizationComputeTimeout,
    {
      wait: 1000,
    }
  );

  useEffect(() => {
    const containerWorkerMaxRuntimeSeconds =
      organizationComputeTimeoutData?.organization?.quotas
        ?.containerWorkerMaxRuntimeSeconds ?? 0;
    if (containerWorkerMaxRuntimeSeconds) {
      const containerWorkerMaxRuntimeHours = parseFloat(
        (containerWorkerMaxRuntimeSeconds / 3600).toFixed(1)
      );
      setComputeTimeout(containerWorkerMaxRuntimeHours);
    }
  }, [
    organizationComputeTimeoutData?.organization?.quotas
      ?.containerWorkerMaxRuntimeSeconds,
  ]);

  const onComputeTimeoutChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Only allow numbers with up to 1 decimal place
      const value = event.target.value;
      if (/^\d*\.?\d{0,1}$/.test(value) || value === "") {
        updateOrganizationComputeTimeoutDebounced(event);
        setComputeTimeout(parseFloat(value) || 0);
      }
    },
    [updateOrganizationComputeTimeoutDebounced]
  );

  return (
    <FormControl>
      <FormLabel>
        Computation timeout (h)
        <InfoTooltip tooltip="This is the number of hours after which a computation is killed. Note that MDCR computations on large datasets may take many hours (we've seen up to 8h)." />
      </FormLabel>
      <Input
        disabled={!enableComputeTimeoutEditor}
        name="computeTimeout"
        onChange={onComputeTimeoutChange}
        placeholder="Computation timeout"
        slotProps={{
          input: {
            inputMode: "decimal",
            min: "1",
            step: "0.1",
          },
        }}
        sx={{
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
        type="number"
        value={computeTimeout}
      />
    </FormControl>
  );
});

OrganizationComputeTimeoutEditor.displayName =
  "OrganizationComputeTimeoutEditor";

export default OrganizationComputeTimeoutEditor;
