import { type AvailableDataPartnerFragment } from "@decentriq/graphql/dist/types";
import { type ActiveMarket } from "@decentriq/types";
import { createContext, useContext } from "react";

export interface CreationWizardDataPartnerContextValue {
  advertiserMarketFilters: (ActiveMarket & { selected: boolean })[];
  availableDataPartners: AvailableDataPartnerFragment[];
  hasSelectedDataPartner: boolean;
  hasSkippedSelection: boolean;
  inviteDataPartner: () => void;
  isAvailableDataPartnersLoading: boolean;
  selectDataPartner: (dataPartner: AvailableDataPartnerFragment | null) => void;
  selectedDataPartner: AvailableDataPartnerFragment | null;
  updateAdvertiserMarketFilters: (marketIds: string[]) => void;
}

export const CreationWizardDataPartnerContext =
  createContext<CreationWizardDataPartnerContextValue | null>(null);

export const CreationWizardDataPartnerContextProvider =
  CreationWizardDataPartnerContext.Provider;

export const useCreationWizardDataPartner = () => {
  const contextValue = useContext(CreationWizardDataPartnerContext);
  if (contextValue === null) {
    throw new Error(
      "useCreationWizardDataPartner must be used within a CreationWizardDataPartnerWrapper"
    );
  }
  return contextValue;
};
