import { DqCard, DqCardContent } from "@decentriq/components";
import {
  faCopy,
  faEye,
  faEyeSlash,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Stack, Tooltip, Typography } from "@mui/joy";
import { useBoolean } from "ahooks";
import { useSnackbar } from "notistack";
import { memo, useCallback } from "react";
import { type ApiToken } from "features/accessTokens/models";
import { useCopyToClipboard } from "hooks";

interface AccessTokensTileProps {
  token: ApiToken;
  onRevokeApiToken: (token: string) => Promise<void>;
}

const AccessTokensTile = memo<AccessTokensTileProps>(
  ({ token, onRevokeApiToken }) => {
    const [, copyToClipboard] = useCopyToClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const handleCopyToken = useCallback(() => {
      if (!token?.token) {
        return;
      }
      copyToClipboard(token.token);
      enqueueSnackbar("Token copied");
    }, [copyToClipboard, enqueueSnackbar, token?.token]);
    const handleRevokeToken = useCallback(
      () => onRevokeApiToken(token.token!),
      [onRevokeApiToken, token]
    );
    const [isTokenVisible, { setTrue: showToken, setFalse: hideToken }] =
      useBoolean(false);
    return (
      <DqCard>
        <DqCardContent alignItems="flex-start">
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack alignItems="center" direction="row" spacing={0.5}>
              <Typography level="body-md">{token.name}</Typography>
              <Tooltip
                title={isTokenVisible ? "Hide API token" : "Show API token"}
              >
                <IconButton
                  className="narrow"
                  onClick={isTokenVisible ? hideToken : showToken}
                >
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={isTokenVisible ? faEye : faEyeSlash}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack alignItems="center" direction="row" spacing={0}>
              <Tooltip title="Copy API token to clipboard">
                <IconButton className="narrow" onClick={handleCopyToken}>
                  <FontAwesomeIcon icon={faCopy} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Revoke API token">
                <IconButton className="narrow" onClick={handleRevokeToken}>
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Typography
            level="body-sm"
            sx={{
              color: isTokenVisible ? undefined : "transparent",
              textShadow: isTokenVisible
                ? undefined
                : "0 0 5px rgba(0,0,0,0.5)",
              transition: "all 0.2s ease-in-out",
            }}
          >
            {token.token}
          </Typography>
        </DqCardContent>
      </DqCard>
    );
  }
);

AccessTokensTile.displayName = "AccessTokensTile";

export default AccessTokensTile;
