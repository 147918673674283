// TODO: Fix joy migration
import { Box, CircularProgress, Typography } from "@mui/joy";
import { memo } from "react";
import TimeAgo from "react-timeago";
import { useComputeNodesVars } from "contexts";
import { useTimeFormatter } from "hooks";

interface ComputeNodeLastSavedProps {
  computeNodeId: string;
}

const ComputeNodeLastSaved: React.FC<ComputeNodeLastSavedProps> = memo(
  ({ computeNodeId }) => {
    const { readOnly } = useComputeNodesVars();
    const { updatedAt, isSaving } = {
      isSaving: false,
      updatedAt: new Date().toISOString(),
    }; // TODO: use correct query
    const timeFormatter = useTimeFormatter();
    return (
      <Box style={{ bottom: "8px", position: "absolute", right: "16px" }}>
        {updatedAt && !isSaving && !readOnly ? (
          <Typography
            level="body-sm"
            sx={{ color: "var(--joy-palette-neutral-500)" }}
          >
            Last saved <TimeAgo date={updatedAt} formatter={timeFormatter} />
          </Typography>
        ) : undefined}
        {isSaving && (
          <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />
        )}
      </Box>
    );
  }
);

ComputeNodeLastSaved.displayName = "ComputeNodeLastSaved";

export default ComputeNodeLastSaved;
