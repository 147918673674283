import { DqEmptyData } from "@decentriq/components";
import { Box, useTheme } from "@mui/joy";
import { Pie } from "@nivo/pie";
import { memo, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AutoSizer } from "react-virtualized";
import {
  type InsightsBasicViewFormValues,
  MediaDataRoomInsightsChartValueKeySelector,
  SuppressedSegmentsInformation,
  useOverlapInsightsAggregation,
} from "features/mediaDataRoom";

const GenderDonutChart: React.FC = () => {
  const { watch } = useFormContext<InsightsBasicViewFormValues>();
  const chartValueKey = watch("genderChartValueKey");

  const { palette } = useTheme();

  const {
    aggregationData: genderAggregationData,
    hasSuppressedValues,
    possibleValues,
    suppressedValues,
    hiddenValues,
    hasHiddenValues,
  } = useOverlapInsightsAggregation({ aggregation: ["gender"] });

  const GENDER_DONUT_CHART_COLORS = useMemo(
    () => [
      palette.chart["300"],
      palette.chart["500"],
      palette.chart["600"],
      palette.chart["700"],
      palette.chart["900"],
    ],
    [palette.chart]
  );

  // 2 genders with highest aggregationData[chartValueKey] value must have predefined colors,
  // but they should be picked up dynamically for other available genders
  // If more genders than colors defined - always take the last color from the array
  const getGenderAggregationColor = useCallback((index: number) => {
    return index > GENDER_DONUT_CHART_COLORS.length
      ? GENDER_DONUT_CHART_COLORS[GENDER_DONUT_CHART_COLORS.length]
      : GENDER_DONUT_CHART_COLORS[index];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pieChartData = genderAggregationData.map(
    ({ aggregationName, ...restAggregationData }, index) => ({
      color: getGenderAggregationColor(index),
      id: aggregationName,
      label: aggregationName,
      value: restAggregationData[chartValueKey],
    })
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <MediaDataRoomInsightsChartValueKeySelector
        suppressedSegmentsInfoComponent={
          <SuppressedSegmentsInformation
            hiddenValues={hiddenValues}
            possibleValues={possibleValues}
            suppressedValues={suppressedValues}
          />
        }
        title="Gender"
        withSuppressedSegments={hasSuppressedValues || hasHiddenValues}
      />
      {pieChartData.length ? (
        <Box flex={1}>
          <AutoSizer>
            {({ width, height }) => (
              <Pie
                activeOuterRadiusOffset={4}
                arcLinkLabelsDiagonalLength={6}
                arcLinkLabelsStraightLength={10}
                // TODO: create custom Nivo theme based on the brand colours
                colors={({ data }) => data?.color}
                data={pieChartData}
                height={height}
                innerRadius={0.5}
                margin={{ bottom: 10, top: 10 }}
                valueFormat=">-.1%"
                width={width}
              />
            )}
          </AutoSizer>
        </Box>
      ) : (
        <DqEmptyData title="No chart data available" />
      )}
    </Box>
  );
};

GenderDonutChart.displayName = "GenderDonutChart";

export default memo(GenderDonutChart);
