import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/joy";
import { memo } from "react";
import { useOrganizationPreferences } from "hooks";
import DataRoomCreate from "../DataRoomCreate/DataRoomCreate";

const DataRoomCreateButton = memo(() => {
  const { isAllowedToCreateDataRoom } = useOrganizationPreferences();
  return (
    <DataRoomCreate
      renderTrigger={(openDataRoomCreateDialog) => (
        <Tooltip
          title={
            !isAllowedToCreateDataRoom ? (
              <div>
                An active license is required to create new data clean rooms.
                Please check with your organization administrator or contact our
                support in case of questions.
              </div>
            ) : undefined
          }
        >
          <span>
            <Button
              color="primary"
              data-testid="new_dcr_button"
              disabled={!isAllowedToCreateDataRoom}
              onClick={openDataRoomCreateDialog}
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
              }
              variant="solid"
            >
              New data clean room
            </Button>
          </span>
        </Tooltip>
      )}
    />
  );
});
DataRoomCreateButton.displayName = "DataRoomCreateButton";

export default DataRoomCreateButton;
