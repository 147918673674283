import { faLock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/joy";
import { memo } from "react";
import { DataRoomParticipantAvatarGroup } from "components";
import useComputeNodeParticipants from "./useComputeNodeParticipants";

interface ComputeNodeParticipantsProps {
  computeNodeId: string;
  readOnly: boolean;
}

const ComputeNodeParticipants: React.FC<ComputeNodeParticipantsProps> = memo(
  ({ computeNodeId, readOnly }) => {
    const { participants, loading } = useComputeNodeParticipants(computeNodeId);
    if (readOnly && !loading && !participants.length) {
      return (
        <Tooltip
          placement="top-start"
          title="There are no Analysts with permissions to execute the computation"
        >
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faLock}
            style={{
              color: "var(--joy-palette-neutral-500)",
              marginRight: ".5rem",
            }}
          />
        </Tooltip>
      );
    }
    return <DataRoomParticipantAvatarGroup participants={participants} />;
  }
);

ComputeNodeParticipants.displayName = "ComputeNodeParticipants";

export default ComputeNodeParticipants;
