import {
  dataSourceTypePresentation,
  DqEmptyData,
  DqLoader,
  DqTable,
} from "@decentriq/components";
import { useDatasetsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type DatasetsQuery,
  type DataSourceType,
} from "@decentriq/graphql/dist/types";
import {
  faFile as falFile,
  faTable as falTable,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Skeleton, Stack, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import React, { memo, useCallback, useMemo, useState } from "react";
import { KeychainItemKind } from "services/keychain";
import { TimeAgoFormatted } from "components";
import {
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets/components";
import { DatasetsView } from "features/datasets/models";
import { formatSize } from "features/datasets/utils";
import {
  defaultDatasetKeychainItemKinds,
  useKeychainItems,
} from "hooks/keychain/useKeychainItems";
import { type DataType } from "models";
import { ellipsisify } from "utils";
import DatasetDrawer from "../DatasetDrawer/DatasetDrawer";
import DatasetActionsMenu from "./components/DatasetActionsMenu/DatasetActionsMenu";

type DatasetColumnDef = Omit<Dataset, "__typename">;
type Dataset = DatasetsQuery["datasets"]["nodes"][number] & {
  type: DataType | null;
  hasKeychainItem: boolean;
};
interface DatasetsListProps {
  view?: DatasetsView;
  customDatasetActions?: (manifestHash: string) => React.ReactNode;
}

const datasetTypeDisplayMap: Record<
  DataType,
  { icon: IconDefinition; label: string }
> = {
  table: {
    icon: falTable,
    label: "Table",
  },
  unstructured: {
    icon: falFile,
    label: "File",
  },
};

const DatasetsList = memo<DatasetsListProps>(
  ({ view, customDatasetActions }) => {
    const { data: datasetsData, loading: isDatasetsDataLoading } =
      useDatasetsQuery({
        fetchPolicy: "network-only",
      });
    const {
      items: keychainItems,
      hasDatasetMetadataItem,
      loading: isKeychainDataLoading,
    } = useKeychainItems({
      kinds: defaultDatasetKeychainItemKinds,
    });
    const hasKeychainItem = useCallback(
      (manifestHash: string): boolean =>
        keychainItems.some(
          ({ id, kind }) =>
            manifestHash === id && kind === KeychainItemKind.Dataset
        ),
      [keychainItems]
    );
    // Not blocking the rendering of the table while the keychain data is loading for all datasets view its a temporary solution to avoid waiting on enclave requests that take long to execute and we don't have a way to get all secrets at once or limited with current approach to do otherwise
    const loading =
      isDatasetsDataLoading ||
      (view === DatasetsView.KeychainOnly && isKeychainDataLoading);
    const { datasets, totalCount } = useMemo(() => {
      if (!datasetsData?.datasets?.nodes?.length) {
        return {
          datasets: [],
          totalCount: 0,
        };
      }
      const datasets: Dataset[] = datasetsData?.datasets?.nodes
        .slice()
        .map((dataset) => ({
          ...dataset,
          hasKeychainItem: hasKeychainItem(dataset.manifestHash),
          type: isKeychainDataLoading
            ? null
            : ((hasDatasetMetadataItem(dataset.manifestHash)
                ? "table"
                : "unstructured") as DataType),
        }))
        .filter(({ hasKeychainItem }) => {
          return (
            !view ||
            view === DatasetsView.All ||
            (view === DatasetsView.KeychainOnly && hasKeychainItem)
          );
        });

      return {
        datasets,
        totalCount: datasets.length,
      };
    }, [
      datasetsData?.datasets?.nodes,
      hasKeychainItem,
      hasDatasetMetadataItem,
      isKeychainDataLoading,
      view,
    ]);
    const [selectedManifestHash, setSelectedManifestHash] = useState<
      string | null
    >(null);
    const selectedDataset = useMemo(
      () =>
        datasets.find(
          ({ manifestHash }) => manifestHash === selectedManifestHash
        ) ?? null,
      [datasets, selectedManifestHash]
    );
    const datasetsColumnDef: MRT_ColumnDef<DatasetColumnDef>[] = useMemo(
      () => [
        {
          accessorFn: ({ name }) => ellipsisify(name, 35),
          accessorKey: "name",
          header: "Name",
          id: "name",
          size: 200,
        },
        {
          Cell: ({ cell }) => {
            const datasetType = cell.getValue<DataType | null>();
            if (!datasetType) {
              return (
                <Stack height="100%" position="relative" width="20%">
                  <Skeleton />
                </Stack>
              );
            }
            const { icon, label } = datasetTypeDisplayMap[datasetType];
            return (
              <Stack alignItems="center" direction="row" gap={1}>
                <FontAwesomeIcon fixedWidth={true} icon={icon} />
                <span>{label}</span>
              </Stack>
            );
          },
          accessorKey: "type",
          header: "Type",
        },
        {
          Cell: ({ cell }) => {
            const createdAt = cell.getValue<string>();
            return createdAt ? (
              <TimeAgoFormatted
                date={createdAt}
                style={{ whiteSpace: "nowrap" }}
              />
            ) : (
              "—"
            );
          },
          accessorKey: "createdAt",
          header: "Upload date",
          id: "createdAt",
          size: 100,
        },
        {
          Cell: ({ cell }) => {
            const sourceType = cell.getValue<DataSourceType>();
            return (
              <Stack alignItems="center" direction="row" gap={1}>
                <ExternalConnectionsIcon
                  connectionType={sourceType}
                  size={ExternalConnectionsIconSize.xs}
                />
                <Typography level="body-sm">
                  {dataSourceTypePresentation[sourceType]}
                </Typography>
              </Stack>
            );
          },
          accessorKey: "sourceType",
          header: "Imported from",
          id: "sourceType",
          size: 100,
        },
        {
          Cell: ({ cell }) => formatSize(cell.getValue<number>()),
          accessorKey: "size",
          header: "File size",
          id: "size",
          size: 100,
        },
      ],
      []
    );
    if (loading) {
      return (
        <Stack alignItems="center" height="100%" justifyContent="center">
          <DqLoader size="sm" />
        </Stack>
      );
    }
    if (!loading && !totalCount) {
      return (
        <DqEmptyData
          // This is workaround to keep same height for empty data between two dataset tabs
          description="&nbsp;"
          title="No datasets have been provisioned to any data clean rooms so far"
        />
      );
    }
    return (
      <Box
        sx={{
          alignItems: "stretch",
          backgroundColor: "common.white",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "stretch",
          overflow: "hidden",
        }}
      >
        <DqTable
          columns={datasetsColumnDef}
          data={datasets}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              enableResizing: false,
              grow: false,
              header: customDatasetActions ? undefined : "Actions",
              muiTableBodyCellProps: {
                align: "left",
                onClick: (event) => event.stopPropagation(),
                sx: {
                  minWidth:
                    "max(calc(var(--col-mrt_row_actions-size)* 1px), 36px)",
                },
              },
              muiTableHeadCellProps: {
                align: "left",
                sx: {
                  fontWeight: "semiBold",
                  minWidth:
                    "max(calc(var(--header-mrt_row_actions-size)* 1px), 36px)",
                },
              },
              size: 160,
            },
          }}
          enableBatchRowSelection={false}
          enableMultiRowSelection={false}
          enableRowActions={true}
          enableRowSelection={true}
          enableSelectAll={false}
          enableSorting={true}
          enableTopToolbar={false}
          getRowId={(row) => row.manifestHash}
          initialState={{
            sorting: [{ desc: true, id: "createdAt" }],
          }}
          localization={{
            noRecordsToDisplay: "No datasets found",
          }}
          muiTableBodyRowProps={({
            row: {
              original: { manifestHash },
              getToggleSelectedHandler,
            },
          }) => {
            return {
              onClick: () => {
                getToggleSelectedHandler();
                setSelectedManifestHash(manifestHash);
              },
              sx: {
                "& > .MuiTableCell-root:first-child": { pl: 2 },
                "& > .MuiTableCell-root:last-child": { pr: 2 },
                cursor: "pointer",
              },
            };
          }}
          muiTableHeadRowProps={{
            sx: {
              "& > .MuiTableCell-root:first-child": { pl: 2 },
              "& > .MuiTableCell-root:last-child": {
                "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                  right: "-1rem",
                },
                pr: 2,
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              width: "100%",
            },
          }}
          muiTopToolbarProps={{
            sx: {
              borderBottom: "1px solid",
              borderColor: "divider",
              flex: "0 0 auto",
              minHeight: "auto",
            },
          }}
          renderRowActions={({ row }) =>
            customDatasetActions ? (
              customDatasetActions(row.original.manifestHash)
            ) : (
              <DatasetActionsMenu manifestHash={row.original.manifestHash} />
            )
          }
          state={{
            columnVisibility: { "mrt-row-select": false },
            rowSelection: selectedManifestHash
              ? { [selectedManifestHash]: true }
              : {},
          }}
        />
        <DatasetDrawer
          manifestHash={selectedManifestHash}
          onClose={() => setSelectedManifestHash(null)}
          open={Boolean(selectedDataset)}
        />
      </Box>
    );
  }
);
DatasetsList.displayName = "DatasetsList";

export default DatasetsList;
