import { useAuth0 } from "@auth0/auth0-react";
import { matchingIdTypeToGqlValues } from "@decentriq/models";
import { MediaDataRoomUserRole, type RawMatchingID } from "@decentriq/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, type PropsWithChildren, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { useCreationWizardConfiguration } from "../DqCreateMediaDcrWizard/contexts";
import { schema, type SchemaType } from "./model";

const DqCreateMediaDcrFormProvider: React.FC<PropsWithChildren> = memo(
  ({ children }) => {
    const {
      participantsEmails,
      dataRoomName,
      matchingIdFormat,
      matchingIdHashingAlgorithm,
      withAgency,
      withObserver,
      withDataPartner,
    } = useCreationWizardConfiguration();
    const selectedMatchingId =
      Object.entries(matchingIdTypeToGqlValues).find(
        ([_, [format, algorithm]]) =>
          format === matchingIdFormat &&
          algorithm === matchingIdHashingAlgorithm
      )?.[0] ?? "";
    const defaultValues: SchemaType = useMemo(
      () => ({
        advertiserEmails: participantsEmails.get(
          MediaDataRoomUserRole.Advertiser
        )!,
        agencyEmails: participantsEmails.get(MediaDataRoomUserRole.Agency)!,
        dataPartnerEmails: participantsEmails.get(
          MediaDataRoomUserRole.DataPartner
        )!,
        enableAdvertiserAudienceDownload: false,
        enabledFeatures: [],
        matchingId: selectedMatchingId as RawMatchingID,
        name: dataRoomName,
        observerEmails: participantsEmails.get(MediaDataRoomUserRole.Observer)!,
        publisherEmails: participantsEmails.get(
          MediaDataRoomUserRole.Publisher
        )!,
        showAbsoluteAudienceSizes: true,
      }),
      [dataRoomName, participantsEmails, selectedMatchingId]
    );
    const { user: currentUser } = useAuth0();
    const { email: currentUserEmail } = currentUser || {};
    const form = useForm<SchemaType>({
      context: { currentUserEmail, withAgency, withDataPartner, withObserver },
      defaultValues,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: async (data, context, options) =>
        await zodResolver(
          schema.superRefine((items, ctx) => {
            if (
              context.currentUserEmail &&
              ![
                ...items.publisherEmails,
                ...items.advertiserEmails,
                ...items.dataPartnerEmails,
                ...items.observerEmails,
                ...items.agencyEmails,
              ].includes(context.currentUserEmail)
            ) {
              /*
                Last field of the participants is used to show the error message about current user being in the participants list.
                Potentially we can show it to all available fields.
              */
              const potentiallyLastParticipantsFieldKeys: (keyof SchemaType)[] =
                [
                  "advertiserEmails",
                  ...(context.withDataPartner
                    ? ["dataPartnerEmails" as const]
                    : []),
                  ...(context.withObserver ? ["observerEmails" as const] : []),
                  ...(context.withAgency ? ["agencyEmails" as const] : []),
                ];
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `You must also be a participant of this data clean room.`,
                path: [
                  potentiallyLastParticipantsFieldKeys[
                    potentiallyLastParticipantsFieldKeys.length - 1
                  ],
                ],
              });
            }
          })
        )(data, context, {
          ...options,
          /*
            NOTE: Clearing the names to force the nesting routine
            to use the validation error keys to determine nesting,
            since field arrays are not used and the names are "simple".
          */
          names: undefined,
        }),
    });
    const { reset, resetField } = form;
    /**
     * NOTE: Due to contexts having a few `useEffect`s have to recalculate
     * and reset to the fresh `defaultValues` until it stabilizises.
     */
    useEffect(() => {
      reset(defaultValues);
    }, [reset, defaultValues]);
    // These effects are used to reset the fields when the user changes the outside of the form configuration
    useEffect(() => {
      if (withObserver) {
        return;
      }
      resetField("observerEmails", { defaultValue: [] });
    }, [resetField, withObserver]);
    useEffect(() => {
      if (withAgency) {
        return;
      }
      resetField("agencyEmails", { defaultValue: [] });
    }, [resetField, withAgency]);
    return <FormProvider {...form}>{children}</FormProvider>;
  }
);
DqCreateMediaDcrFormProvider.displayName = "DqCreateMediaDcrFormProvider";

export default DqCreateMediaDcrFormProvider;
