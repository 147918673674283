import { sortNumericRange } from "@decentriq/utils";
import { orderBy } from "lodash";
import {
  type ActivationType,
  type AggregationData,
  type Audience,
  type MediaInsightsAudienceCacheKey,
  type PublisherDatasetsHashes,
} from "features/MediaInsightsDcr";

export const replaceDecentriqOrgName = (
  orgName: string | undefined,
  replacement: string
): string => (orgName === "Decentriq" || !orgName ? replacement : orgName);

export function getMediaInsightsAudienceCacheKey({
  activationType,
  audienceType,
  dataRoomId,
  activatedAudiences,
  reach,
  advertiserDatasetHash,
  publisherDatasetsHashes,
}: {
  activationType: ActivationType;
  audienceType: string;
  dataRoomId: string;
  activatedAudiences: Audience[];
  reach?: number;
  advertiserDatasetHash: string | null;
  publisherDatasetsHashes: PublisherDatasetsHashes;
}): MediaInsightsAudienceCacheKey | null {
  const publisherUsersDatasetHash =
    publisherDatasetsHashes.usersDatasetHash ?? null;
  if (
    advertiserDatasetHash === null ||
    publisherUsersDatasetHash === null ||
    activatedAudiences.length === 0
  ) {
    return null;
  }

  return {
    activatedAudiences: activatedAudiences.map(({ reach, ...rest }) => ({
      ...rest,
      reach: reach ?? 0,
    })),
    activationType,
    advertiserDatasetHash,
    audienceType,
    dataRoomId,
    publisherUsersDatasetHash,
    reach: reach ?? 0,
    ...(publisherDatasetsHashes.segmentsDatasetHash && {
      publisherSegmentsDatasetHash: publisherDatasetsHashes.segmentsDatasetHash,
    }),
    ...(publisherDatasetsHashes.demographicsDatasetHash && {
      publisherDemographicsDatasetHash:
        publisherDatasetsHashes.demographicsDatasetHash,
    }),
    ...(publisherDatasetsHashes.embeddingsDatasetHash && {
      publisherEmbeddingsDatasetHash:
        publisherDatasetsHashes.embeddingsDatasetHash,
    }),
  };
}

export const sortAgeAggregationData = (
  data: AggregationData[],
  order?: "asc" | "desc"
): AggregationData[] => {
  const aggregationDataAges: string[] = data.map((item) => item.age!);
  const sortedAges = sortNumericRange(aggregationDataAges);
  return orderBy(
    data,
    (item: AggregationData) => sortedAges.indexOf(item.age!),
    order || "asc"
  );
};
