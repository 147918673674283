import { DqEmptyData, DqLoader } from "@decentriq/components";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { Alert, Button, Stack } from "@mui/joy";
import { useCallback, useMemo, useState } from "react";
import { MediaDataRoomAudiencesTable } from "features/mediaDataRoom/components";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { type AudienceFilter } from "features/mediaDataRoom/models";
import {
  useAudiences,
  useAudiencesDataPrefetching,
} from "features/mediaDataRoom/wrappers";
import { logError } from "utils";
import MediaDataRoomAudiencesToolbar from "./MediaDataRoomAudiencesToolbar";

const MediaDataRoomActivation: React.FC = () => {
  const [filter, setFilter] = useState<AudienceFilter>("all");
  const [searchValue, setSearchValue] = useState("");
  const {
    isPublisher,
    isAdvertiser,
    isDeactivated,
    hasAgency,
    supportedFeatures: { enableRemarketing },
  } = useMediaDataRoom();
  const {
    datasets: {
      isLoading: isDataRoomDataLoading,
      data: { hasRequiredData },
      fetch: fetchDatasets,
    },
    isPublisherAudienceBeingUpdated,
  } = useMediaDataRoomInsightsData();
  const [isRetrying, setIsRetrying] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const {
    audiences,
    isAudiencesDataOutdated,
    canResetAudiences,
    resetAudiences,
  } = useAudiences();
  const { isDataPrefetching } = useAudiencesDataPrefetching();
  const loading = useMemo(() => {
    return (
      isDataRoomDataLoading ||
      audiences.loading ||
      audiences.status !== "COMPLETED"
    );
  }, [isDataRoomDataLoading, audiences.status, audiences.loading]);
  const isFiltered = useMemo(
    () => filter !== "all" || !!searchValue.trim(),
    [filter, searchValue]
  );
  const filteredAudiences = useMemo(() => {
    return audiences.computeResults?.filter((audience) => {
      const matchesStatus =
        !isPublisher || audience.mutable.status !== "published_as_intermediate";
      const matchesFilter = filter === "all" || audience.kind === filter;
      const matchesSearch = audience.mutable.name
        .toLowerCase()
        .trim()
        .includes(searchValue.toLowerCase().trim());
      const matchesFeature =
        audience.kind !== "advertiser" || enableRemarketing;
      return matchesStatus && matchesFilter && matchesSearch && matchesFeature;
    });
  }, [
    audiences.computeResults,
    filter,
    isPublisher,
    searchValue,
    enableRemarketing,
  ]);
  const handleRetry = useCallback(async () => {
    setIsRetrying(true);
    try {
      await audiences.retry();
    } finally {
      setIsRetrying(false);
    }
  }, [audiences, setIsRetrying]);
  const handleResetAudiences = useCallback(async () => {
    setIsResetting(true);
    try {
      const hashes = await fetchDatasets();
      await resetAudiences(hashes);
    } catch (error) {
      logError("Failed to reset audiences", error);
    } finally {
      setIsResetting(false);
    }
  }, [fetchDatasets, setIsResetting, resetAudiences]);
  if (isAudiencesDataOutdated) {
    if (!canResetAudiences) {
      return (
        <DqEmptyData
          title={`Datasets have been updated. Please wait until the advertiser 
            ${hasAgency ? "or the agency" : ""} updates the audiences.`}
        />
      );
    }
    return (
      <DqEmptyData title="Datasets have been updated.">
        <Button
          color="neutral"
          loading={isResetting}
          onClick={handleResetAudiences}
          variant="soft"
        >
          Refresh audiences and allow the creation of new ones
        </Button>
      </DqEmptyData>
    );
  }
  if (isPublisherAudienceBeingUpdated) {
    return (
      <DqEmptyData
        description={
          isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."
        }
        title="Publisher audience is being updated."
      />
    );
  }
  if (!isDataRoomDataLoading && !hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }
  if (loading || isDataPrefetching) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader
          label={
            isAdvertiser
              ? audiences.status === "COMPUTING"
                ? "Computing audiences..."
                : audiences.status === "FETCHING" || isDataPrefetching
                  ? "Retrieving audiences..."
                  : ""
              : isPublisher
                ? "Loading audiences..."
                : ""
          }
        />
      </Stack>
    );
  }
  if (audiences.error) {
    return (
      <DqEmptyData
        icon={faTriangleExclamation}
        title="Activated audiences could not be retrieved."
      >
        {!isDeactivated && (
          <Button
            color="neutral"
            loading={isRetrying}
            onClick={handleRetry}
            variant="soft"
          >
            Retry
          </Button>
        )}
      </DqEmptyData>
    );
  }
  return (
    <Stack height="100%">
      <MediaDataRoomAudiencesToolbar
        onFilterChange={setFilter}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        selectedFilter={filter}
      />
      <MediaDataRoomAudiencesTable
        audiences={filteredAudiences || []}
        isFiltered={isFiltered}
      />
    </Stack>
  );
};

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default MediaDataRoomActivation;
