import { memo } from "react";
import {
  Navigate,
  Outlet,
  useParams,
  useResolvedPath,
  useRoutes,
} from "react-router-dom";
import { DataLab } from "features/dataLabs";
import { PublisherPortal, PublisherPortalTabs } from "features/publisherPortal";

const DataLabWithIdFromParams = memo(() => {
  const { datalabId } = useParams();
  return <DataLab id={datalabId!} />;
});

const PublisherPortalPage = memo(() => {
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const element = useRoutes([
    {
      children: [
        {
          element: (
            <PublisherPortal
              activeTab={PublisherPortalTabs.DATA_LABS}
              baseUrl={url}
            />
          ),
          path: "",
        },
        {
          element: <DataLabWithIdFromParams />,
          path: ":datalabId",
        },
      ],
      element: <Outlet />,
      path: `${PublisherPortalTabs.DATA_LABS}/*`,
    },
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.PUBLISHER_CONFIGURATIONS,
    },
    {
      element: (
        <PublisherPortal
          activeTab={PublisherPortalTabs.DATA_LABS}
          baseUrl={url}
        />
      ),
      path: PublisherPortalTabs.DATA_LABS,
    },
    {
      element: (
        <Navigate
          to={`${url}/${PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}`}
        />
      ),
      path: "/",
    },
    {
      element: (
        <Navigate
          to={`${url}/${PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}`}
        />
      ),
      path: "*",
    },
  ]);
  return element;
});
PublisherPortalPage.displayName = "PublisherPortalPage";

export default PublisherPortalPage;
