import {
  type CollaborationTypes,
  type PublishMediaDataRoomInput,
} from "@decentriq/types";
import { Modal, modalDialogClasses } from "@mui/joy";
import { memo } from "react";
import DqCreateMediaDcrProgress from "./DqCreateMediaDcrProgress/DqCreateMediaDcrProgress";
import DqCreateMediaDcrWizard from "./DqCreateMediaDcrWizard/DqCreateMediaDcrWizard";
import {
  ConfigurationWrapper,
  DataPartnerWrapper,
  PublisherWrapper,
  StepperWrapper,
} from "./DqCreateMediaDcrWizard/wrappers";
/**
 * NOTE: `DqCreateMediaDcrProgress` should probably be deprecated as non-standard loading behaviour
 * (at the moment of this writing there's no spec for this yet). The wrappers should be composed
 * elsewhere, likely be a children of the `DqCreateMediaDcrWizard` component (keeping as is for now
 * as trying not to pivot too far from the current architecture).
 * TODO: Both things above must be clarified, resolved, and both this comment and note above removed.
 */

export interface MediaDcrCreateDialogProps {
  onCancel: () => void;
  open: boolean;
  publishing: boolean;
  publishingCalled: boolean;
  publishingError: unknown | undefined;
  restartCreation: () => void;
  sendCollaborationRequest: ({
    requestRecipientId,
    message,
    receiver,
  }: {
    requestRecipientId: string;
    message: string;
    receiver: "publisher" | "dataPartner";
  }) => Promise<void>;
  submit: (values: PublishMediaDataRoomInput) => Promise<void>;
  filterCollaborationTypes?: (
    value: CollaborationTypes,
    index: number,
    array: CollaborationTypes[]
  ) => boolean;
}

const MediaDcrCreateDialog = memo<MediaDcrCreateDialogProps>(
  ({
    onCancel,
    open,
    publishing,
    publishingCalled,
    publishingError,
    restartCreation,
    sendCollaborationRequest,
    submit,
    filterCollaborationTypes,
  }) => {
    return (
      <Modal
        onClose={onCancel}
        open={open}
        sx={{
          [`& .${modalDialogClasses.root}`]: {
            "--ModalDialog-maxWidth": "800px",
            "--ModalDialog-minWidth": "600px",
          },
        }}
      >
        <StepperWrapper restartCreation={restartCreation}>
          <PublisherWrapper>
            <DataPartnerWrapper>
              <ConfigurationWrapper
                filterCollaborationTypes={filterCollaborationTypes}
                sendCollaborationRequest={sendCollaborationRequest}
                submit={submit}
              >
                {publishing ? (
                  <DqCreateMediaDcrProgress
                    publishing={publishing}
                    publishingCalled={publishingCalled}
                    publishingError={publishingError}
                  />
                ) : (
                  <DqCreateMediaDcrWizard />
                )}
              </ConfigurationWrapper>
            </DataPartnerWrapper>
          </PublisherWrapper>
        </StepperWrapper>
      </Modal>
    );
  }
);
MediaDcrCreateDialog.displayName = "MediaDcrCreateDialog";

export default MediaDcrCreateDialog;
