import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { type Palette } from "@mui/joy";

export const WORKER_TYPE_COLOR: Record<
  WorkerTypeShortName,
  keyof Palette["chip"]
> = {
  [WorkerTypeShortName.Match]: "wheat",
  [WorkerTypeShortName.Post]: "alto",
  [WorkerTypeShortName.Preview]: "iceCold",
  [WorkerTypeShortName.Python]: "marzipan",
  [WorkerTypeShortName.R]: "cinderella",
  [WorkerTypeShortName.S3]: "alto",
  [WorkerTypeShortName.Sql]: "tropicalBlue",
  [WorkerTypeShortName.Sqlite]: "tropicalBlue",
  [WorkerTypeShortName.Synthetic]: "celadon",
};
