import { DqCard, DqCardContent } from "@decentriq/components";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { getAudiencePresentation } from "features/legacyMediaDataRoom/types";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr";
import {
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator";
import {
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator/components";
import { useOrganizationPreferences } from "hooks";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import {
  AudienceGeneratorReachSlider,
  AudienceQualityStatistics,
  ExcludeSeedAudienceCheckbox,
} from "../";

const LookalikeAdvertiserAudienceGeneratorConfigurationStep = memo(() => {
  const {
    selectedAudienceType,
    setSelectedAudienceType,
    openExcludeSeedAudienceInfoDialog,
    selectedLookalikeAudience,
    activatedLookalikeAudiences = [],
    availableAudiences: { computeResults: { audienceTypes = [] } = {} },
  } = useLookalikeAdvertiserAudienceGenerator();
  const { handleBackStep, handleNextStep, isFirstStep, cancelGeneration } =
    useAdvertiserAudienceGenerator();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const {
    supportedFeatures: { enableExtendedLalQualityStats = false },
  } = usePublishedMediaInsightsDcr();
  const { allowExcludingSeedAudience } = useOrganizationPreferences();
  const shouldCancelGenerationOnBack = isFirstStep(
    AdvertiserAudienceGeneratorStep.CONFIGURATION
  );
  return (
    <>
      <Grid container={true} mb={8} xs={12}>
        <Grid xs={7}>
          <AudienceGeneratorStepSectionTitle>
            1. AI Lookalike audience setup
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Choose the audience that will be used to seed your lookalike model.
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid xs={5}>
          <FormControl>
            <FormLabel sx={{ fontSize: "12px" }}>Advertiser audience</FormLabel>
            <Select
              onChange={(event, value) => setSelectedAudienceType(value!)}
              renderValue={(option) =>
                getAudiencePresentation({ value: option!.value })
              }
              value={selectedAudienceType || ""}
            >
              {audienceTypes.map((audienceType) => {
                return (
                  <Option key={audienceType} value={audienceType}>
                    {getAudiencePresentation({ value: audienceType })}
                  </Option>
                );
              })}
            </Select>
          </FormControl>
          {enableExtendedLalQualityStats && (
            <Box display="flex" gap={2} mt={2}>
              <DqCard>
                <DqCardContent>
                  <Typography
                    gutterBottom={true}
                    level="body-sm"
                    textAlign="center"
                    textColor="inherit"
                  >
                    Addressable audience
                  </Typography>
                  <Typography fontWeight={800}>
                    {formatter.format(
                      selectedLookalikeAudience?.addressable_audience_size || 0
                    )}
                  </Typography>
                </DqCardContent>
              </DqCard>
              <DqCard>
                <DqCardContent>
                  <Typography
                    gutterBottom={true}
                    level="body-sm"
                    textAlign="center"
                    textColor="inherit"
                  >
                    Seed audience
                  </Typography>
                  <Typography fontWeight={800}>
                    {formatter.format(
                      selectedLookalikeAudience?.seed_audience_size || 0
                    )}
                  </Typography>
                </DqCardContent>
              </DqCard>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container={true} mb={2} xs={12}>
        <Grid xs={7}>
          <AudienceGeneratorStepSectionTitle>
            2. Define the Reach
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Set the precision and reach for your lookalike model
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid xs={5}>
          <AudienceGeneratorReachSlider />
          {allowExcludingSeedAudience && <ExcludeSeedAudienceCheckbox />}
        </Grid>
      </Grid>
      <Grid container={true} xs={12}>
        <AudienceQualityStatistics />
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button
          onClick={
            shouldCancelGenerationOnBack ? cancelGeneration : handleBackStep
          }
          sx={{ mr: 1 }}
        >
          {shouldCancelGenerationOnBack ? "Cancel" : "Back"}
        </Button>
        <Button
          color="primary"
          disabled={!selectedAudienceType}
          onClick={
            !allowExcludingSeedAudience ||
            activatedLookalikeAudiences.length > 0
              ? handleNextStep
              : openExcludeSeedAudienceInfoDialog
          }
          variant="solid"
        >
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

LookalikeAdvertiserAudienceGeneratorConfigurationStep.displayName =
  "LookalikeAdvertiserAudienceGeneratorConfigurationStep";

export default LookalikeAdvertiserAudienceGeneratorConfigurationStep;
