import { Grid, Skeleton } from "@mui/joy";
import { memo } from "react";

const DEFAULT_SKELETON_HEIGHT = 186; // NOTE: Matches the height of the `OrganizationSheet`
const DEFAULT_SKELETONS_COUNT = 6;

const SkeletonGrid: React.FC<{
  height?: number | undefined;
  length?: number | undefined;
}> = memo(
  ({ height = DEFAULT_SKELETON_HEIGHT, length = DEFAULT_SKELETONS_COUNT }) => (
    <Grid container={true} spacing={1}>
      {Array.from({ length }, (_, index) => (
        <Grid key={index} md={4} xs={6}>
          <Skeleton
            height={height}
            sx={{ borderRadius: "sm" }}
            variant="rectangular"
          />
        </Grid>
      ))}
    </Grid>
  )
);

export default SkeletonGrid;
