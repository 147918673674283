import { CircularProgress } from "@mui/joy";
import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminRoute, PrivateRoute } from "components";
import { useApiCore } from "contexts";
import { useUserRole } from "hooks";
import { Main } from "layouts";
import {
  AccessTokensPage,
  AdminPortalPage,
  DataPartnerPortalPage,
  DataRoomsPage,
  DatasetsPage,
  DraftDataRoomPage,
  FourOFourPage,
  HomePage,
  KeychainPage,
  LegacyAccessTokensPage,
  MediaDataRoomPage,
  PublishedDataRoomPage,
  PublisherPortalPage,
} from "pages";

const PagesWrapper = memo(() => {
  const {
    isOrganizationAdmin,
    isSuperAdmin,
    isSuperAdminReadOnly,
    organizationId: userOrganizationId,
    isLoading,
  } = useUserRole();
  const { isMigrated } = useApiCore();
  return (
    <Main>
      <Routes>
        <Route element={<PrivateRoute component={HomePage} />} path="/" />
        <Route
          element={<PrivateRoute component={DatasetsPage} />}
          path="/datasets/*"
        />
        <Route
          element={<PrivateRoute component={DraftDataRoomPage} />}
          path="/datarooms/d/:dataRoomId/*"
        />
        <Route
          element={<PrivateRoute component={PublishedDataRoomPage} />}
          path="/datarooms/p/:dataRoomId/*"
        />
        <Route
          element={<PrivateRoute component={MediaDataRoomPage} />}
          path="/datarooms/mi/:dataRoomId/*"
        />
        <Route
          element={<PrivateRoute component={DataRoomsPage} />}
          path="/datarooms/:dataRoomId?"
        />
        <Route
          element={
            <PrivateRoute
              component={isMigrated ? AccessTokensPage : LegacyAccessTokensPage}
            />
          }
          path="/tokens"
        />
        {isMigrated ? (
          <Route
            element={<PrivateRoute component={LegacyAccessTokensPage} />}
            path="/legacy-tokens"
          />
        ) : undefined}
        {!isMigrated && (
          <Route
            element={<PrivateRoute component={KeychainPage} />}
            path="/keychain"
          />
        )}
        <Route
          element={<PrivateRoute component={PublisherPortalPage} />}
          path="/publisherportal/*"
        />
        <Route
          element={<PrivateRoute component={DataPartnerPortalPage} />}
          path="/data-partner-portal/*"
        />
        <Route
          element={<AdminRoute component={AdminPortalPage} />}
          path="/admin/*"
        />
        {/* Redirecting to organizations page if user is super admin and organization page if organization admin */}
        <Route
          element={
            isLoading ? (
              <CircularProgress
                sx={{
                  alignSelf: "center",
                  display: "flex",
                  height: "2rem !important",
                  justifyContent: "center",
                  width: "100% !important",
                }}
              />
            ) : (
              <Navigate
                replace={true}
                to={{
                  pathname:
                    isSuperAdmin || isSuperAdminReadOnly
                      ? "/admin/organizations"
                      : isOrganizationAdmin
                        ? `/admin/organizations/${userOrganizationId}`
                        : "/",
                }}
              />
            )
          }
          path="/admin"
        />
        <Route element={<Navigate replace={true} to="/" />} path="/signup" />
        <Route element={<FourOFourPage />} path="*" />
      </Routes>
    </Main>
  );
});

export default PagesWrapper;
