import { useAuth0 } from "@auth0/auth0-react";
import {
  createErrorMessagesAndAutofixes,
  DqParticipantsEditor,
  type DqParticipantsEditorProps,
} from "@decentriq/components";
import { MediaDataRoomOrganizationRole } from "@decentriq/types";
import { testIds } from "@decentriq/utils";
import {
  faInfoCircle,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import uniqBy from "lodash/uniqBy";
import {
  Fragment,
  memo,
  type ReactNode,
  type SetStateAction,
  useMemo,
} from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { useCreationWizardStepper } from "../../../../../../contexts";

const getDomainsList = (emailValues: string[]) =>
  uniqBy(
    emailValues.map((email) => {
      const [, emailDomain] = email?.split("@") || [];
      return {
        label: emailDomain,
        value: email,
      };
    }),
    "label"
  );

type RoleEmailFieldName =
  | "advertiserEmails"
  | "publisherEmails"
  | "dataPartnerEmails"
  | "agencyEmails"
  | "observerEmails";

const organizationRoleToFieldNameMap: Record<
  MediaDataRoomOrganizationRole,
  RoleEmailFieldName
> = {
  [MediaDataRoomOrganizationRole.ADVERTISER]: "advertiserEmails",
  [MediaDataRoomOrganizationRole.PUBLISHER]: "publisherEmails",
  [MediaDataRoomOrganizationRole.DATA_PARTNER]: "dataPartnerEmails",
};

interface ParticipantsEditorProps {
  emailPlaceholder?: string;
  icon: IconDefinition;
  label: ReactNode;
  mainParticipantValue?: string;
  name: RoleEmailFieldName;
  onMainParticipantChange?: (email: SetStateAction<string>) => void;
  onRemove?: () => void | undefined;
  readOnly?: boolean | undefined;
  tooltipTitle?: ReactNode;
}

const ParticipantsEditor: React.FC<ParticipantsEditorProps> = memo(
  ({
    emailPlaceholder = `e.g. "user@domain.com"`,
    icon,
    label,
    mainParticipantValue,
    name,
    onMainParticipantChange = () => {},
    onRemove,
    readOnly,
    tooltipTitle,
  }) => {
    const { organizationRole } = useCreationWizardStepper();
    const { user: currentUser } = useAuth0();
    const { email: currentUserEmail } = currentUser || {};
    const { control } =
      useFormContext<{ [k in RoleEmailFieldName]?: string[] }>();
    const { defaultValues } = useFormState();
    const textFieldTestIds: Record<RoleEmailFieldName, string> = {
      advertiserEmails:
        testIds.mediaInsightsDcr.collaborationConfigurationStepRole
          .advertiserEmail,
      agencyEmails:
        testIds.mediaInsightsDcr.collaborationConfigurationStepRole.agencyEmail,
      dataPartnerEmails:
        testIds.mediaInsightsDcr.collaborationConfigurationStepRole
          .dataPartnerEmail,
      observerEmails:
        testIds.mediaInsightsDcr.collaborationConfigurationStepRole
          .observerEmail,
      publisherEmails:
        testIds.mediaInsightsDcr.collaborationConfigurationStepRole
          .publisherEmail,
    };
    const valueLocks = useMemo<
      NonNullable<DqParticipantsEditorProps["valueLocks"]>
    >(
      () =>
        [
          ...((defaultValues?.[name]?.filter(Boolean) as string[]) || []).map(
            (email) => ({ reason: "default" as const, value: email })
          ),
          ...(currentUserEmail &&
          organizationRole &&
          organizationRoleToFieldNameMap[organizationRole] === name
            ? [{ reason: "current" as const, value: currentUserEmail }]
            : []),
          // This filter allows to remove current user email from the list of participants
          // if the current user has advertiser role
        ].filter(
          ({ value }) =>
            name !== "advertiserEmails" || value !== currentUserEmail
        ),
      [currentUserEmail, defaultValues?.[name], name, organizationRole]
    );
    return (
      <FormControl required={true}>
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => {
            const { error } = fieldState;
            const domainsList = getDomainsList(field.value || []);
            const mainParticipantDomain =
              domainsList.find(
                (domain) => domain.value === mainParticipantValue
              )?.label || "No organization selected";
            const isMainParticipantSelectVisible =
              domainsList.length > 1 &&
              ["publisherEmails", "advertiserEmails"].includes(name);
            return (
              <FormControl error={Boolean(error)}>
                <FormLabel sx={{ width: "100%" }}>
                  <Typography
                    endDecorator={
                      tooltipTitle ? (
                        <Tooltip title={tooltipTitle}>
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      ) : null
                    }
                    level="inherit"
                    startDecorator={
                      icon ? (
                        <FontAwesomeIcon fixedWidth={true} icon={icon} />
                      ) : null
                    }
                  >
                    {label}
                  </Typography>
                  {onRemove ? (
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        order: 1,
                      }}
                    >
                      <Link
                        color="neutral"
                        component="button"
                        level="inherit"
                        onClick={onRemove}
                        underline="none"
                        variant="plain"
                      >
                        Remove
                      </Link>
                    </Box>
                  ) : null}
                </FormLabel>
                <DqParticipantsEditor
                  data-testid={textFieldTestIds[name]}
                  error={error}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    const domainsList = getDomainsList(newValue);
                    const isParticipantExist = domainsList.find(
                      ({ value }) => value === mainParticipantValue
                    );
                    if (
                      !isMainParticipantSelectVisible ||
                      !isParticipantExist
                    ) {
                      onMainParticipantChange(domainsList[0]?.value || "");
                    }
                  }}
                  options={[]}
                  placeholder={readOnly ? undefined : emailPlaceholder}
                  readOnly={readOnly}
                  slotProps={{
                    startDecorator: {
                      sx: {
                        marginInlineEnd: "calc(var(--Input-gap) / 2)",
                      },
                    },
                  }}
                  startDecorator={
                    isMainParticipantSelectVisible ? (
                      <Fragment>
                        <FormControl
                          sx={{
                            borderBottomLeftRadius: "var(--Input-radius)",
                            borderTopLeftRadius: "var(--Input-radius)",
                            height: "100%",
                            ml: -1.5,
                            mr: "-1px",
                            overflow: "hidden",
                          }}
                        >
                          <Tooltip
                            title={
                              mainParticipantDomain ? (
                                <div>
                                  <Typography
                                    level="title-md"
                                    textColor="inherit"
                                  >
                                    {mainParticipantDomain}
                                  </Typography>
                                  Organization that represents {label}
                                </div>
                              ) : null
                            }
                          >
                            <Select
                              onChange={(event, value) => {
                                onMainParticipantChange(value || "");
                              }}
                              placeholder="No organization selected"
                              renderValue={() => mainParticipantDomain}
                              slotProps={{
                                button: {
                                  sx: {
                                    display: "inline",
                                    fontWeight: 500,
                                    lineHeight: "1.5rem",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  },
                                },
                                listbox: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: { offset: [0, 4] },
                                    },
                                    {
                                      enabled: true,
                                      fn: ({ state }) => {
                                        state.styles.popper.width = `${state.rects.reference.width}px`;
                                      },
                                      name: "equalWidth",
                                      phase: "beforeWrite",
                                      requires: ["computeStyles"],
                                    },
                                  ],
                                  variant: "outlined",
                                },
                                root: {
                                  sx: {
                                    borderRadius: 0,
                                    marginLeft: "-1px",
                                    marginTop: "-1px",
                                    maxWidth: "150px",
                                    paddingBlock: "2px",
                                  },
                                },
                              }}
                              value={mainParticipantValue || ""}
                              variant="plain"
                            >
                              {domainsList.map(({ value, label }) => (
                                <Option key={value} value={value}>
                                  {label}
                                </Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </FormControl>
                        <Divider orientation="vertical" />
                      </Fragment>
                    ) : null
                  }
                  value={field.value}
                  valueLocks={valueLocks}
                />
                <FormHelperText>
                  {error
                    ? createErrorMessagesAndAutofixes(field, error, {
                        lockedValues:
                          valueLocks?.map(({ value }) => value) ?? [],
                      })
                    : "Type in user email and press Enter"}
                </FormHelperText>
              </FormControl>
            );
          }}
        />
      </FormControl>
    );
  }
);
ParticipantsEditor.displayName = "ParticipantsEditor";

export default ParticipantsEditor;
