// NOTE: Came from "features/MediaInsightsDcr/contexts/MediaInsightsDcrCreateWizardContext/CreationWIzardStepperContext"
export enum MediaDataRoomCreationStep {
  SELECT_ORGANIZATION_ROLE = "SELECT_ORGANIZATION_ROLE",
  SELECT_PUBLISHER = "SELECT_PUBLISHER",
  SELECT_DATA_SOURCE = "SELECT_DATA_SOURCE",
  SELECT_DATA_PARTNER = "SELECT_DATA_PARTNER",
  COLLABORATION_CONFIGURATION = "COLLABORATION_CONFIGURATION",
  COLLABORATION_REQUEST_TO_PUBLISHER = "COLLABORATION_REQUEST_TO_PUBLISHER",
  COLLABORATION_REQUEST_TO_DATA_PARTNER = "COLLABORATION_REQUEST_TO_DATA_PARTNER",
}
